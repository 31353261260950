 import React, { useEffect, Suspense, lazy, Fragment, useState } from "react";
 const selectedTheme = localStorage.getItem("ThemeSelected");
require("../../src/assets/scss/screen1.css");
localStorage.removeItem("ThemeSelected");
if (selectedTheme === "theme1") {
  import("../../src/assets/scss/screen1.css");
}

else{
  localStorage.setItem("ThemeReload","true");
  import("../../src/assets/scss/screen1.css");
}

//React.Suspense work starts
const Theme1 = React.lazy(async () => (await import("../../src/assets/scss/screen1.css")));
const Theme2 = React.lazy(async () => (await import("../../src/assets/scss/screen1.css")));



const ThemeSelector = ({ children }) => {
  var CHOSEN_THEME = "Light";
  return (
    <Fragment>
      <React.Suspense fallback={<div></div>}>
        {CHOSEN_THEME === "theme1" && <Theme1 />}
        {CHOSEN_THEME === "theme2" && <Theme2 />}
      </React.Suspense>
      {children}
    </Fragment>
  );
};

export default ThemeSelector;
