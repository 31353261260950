import React, { useState, useRef, Fragment, useEffect } from "react";
import { history } from "../../helpers/history";
import clientmanagmentObj from "../../services/client-managment.service";
import validator from "validator";
import ModelPopup from "../../helpers/ModelPopup";
import { useSelector } from "react-redux";
import depositslip from "../../assets/images/deposit-slip.svg";
import depositemptystate from "../../assets/images/icons/deposit-emptystate.svg";
import ReactDatePickerHelper from "../../helpers/ReactDatePickerHelper";
import crossImgs from "../../assets/images/icons/cross.svg";
import RefreshToken from "../../helpers/RefreshToken";
import DateFormat from "../../helpers/DateFormat";
import CurrencyFormat from 'react-currency-format';

const SearchGridInputData = [
  {
    Name: "",
    tokenId: "",
    DateFrom: "",
    DateTo: "",
    startIndex: 1,
    scrolled: false,
    isFirstTime: true,
  },
];

const DepositClientGrid = props => {
  const { user: currentUser } = useSelector(state => state.auth);
  const [modalShow, setModalShow] = useState(false);
  const [modalShowDeposit, setModalShowDeposit] = useState(false);
  const [name, setCompanyName] = useState("");
  const [ClientId, setclientId] = useState(String(currentUser.user.ClientId));

  const [ClientCurrency, setClientCurrency] = useState(String(currentUser.user?.ClientCurrency));
  const [tokenId, setTokentId] = useState("");
  //const [RequestId, setRequestId] = useState();
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const searchTbl = useRef();
  const [loadingData, setLoadingData] = useState(false);
  const [searchData, setSearchData] = useState();
  const [searchGridSuccess, setSearchGridSuccess] = useState(false);
  const [requestId, setrequestId] = useState("");
  const [bank, setbank] = useState("");
  const [accountNumber, setaccountNumber] = useState("");
  const [depositAmount, setdepositAmount] = useState("");
  const [depositStatus, setdepositStatus] = useState("");
  const [wire, setwire] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [SearchInputDisabled, setSearchDisabled] = useState(false);
  const [AcceptedAmount, setAcceptedAmount] = useState();

  useEffect(() => {
    SearchClickHandler(null, true);
  }, []);
  const handleInputSearchClick = e => {
    e.preventDefault();
    setModalShow(true);
    setSearchDisabled(true);
    setTokentId("");
    setCompanyName("");
    dateToHandler("");
    dateFromHandler("");
  };
  const nameChangeHandler = e => {
    SearchGridInputData[0].Name = e.target.value;
    setCompanyName(SearchGridInputData[0].Name);
  };
  // const clientChangeHandler = (e) => {
  //   SearchGridInputData[0].ClientId = e.target.value;
  //   if (validator.isNumeric(SearchGridInputData[0].ClientId))
  //     setclientId(SearchGridInputData[0].ClientId);
  // };
  const TokenIDChangeHandler = e => {
    SearchGridInputData[0].tokenId = e.target.value;
    if (validator.isNumeric(SearchGridInputData[0].tokenId))
      setTokentId(SearchGridInputData[0].tokenId);
  };
  // const dateFromHandler = (e) => {
  //   SearchGridInputData[0].DateFrom = e.target.value;
  //   setDateFrom(SearchGridInputData[0].DateFrom);
  // };
  // const dateToHandler = (e) => {
  //   SearchGridInputData[0].DateTo = e.target.value;
  //   setDateTo(SearchGridInputData[0].DateTo);
  // };
  const SearchClickHandler = (e, isFromUseEffect) => {
    setLoadingData(true);
    if (e) {
      e.preventDefault();
      SearchGridInputData[0].startIndex = 1;
    }
    if (isFromUseEffect) {
      SearchGridInputData[0].startIndex = 1;
      setSearchData("");
      // setModalShow(true);
    } else setModalShow(false);
    setSearchDisabled(false);

    if (!SearchGridInputData[0].scrolled) {
      setSearchGridSuccess(false);
    }
    clientmanagmentObj
      .getdeposits(
        name,
        tokenId,
        dateFrom || new Date("0001-01-01"),
        dateTo || new Date("0001-01-01"),
        SearchGridInputData[0].startIndex,
        currentUser?.user?.SuperClientId > 0 ? "" : ClientId,
        currentUser?.user?.SuperClientId == 0 ? "" : String(currentUser?.user?.SuperClientId)
      )
      .then(resp => {
        setLoadingData(false);
        if (resp.data.success) {
          // if(isFromUseEffect)
          // setModalShow(true);
          // else
          setModalShow(false);
        }
        if (
          resp.data.payload.result.pageModel.length > 0 ||
          searchData.length > 0
        ) {
          setTotalCount(resp.data.payload.result.totalCount);
          if (SearchGridInputData[0].scrolled) {
            let appendedData = searchData.concat(
              resp.data.payload.result.pageModel
            );
            setSearchData(appendedData);
            props?.setDataset && props?.setDataset(appendedData);
          } else {
            setSearchData(resp.data.payload.result.pageModel);
            props?.setDataset && props?.setDataset(resp.data.payload.result.pageModel);
            SearchGridInputData[0].startIndex = 1;
          }
          setSearchGridSuccess(true);

          SearchGridInputData[0].scrolled = false;
          // if (SearchGridInputData[0].isFirstTime) {
          searchTbl.current.onscroll = tblScrollHandler;
          SearchGridInputData[0].isFirstTime = false;
          // }
        } else {
          SearchGridInputData[0].startIndex = 1;
        }
      })
      .catch(error => {
        setSearchGridSuccess(false);
        setLoadingData(false);
        (async () => {
          let resp = await RefreshToken(
            error && error.response && error.response.status
          );
          if (resp) {
            if (resp) {
              SearchClickHandler(null, false);
              setLoadingData(false);
            } else {
              setLoadingData(false);
            }
          }
        })();
      });
  };
  const showDepositPopup = (
    uuid,
    requestId,
    depositDate,
    depositAmount,
    bank,
    accountNumber,
    depositStatus,
    acceptedAmount,
    wireConfirmationUrl
  ) => {
    setModalShowDeposit(true);
    setrequestId(requestId);
    setbank(bank);
    setaccountNumber(accountNumber);
    setdepositAmount(depositAmount);
    setdepositStatus(depositStatus);
    setwire(wireConfirmationUrl);
    setAcceptedAmount(acceptedAmount);
    //setdepositBtnViewClicked(true);
  };
  const tblScrollHandler = e => {
    //if (e.currentTarget.className === "") {
    if (
      !SearchGridInputData[0].scrolled &&
      SearchGridInputData[0].startIndex + 10 <= totalCount
    ) {
      SearchGridInputData[0].scrolled = true;
      SearchGridInputData[0].startIndex =
        SearchGridInputData[0].startIndex + 10;
      SearchClickHandler(null, false);
    }
    //  }
  };
  const showClientDetails = id => {
    history.push("/clientDetails", id);
  };
  const renderTableHeader = () => {
    let header = Object.values(props.headersdata);
    return header.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  };
  const dateFromHandler = e => {
    SearchGridInputData[0].DateFrom = e;
    if (e)
      SearchGridInputData[0].DateFrom = dateFilter(
        SearchGridInputData[0].DateFrom
      );
    setDateFrom(SearchGridInputData[0].DateFrom);
  };
  const dateToHandler = e => {
    SearchGridInputData[0].DateTo = e;
    if (e)
      SearchGridInputData[0].DateTo = dateFilter(SearchGridInputData[0].DateTo);
    setDateTo(SearchGridInputData[0].DateTo);
  };
  const dateFilter = date => {
    return new Date(date);
  };
  const HideSearchPopupDiv = () => {
    setModalShow(false);
    setSearchDisabled(false);
    setrequestId("");
    setCompanyName("");
  };
  const ClearSearchData = () => {
    setTokentId("");
    setCompanyName("");
    dateToHandler("");
    dateFromHandler("");
    ClearInputData();
  };
  const ClearInputData = () => {
    setTimeout(() => {
      [...document.getElementsByClassName("r-input-group")].forEach(element => {
        element.children[0].value = "";
      });
      document.getElementById("searchbuttonDepositClient").click();
    }, 500);
  };
  const renderTableData = () => {
    return searchData.map((data, index) => {
      const {
        uuid,
        requestId,
        depositDate,
         depositAmount,
        bank,
        accountNumber,
        depositStatus,
        wireConfirmationUrl,
        acceptedAmount,
        depositCurrency
      } = data; //destructuring
      return (
        <tr key={uuid}>
          <td>{requestId}</td>
          <td>
            <DateFormat date={depositDate} />
          </td>

          {/* <td>{depositDate}</td> */}

          {/* <td><CurrencyFormat value={depositAmount}displayType={'text'} thousandSeparator={true} /> {  }{ClientCurrency===null ? "":ClientCurrency}</td> */}
          <td><CurrencyFormat value={acceptedAmount} displayType={'text'} thousandSeparator={true} /> { }{depositCurrency === null ? "" : depositCurrency}</td>
          <td>{bank}</td>
          <td>{accountNumber}</td>
          <td>{depositStatus}</td>
          <td>
            <span
              className="icon-show"
              onClick={() =>
                showDepositPopup(
                  uuid,
                  requestId,
                  depositDate,
                  depositAmount,
                  bank,
                  accountNumber,
                  depositStatus,
                  acceptedAmount,
                  wireConfirmationUrl
                )
              }
            ></span>
          </td>
        </tr>
      );
    });
  };
  const renderDepositDetails = () => {
    return (
      <div class="row">
        <div class="col-md-12">
          <h2>Deposit Details</h2>
          <div class="mt-16">
            <form onSubmit={e => e.preventDefault()}>
              <ul class="ul-card-details-popup">
                <li class="d-flex justify-content-between mb-20">
                  <label>Request</label>
                  <span>{requestId}</span>
                </li>
                <li class="d-flex justify-content-between mb-20">
                  <label>Bank</label>
                  <span>{bank}</span>
                </li>
                <li class="d-flex justify-content-between mb-20">
                  <label>Account Number</label>
                  <span>{accountNumber}</span>
                </li>
                <li class="d-flex justify-content-between mb-20">
                  <label>Amount</label>
                  <span><CurrencyFormat value={depositAmount} displayType={'text'} thousandSeparator={true} /></span>
                </li>
                {depositStatus === "Accepted" && (
                  <li className="d-flex justify-content-between mb-20">
                    <label>Accepted Amount</label>
                    <span><CurrencyFormat value={AcceptedAmount} displayType={'text'} thousandSeparator={true} /></span>
                  </li>
                )}
                <li class="d-flex justify-content-between mb-20">
                  <label>Status</label>
                  <span>{depositStatus}</span>
                </li>
                <li class="d-flex justify-content-between mb-20">
                  <label>Wire</label>
                  {/* <span>{wire}</span> */}
                </li>
              </ul>
              <div className="deposit-slip-img">
                {wire ? (
                  // Check if the file is a PDF based on its extension
                  wire.endsWith('.pdf') ? (
                    <Fragment>

                      {/* <iframe
                        className="pdf-viewer"
                        src={wire}
                        title="Deposit Slip PDF"
                        width="100%"
                        height="10px"
                      ></iframe> */}
                      <a
                        href={wire}
                        download
                        className="btn btn-large"
                      >
                        Download PDF
                      </a>
                    </Fragment>

                  ) : (
                    // Display image for other file types
                    <img className="img-fluid" src={wire} alt="deposit" />
                  )
                ) : (
                  "No Wire Transfer Image Found!"
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div>
      <div className="tab-content" id="myTabContent2">
        <div
          className="tab-pane fade show active"
          id="allClients"
          role="tabpanel"
          aria-labelledby="allClients-tab"
        >
          {/* no Deposit found  */}

          <Fragment>
            <form onSubmit={e => e.preventDefault()}>
              <div className="row row-search">
                <div className="col-xl-12 col-12">
                  <div className="form-group">
                    <div className="form-controls">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={props.placeholdervalue}
                        onClick={handleInputSearchClick}
                        disabled={SearchInputDisabled}
                      />
                      <span className="icon-search _ico"></span>
                      {/* search box  */}
                      <div
                        className="bo-cardsearch"
                        style={{ display: modalShow ? "" : "none" }}
                      >
                        <div className="row">
                          <div className="col-md-5">
                            <div className="form-group form-search">
                              <label>Date From</label>
                              <div className="form-controls">
                                <ReactDatePickerHelper
                                  datevalue={dateFrom}
                                  onchangedate={dateFromHandler}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-2">
                            <img
                              className="cross-icon cross-modal"
                              src={crossImgs}
                              aria-label="Close"
                              onClick={HideSearchPopupDiv}
                              alt=""
                            />
                          </div>
                          <div className="col-md-5">
                            <div className="form-group form-search">
                              <label>Date To</label>
                              <div className="form-controls">
                                <ReactDatePickerHelper
                                  datevalue={dateTo}
                                  onchangedate={dateToHandler}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-5">
                            <div className="form-group form-search ">
                              <label>Reference No</label>

                              <div className="form-controls col-md-6">
                                <input
                                  type="text"
                                  value={tokenId}
                                  onChange={TokenIDChangeHandler}
                                  className="form-control"
                                  maxLength={9}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="form-group submit-field form-search">
                              <button
                                className="btn btn-outline mr-19"
                                onClick={ClearSearchData}
                              >
                                Clear Search
                              </button>
                              <button
                                id="searchbuttonDepositClient"
                                className="btn btn-large"
                                onClick={SearchClickHandler}
                              >
                                Search
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <span className="help-block d-none"></span>
                  </div>
                </div>
              </div>
            </form>
          </Fragment>
        </div>
        {searchData === undefined || searchData.length === 0 ? (
          <div className="d-flex align-content-center justify-content-center no-clients-msg">
            <div className="text-center">
              <span className="icon-deposit-emptystate">
                <span className="path1"></span>
                <span className="path2"></span>
                <span className="path3"></span>
                <span className="path4"></span>
                <span className="path5"></span>
                <span className="path6"></span>
                <span className="path7"></span>
                <span className="path8"></span>
                <span className="path9"></span>
                <span className="path10"></span>
                <span className="path11"></span>
              </span>
              {/* <img src={depositemptystate} alt="transaction" /> */}
              <h3>Empty deposit Listing</h3>
              <h6>There is no deposit available for now.</h6>
            </div>
          </div>
        ) : (
          <Fragment>
            {searchGridSuccess && (
              <div className="table-wrap" ref={searchTbl}>
                <table
                  className="table table-borderless bo-table table-cards"
                  onScroll={tblScrollHandler}
                  onWheel={tblScrollHandler}
                >
                  <thead>
                    <tr>{renderTableHeader()}</tr>
                  </thead>
                  <tbody>{renderTableData()}</tbody>
                </table>
              </div>
            )}
            {!searchGridSuccess && (
              <Fragment>
                <div className="table-wrap">
                  <table>
                    <tbody>
                      <tr>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {loadingData ? (
                  <div className="loading-more">
                    <div className="loader">
                      <div></div>
                    </div>
                    <p>loading data</p>
                  </div>
                ) : (
                  <div className="loading-more">
                    {" "}
                    <div></div>
                    <p>No Data Found</p>
                  </div>
                )}
              </Fragment>
            )}
          </Fragment>
        )}
      </div>
      <div>
        <ModelPopup
          key={1}
          show={modalShowDeposit}
          onHide={() => setModalShowDeposit(false)}
          modelheading={""}
          modelbody={renderDepositDetails()}
          showheader={"false"}
          dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
          className="smallPopup issueCardSmall"
          backdrop="static"
          keyboard={false}
        />
      </div>
    </div>
  );
};

export default DepositClientGrid;
