import axios from "axios";
import { Env } from "../enviroment/environment";
import authHeader from "./auth-header";

const API_URL = Env.UserApiUrl; //"http://localhost:8000/api/users/";
const API_URL_Upload = Env.UploadDocuments; //"http://localhost:8000/api/Upload/";

const identityverification = (IssuingCountry,IDType,AddressProof,FilePathIdType,FilePathAddressProof,
  UserId) => {
  return axios
    .post(API_URL + "identityVerification", {
        IssuingCountry,IDType,AddressProof,FilePathIdType,FilePathAddressProof,
        UserId
    }, { headers: authHeader() }
    )
    .then((response) => {
      return response;
    });
};
const updateCardHolderIdentityDocuments = (IssuingCountry,IDType,AddressProof,FilePathIdType,FilePathAddressProof, UserId,
  ClientId) => {
  return axios
    .post(API_URL + "updateuseridentities", {
        IssuingCountry,
        IDType,AddressProof,
        FilePathIdType,
        FilePathAddressProof, 
        UserId,
        ClientId
    },
    { headers: authHeader() }
    )
    .then((response) => {
      return response;
    });
};
const getIdTypeEnums = () => {
    return axios
      .get(API_URL + "getAllIdTypes",  { headers: authHeader() })
      .then((response) => {
        return response;
      });
  };
  const getProofofAddressEnums = () => {
    return axios
      .get(API_URL + "getAllIdentityProof",  { headers: authHeader() })
      .then((response) => {
        return response;
      });
  };
const uploaduserdocs = (formData,options) => {
    return axios
      .post(API_URL_Upload + "uploaduserdocs",
        formData,options,  { headers: authHeader() })
      .then((response) => {
        return response;
      });
  };
  const getuseridentities = (userId) => {
    return axios
      .get(API_URL + "getuseridentities?userId="+  userId,{ headers: authHeader() })
      .then((res) => {
        return res;
      });
  };
  const IssuerGKyc = (IdNumber,
    IdType,
    IdDocLang,
    AddressType,
    AddressDocLang,
    //ID Type
    IdFrontPic,
    IdBackPic,
    //Address Front Back
    AddressFrontPic,
    AddressBackPic,
    UserId,
    IdFrontPicBase64,
    IdBackPicBase64,
    AddressFrontPicBase64,
    AddressBackPicBase64,
    StateId,
    idIssuedBy,
    idIssueDate,
    idExpDate,
    AddressIssuedBy,
    AddressIssueDate,
    firstname,lastname,DOB,address,phonenumber,isUpdate
  ) => {
    return axios
      .post(API_URL +"resubmitissuergkyc?firstname=" + firstname + "&lastname=" + 
      lastname + "&DOB=" + DOB + "&address=" + address + "&phonenumber=" + phonenumber + "&isUpdate="+ isUpdate , {
        IdNumber,
        IdType,
        IdDocLang,
        AddressType,
        AddressDocLang,
        //ID Type
        IdFrontPic,
        IdBackPic,
        //Address Front Back
        
        AddressFrontPic,
        AddressBackPic,
        UserId,
        IdFrontPicBase64,
        IdBackPicBase64,
        AddressFrontPicBase64,
        AddressBackPicBase64,
        StateId,
        idIssuedBy,
        idIssueDate,
        idExpDate,
        AddressIssuedBy,
        AddressIssueDate
      }, {
        headers: authHeader(),
      }
      )
      .then((response) => {
        return response;
      });
  };
const identityverificationObj = {
    identityverification,
    uploaduserdocs,
    getIdTypeEnums,
    getProofofAddressEnums,
    getuseridentities,
    updateCardHolderIdentityDocuments,
    IssuerGKyc
};
export default identityverificationObj;
