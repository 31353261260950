import axios from "axios";
import { Env } from "../enviroment/environment";

const API_URL = Env.AuthApiUrl; //"http://localhost:8000/api/auth/";

const login = (email, password, rememberMe,EmailCode,SmsCode) => {
  return axios
    .post(API_URL + "postlogin", {
      email,
      password,
      EmailCode,SmsCode,
    })
    .then((response) => {
      if (response.data.auth_token) {
        sessionStorage.setItem("user", JSON.stringify(response.data));
      }
      if (rememberMe) {
        localStorage.setItem("boUserEmail", email);
        localStorage.setItem("boUserPassword", password);
      }
      return response.data;
    });
};

const logout = () => {
  sessionStorage.removeItem("user");
};
const refreshtoken = (accessToken) => {
  return axios.post(API_URL + "refreshtoken", { accessToken }).then((resp) => {
    return resp;
  });
};
const prelogin = (email, password) => {
  return axios
    .post(API_URL + "prelogin", {
      email,
      password,
    })
    .then((response) => {
      return response;
    });
};
const loginSuperAdmin = (email, password, rememberMe) => {
  return axios
    .post(API_URL + "login", {
      email,
      password,
      
    })
    .then((response) => {
      if (response.data.auth_token) {
        sessionStorage.setItem("user", JSON.stringify(response.data));
      }
      if (rememberMe) {
        localStorage.setItem("boUserEmail", email);
        localStorage.setItem("boUserPassword", password);
      }
      return response.data;
    });
};

const authObj = {
  login,
  logout,
  refreshtoken,
  prelogin,
  loginSuperAdmin
};
export default authObj;
