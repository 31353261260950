import React, { useState, useRef, Fragment, useEffect,useCallback } from "react";
import DateFormat from "../../helpers/DateFormat";
import crossImgs from "../../assets/images/icons/cross.svg";
import RefreshToken from "../../helpers/RefreshToken";
import ReactDatePickerHelper from "../../helpers/ReactDatePickerHelper";
import emptyclient from "../../assets/images/icons/clients-empty.svg";
import transactionService from "../../services/transaction.service";
import { useSelector } from "react-redux";
import moment from "moment";
import ModelPopup from "../../helpers/ModelPopup";
import CurrencyFormat from 'react-currency-format';

const SearchGridInputData = [
  {
    Name: "",
    userId: "",
    StartDate: "",
    EndDate: "",
    Size: 10,
    startIndex: 1,
    scrolled: false,
    isFirstTime: true,
  },
];
var fontStyle = {
  textTransform: "uppercase",
};

const SearchGridClientTransactions = (props) => {
  const [modalShow, setModalShow] = useState(false);
  const [name, setCompanyName] = useState("");
  const [DateFrom, setDateFrom] = useState();
  const [DateTo, setDateTo] = useState();
  const searchTbl = useRef();
  const [loadingData, setLoadingData] = useState(false);
  const [searchData, setSearchData] = useState();
  const [searchGridSuccess, setSearchGridSuccess] = useState(false);
  const [SearchInputDisabled, setSearchDisabled] = useState(false);
  const { user: currentUser } = useSelector((state) => state.auth);
  const [userId, setuserId] = useState(String(currentUser.user.UserId));
  const [ClientId, setClientId] = useState(String(currentUser.user.ClientId));
  const [ClientCurrency, setClientCurrency] = useState(String(currentUser.user?.ClientCurrency));
  const [isOldData, setIsOldData] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  //Transaction Models Showing Values
  const [TransactionsType, setTransactionsType] = useState("");
  const [Status, setStatus] = useState("");
  const [CurrencyCode, setCurrencyCode] = useState("");
  const [Amount, setAmount] = useState("");
  const [Date, setDate] = useState("");
  const [ReferenceNumber, setReferenceNumber] = useState("");
  //Model popup Transactions
  const [modalShowTransactions, setModalShowTransactions] = useState(false);
  
 
  const styleObj = {
    fontSize: 10,
  }
  const observer=useRef();
  const lastElementRef=useCallback(node=>{
  if(loadingData) return
  if(observer.current) observer.current.disconnect()
  observer.current=new IntersectionObserver(entries=>{
  if(entries[0].isIntersecting){
    tblScrollHandler();
  }
  })
  if(node) observer.current.observe(node)
  },[loadingData,searchData])
  useEffect(() => {
    SearchClickHandler(null,true);
  }, []);
  const handleInputSearchClick = (e) => {
    e.preventDefault();
    setModalShow(true);
    setSearchDisabled(true);
    setCompanyName("");
    dateToHandler("");
    dateFromHandler("");
  };
  const nameChangeHandler = (e) => {
    SearchGridInputData[0].Name = e.target.value;
    setCompanyName(SearchGridInputData[0].Name);
  };
  const dateFromHandler = (e) => {
    SearchGridInputData[0].StartDate = e;
    if (e) {
      let date = SearchGridInputData[0].StartDate;
      if (SearchGridInputData[0].StartDate !== "") {
        SearchGridInputData[0].StartDate = moment(SearchGridInputData[0].StartDate).format("YYYY-MM-DD");
        setDateFrom(date);
      } else {
        setDateFrom(date);
      }
    }
  };
  const dateFilter = (date) => {
    return new Date(date);
  };
  const dateToHandler = (e) => {
    SearchGridInputData[0].EndDate = e;
    if (e) {
      let date = SearchGridInputData[0].EndDate;
      if (SearchGridInputData[0].EndDate !== "") {
        SearchGridInputData[0].EndDate = moment(SearchGridInputData[0].EndDate).format("YYYY-MM-DD");
        setDateTo(date);
      } else {
        setDateTo(date);
      }
    }
  };
  const SearchClickHandler = (e,isFromUseEffect) => {
    setLoadingData(true);
    if (!SearchGridInputData[0].scrolled) {
      setSearchGridSuccess(false);
    }
    // if (isFromUseEffect) {
    //   setModalShow(true);
    // }
    // else
    setModalShow(false);
    setSearchDisabled(false);
    if(isOldData){
      transactionService
      .gettransactionhistory(
        "",
        "",
        ClientId,
        DateFrom,
        DateTo,
        SearchGridInputData[0].startIndex,
        Status,
        userId,
        "",
        SearchGridInputData[0].FetchAll
      )
      .then((resp) => {
        setLoadingData(false);
        if (resp && resp.data && resp.data.payload) {
          if (resp.data.success) {
            // if(isFromUseEffect)
            // setModalShow(true);
            // else
            setModalShow(false);
            setSearchDisabled(false);
          }
          if (
            resp.data.payload.result.pageModel.length > 0 ||
            searchData.length > 0
          ) {
            setTotalCount(resp.data.payload.result.totalCount);
            if (SearchGridInputData[0].scrolled) {
              let appendedData = searchData.concat(
                resp.data.payload.result.pageModel
              );
              setSearchData(appendedData);
             
            } else {
              setSearchData(resp.data.payload.result.pageModel);
             
              SearchGridInputData[0].startIndex = 1;
            }
            setSearchGridSuccess(true);

            SearchGridInputData[0].scrolled = false;
            // if (SearchGridInputData[0].isFirstTime) {
            searchTbl.current.onscroll = tblScrollHandler;
            SearchGridInputData[0].isFirstTime = false;
            // }
          }
        }
      })
      .catch((error) => {
        setSearchGridSuccess(false);
        setLoadingData(false);
        (async () => {
          let resp = await RefreshToken(
            error && error.response && error.response.status
          );
          if (resp) {
            SearchClickHandler();
          }
        })();
      });
    }
   else{
    transactionService
    .searhGridDebit(
      userId,
      ClientId,
      DateFrom,
      DateTo,
      SearchGridInputData[0].startIndex,
      
      
    )
    .then((resp) => {
      setLoadingData(false);
      if (resp && resp.data && resp.data.payload) {
        if (resp.data.success) {
          // if(isFromUseEffect)
          // setModalShow(true);
          // else
          setModalShow(false);
          setSearchDisabled(false);
        }
        if (
          resp.data.payload.result.pageModel.length > 0 ||
          searchData.length > 0
        ) {
          setTotalCount(resp.data.payload.result.totalCount);
          if (SearchGridInputData[0].scrolled) {
            let appendedData = searchData.concat(
              resp.data.payload.result.pageModel
            );
            setSearchData(appendedData);
          } else {
            setSearchData(resp.data.payload.result.pageModel);
            SearchGridInputData[0].startIndex = 1;
          }
          setSearchGridSuccess(true);

          SearchGridInputData[0].scrolled = false;
          // if (SearchGridInputData[0].isFirstTime) {
          searchTbl.current.onscroll = tblScrollHandler;
          SearchGridInputData[0].isFirstTime = false;
          // }
        }
      }
    })
    .catch((error) => {
      setSearchGridSuccess(false);
      setLoadingData(false);
      (async () => {
        let resp = await RefreshToken(
          error && error.response && error.response.status
        );
        if (resp) {
          SearchClickHandler();
        }
      })();
    });
   }
  };
  const tblScrollHandler = (e) => {
    if (
      !SearchGridInputData[0].scrolled &&
      SearchGridInputData[0].startIndex + 10 <= totalCount
    ) {
      SearchGridInputData[0].scrolled = true;
      SearchGridInputData[0].startIndex =
        SearchGridInputData[0].startIndex + 10;
      SearchClickHandler();
    }
  };
  const renderTableHeader = () => {
    let header = Object.values(props.headersdata);
    return header.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  };
  const showTransactionsPopup = (
    clientName,
    tokenId,
    amount,
    createdDate,
    currencyCode,
    debitCredit,
    description,
  ) => {
    setModalShowTransactions(true);
    setTransactionsType(tokenId);
    setStatus(debitCredit);
    setCurrencyCode(currencyCode);
    setAmount(amount);
    setDate(createdDate);
    setReferenceNumber(clientName);

  };
  const renderTableData = () => {
    return searchData.map((data, index) => {
      const {
        id,
        clientName,
        tokenId,
        amount,
        createdDate,
        debitCredit,
        currencyCode,
        description,
        transactionType,
        cardHolderName,
        firstName,
        lastName,
      } = data; //destructuring
      if(searchData.length===index+1){
        if(id!=92906){
        return (
          <tr key={tokenId}  ref={lastElementRef}>
            <td >
              <DateFormat date={createdDate} />
            </td>
            <td style={fontStyle} title={debitCredit}>{debitCredit}</td>
            <td style={fontStyle} title={transactionType}>{transactionType}</td>
            <td style={fontStyle}>{tokenId}</td>
            <td style={fontStyle}><CurrencyFormat value={amount}displayType={'text'} thousandSeparator={true} />&nbsp;{ClientCurrency===null ?"":ClientCurrency}</td>
            <td  title={firstName} >{firstName}{firstName?.length > 20 && ("...")}</td>
            <td  title={lastName} >{lastName}{lastName?.length > 20 && ("...")}</td>
            <td style={fontStyle} title={description}>{description?.substr(0, 10)}{description?.length > 20 && ("...")}</td>
          </tr>
        );
        }
      }
      else{
        if(id!=92906){
          return (
            <tr key={tokenId}>
              <td >
                <DateFormat date={createdDate} />
              </td>
              <td style={fontStyle} title={debitCredit}>{debitCredit}</td>
              <td style={fontStyle} title={transactionType}>{transactionType}</td>
              <td style={fontStyle}>{tokenId}</td>
              <td style={fontStyle}><CurrencyFormat value={amount}displayType={'text'} thousandSeparator={true} />&nbsp;{ClientCurrency===null ?"":ClientCurrency}</td>
              <td  title={firstName} >{firstName}{firstName?.length > 20 && ("...")}</td>
            <td  title={lastName} >{lastName}{lastName?.length > 20 && ("...")}</td>
              <td style={fontStyle} title={description}>{description?.substr(0, 10)}{description?.length > 20 && ("...")}</td>
            </tr>
          ); 
        }
       
      }
  
    });
  };
  const HideSearchPopupDiv = () => {
    setModalShow(false);
    setSearchDisabled(false);
    setCompanyName("");
  };
  const ClearSearchData = () => {
    setCompanyName("");
    dateToHandler("");
    dateFromHandler("");
    setDateFrom();
    setDateTo();
    setIsOldData(false);
    ClearInputData();
  };
  const ClearInputData = () => {
    if (document.getElementById("clientNameDD") !== null)
      document.getElementById("clientNameDD").value = "";
    setTimeout(() => {
      [...document.getElementsByClassName("r-input-group")].forEach(
        (element) => {
          element.children[0].value = "";
        }
      );
      document.getElementById("searchBtnClients").click();
    }, 500);
  };
  const handleSingleCheck = e => {
    const chckSame = e.target.checked;
    if (chckSame === true) {
      setIsOldData(true);
    }
    if (chckSame === false) {
      setIsOldData(false);
    }
  
  };
  const renderTransactionDetails = () => {
    return (

      <div class="row">
        <div class="col-md-12">
          <h3>Transaction Details</h3>
          <div class="mt-16">
            <form onSubmit={(e) => e.preventDefault()}>
              <ul class="ul-card-details-popup">

                <li class="d-flex justify-content-between mb-3">
                  <label >Name</label>
                  <span >{ReferenceNumber}</span>
                </li>
                <li class="d-flex justify-content-between mb-3">
                  <label >Token ID</label>
                  <span  >{TransactionsType}</span>
                </li>
                <li class="d-flex justify-content-between mb-3">
                  <label >Status</label>
                  <span>{Status}</span>
                </li>
                <li class="d-flex justify-content-between mb-3">
                  <label >Amount</label>
                  <span >{CurrencyCode}  {Amount}</span>
                </li>
                <li class="d-flex justify-content-between mb-3">
                  <label >Date</label>
                  <span ><DateFormat date={Date} /></span>
                </li>

              </ul>

            </form>
          </div>
        </div>
      </div>

    );
  };

  return (
    <Fragment>
      <div className="row align-items-center mt-14">
        <div className="col-6">
        </div>

      </div>
      <div className="mt-0 mt-sm-4">
        <div className="tab-content" id="myTabContent2">
          <div
            className="tab-pane fade show active"
            id="allClients"
            role="tabpanel"
            aria-labelledby="allClients-tab"
          >
            {/* no client found  */}
            <div className="d-flex align-content-center justify-content-center no-clients-msg d-none">
              <div className="text-center">
                <span className="icon-clients-empty">
                  <span className="path1"></span>
                  <span className="path2"></span>
                  <span className="path3"></span>
                  <span className="path4"></span>
                  <span className="path5"></span>
                  <span className="path6"></span>
                </span>
                <h3>Empty Clients Listing</h3>
                <h6>There is no Client(s) available for now.</h6>
              </div>
            </div>
            <Fragment>
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="row row-search">
                  <div className="col-xl-12 col-12">
                    <div className="form-group">
                      <div className="form-controls">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={props.placeholdervalue}
                          onClick={handleInputSearchClick}
                          disabled={SearchInputDisabled}
                        />
                        <span className="icon-search _ico"></span>
                        {/* search box  */}
                        <div
                          className="bo-cardsearch"
                          style={{ display: modalShow ? "" : "none" }}
                        >
                          <div className="row">
                            <div className="col-md-12">
                              <img
                                className="cross-icon cross-modal"
                                src={crossImgs}
                                aria-label="Close"
                                onClick={HideSearchPopupDiv}
                                alt=""
                              />
                            </div>
                            <div className="col-md-6">
                              <div className="form-group form-search mr-20">
                                <label>Date From</label>
                                <div className="form-controls">
                                  <ReactDatePickerHelper
                                    datevalue={DateFrom}
                                    onchangedate={dateFromHandler}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group form-search mr-20">
                                <label>Date To</label>
                                <div className="form-controls">
                                  <ReactDatePickerHelper
                                    datevalue={DateTo}
                                    onchangedate={dateToHandler}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 form-checkbox mb-16 mt-16">
                              <input
                               type="checkbox"
                               name="olderdatachk"
                               id="olderdatachk"
                               onClick={(e) => handleSingleCheck(e)}
                              />
                              <label class="checkbox-label" for="olderdatachk">
                                More Than 3 Months
                              </label>
                            </div>

                            <div className="col-md-12">
                              <div className="form-group submit-field form-search">
                                <button
                                  id="searchBtnClients"
                                  className="btn btn-large mr-19"
                                  onClick={SearchClickHandler}
                                >
                                  Search
                                </button>
                                <button
                                  className="btn btn-outline"
                                  onClick={ClearSearchData}
                                >
                                  Clear Search
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <span className="help-block d-none"></span>
                    </div>
                  </div>
                </div>
              </form>
            </Fragment>
          </div>

          {searchGridSuccess && (
            <div className="table-wrap" ref={searchTbl}>
              <table
                className="table table-borderless bo-table table-cards"
                onScroll={tblScrollHandler}
                onWheel={tblScrollHandler}
              >
                <thead>
                  <tr>{renderTableHeader()}</tr>
                </thead>
                <tbody>{renderTableData()}</tbody>
              </table>
            </div>
          )}
          {loadingData ? (
            <div className="loading-more">
              <div className="loader">
                <div></div>
              </div>
              <p>loading data</p>
            </div>
          ) : totalCount === 0 ? (
            <div className="d-flex align-content-center justify-content-center no-clients-msg ">
              <div className="text-center">
                <span className="icon-transaction-empty">
                  <span className="path1"></span><span className="path2"></span>
                  <span className="path3"></span><span className="path4"></span>
                  <span className="path5"></span><span className="path6"></span>
                </span>
                <h3>Empty Transactions Listing</h3>
                <h6>There are no transactions available for now.</h6>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div>
        <ModelPopup
          key={1}
          show={modalShowTransactions}
          onHide={() => setModalShowTransactions(false)}
          modelheading={""}
          modelbody={renderTransactionDetails()}
          showheader={"false"}
          dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
          className="smallPopup issueCardSmall"
          backdrop="static"
          keyboard={false}
        />
      </div>
    </Fragment>
  );

};

export default SearchGridClientTransactions;
