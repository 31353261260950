import ClientService from "../../services/client-managment.service";
import { useState, Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import RefreshToken from "../../helpers/RefreshToken";
import BoLoader from "../../helpers/BoLoader";


const GetAllSubClients = (props) => {

  useEffect(() => {
    getClientsDropDown();
  }, []);
  const [clientName, setClientName] = useState();
  const [allClientsData, setAllClientsData] = useState();
  const [boloader, setBoLoader] = useState();
  const { user: currentUser } = useSelector((state) => state.auth);

  const getClientsDropDown = () => {
    setBoLoader(true);
    ClientService.getclients(
      "", "","0001-01-01T00:00:00.000Z",
      "0001-01-01T00:00:00.000Z",1, "Active","","",
      String(currentUser.user.SuperClientId),
    )
      .then((response) => {
        setBoLoader(false);
        if (response?.data?.payload?.succeeded) {
          
          let data = response?.data?.payload?.result?.pageModel;
          setAllClientsData(data);

        }
      })
      .catch((error) => {
        setBoLoader(false);
        (async () => {
          let resp = await RefreshToken(
            error && error.response && error.response.status
          );
          if (resp) {
            getClientsDropDown();
          }
        })();
      });
  };
  return (
    <Fragment>
      <BoLoader loading={boloader}/>
      {allClientsData && (
        <Fragment>
          {currentUser?.role.includes("Super client") && (
            <select
              id="clientNameDD"
              className="form-control no-icon"
              onChange={props.clientnamechangehandler}
            >
              {props.searchgriddata === "Y" && (
                <option value="">Select Program Ledger</option>
              )}
              {allClientsData.map((options) => (
                <option key={options.uuid} currency={options.currency} value={options.id} paymentProcessor={options.paymentProcessor}
                  cardFlow={options.cardFlow} issuerCategory={options.issuerCategory} cardVisaMaster={options?.cardVisaMaster}>
                  {options.clientName}
                </option>
              ))}
            </select>
          )}

        </Fragment>
      )}
    </Fragment>
  );
};

export default GetAllSubClients;
