import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { history } from "../../helpers/history";

import { get_two_FA_Code } from "../../actions/account";
import TwoFaForm from "./TwoFaForm";
import BoPaymentWrapper from "../BoPaymentLeftWrapper";
import useWindowDimensions from "../../helpers/WindowWidth";

const TwoFAVerification = (props) => {
  const { height, width } = useWindowDimensions();
  const { registeredUser: registerationData } = useSelector(
    (state) => state.account
  );

  const [two_fa_successful, set_two_fa_Successful] = useState(false);
  const [State, seState] = useState();

  const dispatch = useDispatch();
  useEffect(() => {
    let Id = props.location.state.split("userid");
    seState(Id[2]);
    handleTwo_FA();
  }, []);
  if (!registerationData) {
    history.push("/register");
  }
  const handleTwo_FA = () => {
    if (registerationData) {
      set_two_fa_Successful(false);
      dispatch(get_two_FA_Code(registerationData.root.id))
        .then(() => {
          set_two_fa_Successful(true);
        })
        .catch(() => {
          set_two_fa_Successful(false);
        });
    }
  };

  return (
    <div className="row minvh-100">
      {width > 767 && <BoPaymentWrapper
        pagename={["Authentication", <br />, "Code"]}
        pagemsg={State}
      />}
      <div className="col-md-7 col-lg-8 onbo-content onbo-content-mbl">
        {two_fa_successful && (
          <TwoFaForm
            userEmail={registerationData.root.email}
            userId={registerationData.root.id}
            userMobile={registerationData.root.phoneNumber}
            pagename={props.location.state}
          />
        )}
        {/* </div> */}
      </div>
    </div>
  );
};

export default TwoFAVerification;
