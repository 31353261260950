import React, { useEffect, useRef, useState, Fragment } from "react";
import ReactFlagsSelect from "react-flags-select";
// import fileupload from "../../assets/images/icons/file-upload.svg";
// import file from "../../assets/images/icons/file.svg";
// import fileaddress from "../../assets/images/icons/file-address.svg";
import identityverificationObj from "../../services/identityverification.service";
import cross from "../../assets/images/icons/cross.svg";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import DialoguePopup from "../../helpers/DialoguePopup";

var objId = "";
var objPof = "";
const SettingIdVerification = () => {
  //Use Selectors
  const { user: currentUser } = useSelector((state) => state.auth);
  //Use States
  const [AddressProof, setAddressProof] = useState();
  const [FilePathAddressProof, setFilePathAddressProof] = useState("");
  const [AddressProofList, setAddressProofList] = useState([]);
  const [IDTypeList, setIDTypeList] = useState([]);
  const [IDType, setIDType] = useState();
  const [FilePathIdType, setFilePathIdType] = useState("");
  const [IssuingCountry, setIssuingCountry] = useState("");
  const [successfulSubmitted, setSuccessfulSubmitted] = useState(false);
  const [LogoNameID, setlogoNameID] = useState("");
  const [LogoNamePOF, setlogoNamePOF] = useState("");
  const [LogoSizeID, setlogoSizeID] = useState();
  const [LogoSizePOF, setlogoSizePOF] = useState();
  const [isValidCountry, setisValidCountry] = useState(true);
  const [isNewField, setisNewField] = useState(true);
  const [IsNewCountry, setIsNewCountry] = useState(true);
  const [IsNewIdType, setIsNewIdType] = useState(true);
  const [IsnewProofofAddress, setIsnewProofofAddress] = useState(true);
  const [isValidFile, setisValidFile] = useState(true);

  //GetUserDocumentsState
  const [userId, setuserId] = useState(currentUser.user.UserId);
  const [ClientId, setiClientId] = useState(currentUser.user.ClientId);
  const [UserId, setiUserId] = useState(currentUser.user.UserId);

  //useReFerences
  const POAAddress = useRef();
  const IdType = useRef();
  //loader
  const [boloader, setBoLoader] = useState(false);
  //Dialogue Popup
  const [modalShow, setModalShow] = useState(false);
  const [modelhdng, setMdlHdng] = useState("");
  const [modelbdy, setMdlBdy] = useState("");
  const [MdlIcon, setMdlIcon] = useState("");
  var dropDownIcon = {
    paddingLeft: "45px",
  };
  var acceptfiles = ".gif,.jpg,.jpeg,.png";

  //Use Effects
  useEffect(() => {
    identityverificationObj.getIdTypeEnums().then((response) => {
      const idType = response.data.payload.result.map((data) => {
        return {
          value: data.id,
          label: data.name,
        };
      });
      setIDTypeList(idType);
      let setdefaultval = parseInt(idType[0].value);
      setIDType(setdefaultval);
    });
  }, []);
  useEffect(() => {
    identityverificationObj.getProofofAddressEnums().then((response) => {
      if (response && response.data && response.data.payload) {
        const setAddressProofs = response.data.payload.result.map((data) => {
          return {
            value: data.id,
            label: data.name,
          };
        });
        setAddressProofList(setAddressProofs);
        let setdefaultval = parseInt(setAddressProofs[0].value);
        setAddressProof(setdefaultval);
      }
    });
  }, []);
  useEffect(() => {
    setisValidCountry(true);
  }, [IssuingCountry]);
  useEffect(() => {
    setisValidCountry(true);
  }, [IssuingCountry]);
  useEffect(() => {
    setisValidFile(true);
  }, [setlogoSizePOF]);
  useEffect(() => {
    identityverificationObj.getuseridentities(userId).then((response) => {
      if (response && response.data && response.data.payload.succeeded) {
        if (response.data.payload.result) {
          if (response.data.payload.result.filePathIdType) {
            objId = response.data.payload.result.filePathIdType;
            var obj1 = objId.split("name");
            setlogoNameID(obj1[1]);
          }
          if (response.data.payload.result.filePathAddressProof) {
            objPof = response.data.payload.result.filePathAddressProof;
            var obj2 = objPof.split("name");
            setlogoNamePOF(obj2[1]);
          }

          setIssuingCountry(response.data.payload.result.issuingCountry);
          setIDType(response.data.payload.result.idType);
          setAddressProof(response.data.payload.result.addressProof);
        }
        else {
          setIsNewCountry(false);
          setisNewField(false);
        }

      }

    });
  }, []);
  const changeHandlerPoa = (e) => {
    const status = e.target.selectedOptions[0].value;
    let statusvalue = parseInt(status);
    setAddressProof(statusvalue);
  };
  const changeHandlerIdType = (e) => {
    const status = e.target.selectedOptions[0].value;
    let statusvalue = parseInt(status);
    setIDType(statusvalue);
  };
  const saveUser = () => {
    if (IssuingCountry.trim() == null || IssuingCountry.trim() === "") {
      setisValidCountry(false);
      return;
    } else setisValidCountry(true);
    setBoLoader(true);
    identityverificationObj
      .updateCardHolderIdentityDocuments(
        IssuingCountry,
        IDType,
        AddressProof,
        FilePathIdType,
        FilePathAddressProof,
        UserId,
        ClientId
      )
      .then((response) => {
        setBoLoader(false);
        if (response && response.data && !response.data.isError) {
          setSuccessfulSubmitted(true);
          errorAndSuccessModl(
            "Success",
            "Updated Successfully",
            "icon-successful"
          );
        }
      })
      .catch((err) => {
        setBoLoader(false);
        let msgBody = err.response.data.title
          ? err.response.data.title
          : "Internal Server Error";
        let errIcon = "icon-error";
        errorAndSuccessModl("Error", msgBody, errIcon);
      });
  };
  const errorAndSuccessModl = (heading, body, errIcon) => {
    setModalShow(true);
    setMdlHdng(heading);
    setMdlBdy(body);
    setMdlIcon(errIcon);
  };

  const onChangeIDtype = (e) => {
    const file_data = e.target.files[0];
    const formdata = new FormData();
    let reg = /(.*?)\.(jpg|bmp|jpeg|PNG|png)$/;
    if (!file_data.name.match(reg)) {
      setisValidFile(false);
      return false;
    }
    setlogoNameID(file_data.name);
    var size = formatBytes(file_data.size);
    setlogoSizeID(size);
    formdata.append("File", file_data);
    identityverificationObj.uploaduserdocs(formdata).then((response) => {
      if (response) {
        setFilePathIdType(response.data.payload);
      }
    });
    setIsNewIdType(false);
    setisNewField(false);
  };

  const onChangeProofofaddresses = (e) => {
    const file_data = e.target.files[0];
    const formdata = new FormData();
    let reg = /(.*?)\.(jpg|bmp|jpeg|PNG|png)$/;
    if (!file_data.name.match(reg)) {
      setisValidFile(false);
      return false;
    }
    setlogoNamePOF(file_data.name);
    var size = formatBytes(file_data.size);
    setlogoSizePOF(size);
    formdata.append("File", file_data);
    identityverificationObj.uploaduserdocs(formdata).then((response) => {
      if (response) {
        setFilePathAddressProof(response.data.payload);
      }
    });
    setIsnewProofofAddress(false);
    setisNewField(false);
  };

  const onCrossImg = () => {
    setlogoNamePOF("");
  };

  const onCrossImgId = () => {
    setlogoNameID("");
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };
  const onEditCountry = (e) => {
    setIsNewCountry(false);
    setisNewField(false);
  };
  const onEditIdType = (e) => {
    document.getElementById("id_idtype").click();
  };
  const onEditProofofAddrs = (e) => {
    document.getElementById("id_poatype").click();
  };
  const onCloseEditCountryType = () => {
    setIsNewCountry(true);
    setisNewField(true);
  };
  const onCloseEditIdType = () => {
    setIsNewIdType(true);
    setisNewField(true);
  };
  const onCloseEditProofOfAddress = () => {
    setIsnewProofofAddress(true);
    setisNewField(true);
    setisValidFile(true);
  };
  const resetPage = () => {
    identityverificationObj.getuseridentities(userId).then((response) => {
      if (response.data.payload.succeeded) {
        if (response.data.payload.result) {
          if (response.data.payload.result.filePathIdType) {
            objId = response.data.payload.result.filePathIdType;
            var obj1 = objId.split("name");
            setlogoNameID(obj1[1]);
          }
          if (response.data.payload.result.filePathAddressProof) {
            objPof = response.data.payload.result.filePathAddressProof;
            var obj2 = objPof.split("name");
            setlogoNamePOF(obj2[1]);
          }
          setIssuingCountry(response.data.payload.result.issuingCountry);
          setIDType(response.data.payload.result.idType);
          setAddressProof(response.data.payload.result.addressProof);
        }
      }
    });
    setIsnewProofofAddress(true);
    setisValidFile(true);
    if (IssuingCountry !== "") {
      setisNewField(true);
      setIsNewCountry(true);
    }
    setIsNewIdType(true);
  };
  const cancelBtn = () => {
    resetPage();
  };

  return (
    <Fragment>
      <div className="">
        <h3>Your ID is Verified</h3>
        <form>
          <div className="row mt-32">
            <div className="col-md-4 col-sm-6">
              <div className="form-group">
                <label for="email">Country/region</label>
                <div className="form-controls">
                  {IsNewCountry ? (
                    <Fragment>
                      <ReactFlagsSelect
                        countries={[
                          "GB",
                          "AT",
                          "BE",
                          "BG",
                          "CY",
                          "CZ",
                          "DE",
                          "DK",
                          "EE",
                          "ES",
                          "FI",
                          "FR",
                          "GR",
                          "HR",
                          "HU",
                          "IE",
                          "IT",
                          "LT",
                          "LU",
                          "LV",
                          "MT",
                          "NL",
                          "PO",
                          "PT",
                          "RO",
                          "SE",
                          "SI",
                          "SK",
                        ]}
                        className="form-control no-icon"
                        selected={IssuingCountry}
                        onSelect={(code) => setIssuingCountry(code)}
                        disabled
                      />
                      {IssuingCountry !== "" && <span
                        className="icon-round icon-edit-mini"
                        onClick={onEditCountry}
                      ></span>}
                    </Fragment>
                  ) : (
                    <Fragment>
                      <ReactFlagsSelect
                        countries={[
                          "GB",
                          "AT",
                          "BE",
                          "BG",
                          "CY",
                          "CZ",
                          "DE",
                          "DK",
                          "EE",
                          "ES",
                          "FI",
                          "FR",
                          "GR",
                          "HR",
                          "HU",
                          "IE",
                          "IT",
                          "LT",
                          "LU",
                          "LV",
                          "MT",
                          "NL",
                          "PO",
                          "PT",
                          "RO",
                          "SE",
                          "SI",
                          "SK",
                        ]}
                        className="form-control no-icon"
                        selected={IssuingCountry}
                        onSelect={(code) => setIssuingCountry(code)}
                      />
                      {!isValidCountry && (
                        <span className="help-block has_error">
                          Country is Required
                        </span>
                      )}
                    </Fragment>
                  )}
                </div>
              </div>
            </div>
            <div className="col-sm-12"></div>

            {IsNewIdType && (
              <div className="col-lg-4 col-md-6">
                <div className="form-group">
                  <label for="email">Driving License</label>
                  <div className="form-controls">
                    <input
                      className="form-control double-icon"
                      disabled
                      value={LogoNameID}
                    />

                    <span className="icon-upload-file _ico">
                      <span className="path1"></span>
                      <span className="path2"></span>
                      {/* <img src={fileupload} alt="uploadicon" /> */}
                    </span>
                    <span
                      className="icon-round icon-edit-mini"
                      onClick={onEditIdType}
                    >
                      <input
                        type="file"
                        hidden
                        id="id_idtype"
                        className="form-control"
                        name="logo"
                        onChange={onChangeIDtype}
                        ref={IdType}
                      />
                    </span>
                    {!isValidFile && (
                      <span className="help-block has_error">
                        Invalid file format
                      </span>
                    )}
                  </div>
                </div>
              </div>
            )}
            {/* <!-- editable --> */}
            {!IsNewIdType && (
              <div className="col-12 ">
                <div className="row align-items-end">
                  <div className="col-lg-4 col-md-6">
                    <div className="form-group">
                      <label for="email">Select ID Type</label>
                      <Fragment>
                        <div className="form-controls">
                          <select
                            onChange={changeHandlerIdType}
                            className="form-control no-icon"
                            style={dropDownIcon}
                          >
                            {IDTypeList.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                          <span className="icon-card-id _ico"></span>
                        </div>
                      </Fragment>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6">
                    <div className="form-group">
                      <div className="bo-file d-flex mt-0">
                        <div className="file-name">
                        <span className="icon-file _ico"></span>
                          {/* <img src={file} alt="fileicon" /> */}
                          <h5>
                            <span>{LogoNameID}</span>
                          </h5>
                        </div>
                        <div className="ml-auto file-size">
                          <span>{LogoSizeID}</span>
                          <img
                            className="cross-icon"
                            src={cross}
                            alt="crossicon"
                            onClick={onCloseEditIdType}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="col-sm-12"></div>

            {/* <!-- static --> */}
            {IsnewProofofAddress && (
              <div className="col-lg-4 col-md-6">
                <div className="form-group">
                  <label for="email">Proof of Address</label>
                  <div className="form-controls">
                    <input
                      className="form-control double-icon"
                      disabled
                      value={LogoNamePOF}
                    />

                    <span className="icon-PDF _ico">
                      {/* <img src={fileaddress} alt="fileaddressicon" /> */}
                    </span>
                    <div>
                      <input
                        type="file"
                        hidden
                        id="id_poatype"
                        onChange={onChangeProofofaddresses}
                        className="form-control file-control"
                        accept={acceptfiles}
                        name="logoPof"
                      />
                      <span
                        className="icon-round icon-edit-mini"
                        onClick={onEditProofofAddrs}
                      ></span>
                      {!isValidFile && (
                        <span className="help-block has_error">
                          Invalid file format
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* <!-- editable --> */}
            {!IsnewProofofAddress && (
              <div className="col-12 ">
                <div className="row align-items-end">
                  <div className="col-lg-4 col-md-6">
                    <div className="form-group">
                      <label for="email">Proof of Address</label>
                      <div className="form-controls">
                        <select
                          onChange={changeHandlerPoa}
                          className="form-control no-icon"
                          style={dropDownIcon}
                        >
                          {AddressProofList.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                        <span className="icon-address _ico"></span>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6">
                    <div className="form-group">
                      <div className="bo-file d-flex mt-0">
                        <div className="file-name">
                        <span className="icon-file _ico"></span>
                          {/* <img src={file} alt="fileicon" /> */}
                          <h5>
                            <span>{LogoNamePOF}</span>.jpg
                          </h5>
                        </div>
                        <div className="ml-auto file-size">
                          <span>{LogoSizePOF}</span>
                          <img
                            className="cross-icon"
                            src={cross}
                            alt="crossicon"
                            onClick={onCloseEditProofOfAddress}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          {!isNewField && (
            <div className="col-md-12">
              <div className="form-group submit-field d-flex ">
                <Link
                  to="#"
                  className="btn btn-outline mr-19"
                  data-bs-dismiss="modal"
                  onClick={cancelBtn}
                >
                  Cancel
                </Link>
                <Link to="#" className="btn btn-large" onClick={saveUser}>
                  Submit
                </Link>
              </div>
            </div>
          )}
        </form>
      </div>
      <DialoguePopup
        show={modalShow}
        onHide={() => {
          setModalShow(false);
          resetPage();
        }}
        modelheading={modelhdng}
        modelbody={modelbdy}
        modelicon={MdlIcon}
        hideallpopups={() => {
          setModalShow(false);
          resetPage();
        }}
      />
    </Fragment>
  );
};

export default SettingIdVerification;
