import React, { useState, useRef, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import { clientSignup } from "../../actions/account";
import CheckButton from "react-validation/build/button";
import BoPaymentWrapper from "../BoPaymentLeftWrapper";
import { history } from "../../helpers/history";
import accountService from "../../services/account.service";
import BoLoader from "../../helpers/BoLoader";
import useWindowDimensions from "../../helpers/WindowWidth";

const required = (value) => {
  if (!value) {
    document.getElementById("Password").classList.add("has_error");
    return <span className="help-block">Password is required!</span>;
  } else document.getElementById("Password").classList.remove("has_error");
};
const crequired = (value) => {
  if (!value) {
    document.getElementById("cPassword").classList.add("has_error");
    return <span className="help-block">Password is required!</span>;
  } else document.getElementById("cPassword").classList.remove("has_error");
};
let passwordValue = "";
const vpassword = (value) => {
  passwordValue = value;
  if (value.length < 8 || value.length > 20) {
    document.getElementById("Password").classList.add("has_error");
    return (
      <div className="help-block" role="alert">
        The password must be between 8 and 20 characters!
      </div>
    );
  }
};
const vcpassword = (value) => {
  if (value.length < 8 || value.length > 20) {
    document.getElementById("cPassword").classList.add("has_error");
    return (
      <div className="help-block" role="alert">
        The password must be between 8 and 20 characters!
      </div>
    );
  } else if (value !== passwordValue) {
    document.getElementById("cPassword").classList.add("has_error");
    return (
      <span className="help-block" role="alert">
        The password and confirm password must match!
      </span>
    );
  }
};
const ClientSignup = (props) => {
  const dispatch = useDispatch();
  const { height, width } = useWindowDimensions();
  const form = useRef();
  const checkBtn = useRef();
  const { message } = useSelector((state) => state.message);
  const [Name, setBoUserName] = useState("");
  const [Username, setUsername] = useState("");
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [cPasswordShown, setCPasswordShown] = useState(false);
  const [showHidePassClass, setshowHidePassClass] = useState("icon-hidden");
  const [showHideCPassClass, setshowHideCPassClass] = useState("icon-hidden");
  const [boloader, setBoLoader] = useState(false);
  const [Rolename, setRolename] = useState("client admin");
  const [UserId, setUserId] = useState("");
  const [emailmsg, setemailmsg] = useState(true);
  const [clientlogo, setclientlogo] = useState("");
  const [theme, settheme] = useState("");
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const userId = params.get("Id");
  useEffect(() => {
    setBoLoader(true);
    accountService
      .getUserbyId(userId)
      .then((resp) => {
        setBoLoader(false);
        if (resp.data.success) {
          setBoUserName(resp.data.root.name);
          setEmail(resp.data.root.email);
          setUserId(userId);
          settheme(resp.data.root.themeName)
          setclientlogo(resp.data.root.logoUrl);
          if(resp.data.root){
           // let themeName=resp.data.root.themeName;
            localStorage.setItem("Reload","Reload ClientSignup");
            var primary = resp.data.root.primaryColor;
            var secondary = resp.data.root.secondaryColor;
            var lighter1 = resp.data.root.colorPrimarylighter1;
            var lighter2 =resp.data.root.colorPrimarylighter2;
            document.documentElement.style.setProperty("--color-primary", primary);
            document.documentElement.style.setProperty('--color-secondary',secondary);
            document.documentElement.style.setProperty('--color-primary-lighter-1',lighter1);
            document.documentElement.style.setProperty('--color-primary-lighter-2',lighter2);
            // if(themeName==="theme1"){
            //   localStorage.setItem("ThemeSelected", "theme1");
            // }
            //  if(themeName==="theme2"){
            //   localStorage.setItem("ThemeSelected", "theme2");
            // }
            // if(themeName==="theme3"){
            //   localStorage.setItem("ThemeSelected", "theme3");
            // }
            //  if(themeName==="theme4"){
            //   localStorage.setItem("ThemeSelected", "theme4");
            // }
            // if(themeName==="theme5"){
            //   localStorage.setItem("ThemeSelected", "theme5");
            // }
            //  if(themeName==="theme6"){
            //   localStorage.setItem("ThemeSelected", "theme6");
            // }
            // if(themeName==="theme7"){
            //   localStorage.setItem("ThemeSelected", "theme7");
            // }
            //  if(themeName==="theme8"){
            //   localStorage.setItem("ThemeSelected", "theme8");
            // }
            // if(themeName==="theme9"){
            //   localStorage.setItem("ThemeSelected", "theme9");
            // }
            let themestorage=localStorage.getItem("ThemeReload");
            if(themestorage==="true"){
              localStorage.setItem("ThemeReload","false");
              // window.location.reload();
          }
          }

        }
      })
      .catch(() => {
        setBoLoader(false);
      });
    setTimeout(() => {
      setemailmsg(false);
    }, 5000);
  }, []);

  const handleRegister = (e) => {
    setUsername(Email);
    //setUserId(userId);
    e.preventDefault();
    form.current.validateAll();
    if (checkBtn.current.context._errors.length === 0) {
      setBoLoader(true);
      dispatch(clientSignup(Name,
        Email,
        Password,
        UserId))
        .then(() => {
          setBoLoader(false);
          localStorage.removeItem("userRegisteration");
          //history.push("/identityVerification","To the Client Admin Account");
         history.push("/2FAVerification", "To the Client Admin Account" + "userid" + userId);

         
        })
        .catch((err) => {
          setBoLoader(false);
        });
    }
  };
  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };
  const onChangeConfirmPassword = (e) => {
    const confirmPassword = e.target.value;
    setConfirmPassword(confirmPassword);
  };
  const showHidePassHandler = () => {
    setPasswordShown(passwordShown ? false : true);
    setshowHidePassClass(passwordShown ? "icon-hidden" : "icon-show");
  };
  const showHideCPassHandler = () => {
    setCPasswordShown(cPasswordShown ? false : true);
    setshowHideCPassClass(cPasswordShown ? "icon-hidden" : "icon-show");
  };
  return (
    <div className="row minvh-100">
      <BoLoader loading={boloader} />
      {width > 767 && <BoPaymentWrapper
        pagename={["Create An", <br />, "Account"]}
        pagemsg={"To the Client Admin Account"}
        clientlogo={clientlogo}
        themename={theme}
      />}

      <div class="col-md-7 col-lg-8 onbo-content onbo-content-mbl">
        <div class="row">
          {width <= 767 && <div className="col-12">
            <h2>Create Account</h2>
          </div>}
          <div class="bo-on-screens position-relative">
            {
              emailmsg &&   <div class="col-sm-12 col-md-10 alert-messages alert-info alert-mbl-common">
              <span class="icon-success"></span>
              <p>The Email Verification is Successful!</p>
            </div>
            }
          
            {message && (
              <Fragment>
                {!message.data.success && (
                  <div class="col-sm-12 col-md-10 alert-messages alert-info alert-mbl-common">
                    <span class="icon-info"></span>
                    <p>
                      {message.data.message.includes(",") ? (
                        <Fragment>
                          {message.data.message.split(".")[0].replace(",", "")}
                          <br />
                          {message.data.message.split(".")[1].replace(",", "")}
                          <br />
                          {message.data.message.split(".")[2].replace(",", "")}
                        </Fragment>
                      ) : (
                        message.data.message
                      )}
                    </p>
                  </div>
                )}
              </Fragment>
            )}
            <div className="register-top-mbl row">
              <div class="col-md-8 mb-40">
                <h3>Here you can review and update your details.</h3>
              </div>
              <div class="col-sm-12 col-md-10"></div>
              <Form onSubmit={handleRegister} ref={form}>
                <div class="row">
                  <div class="col-md-6">
                    <div className="form-group">
                      <label htmlFor="cardholderName">Company Name*</label>
                      <div class="form-controls">
                        <Input
                          type="text"
                          className="form-control"
                          name="cardholderName"
                          value={Name}
                          disabled
                        />
                        <span class="icon-company _ico"></span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div className="form-group">
                      <label htmlFor="email">Email</label>
                      <div class="form-controls">
                        <Input
                          type="text"
                          className="form-control"
                          name="email"
                          value={Email}
                          disabled
                        />
                        <span class="icon-email _ico"></span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div className="form-group" id="Password">
                      <label htmlFor="password">Password*</label>
                      <div class="form-controls">
                        <Input
                          type={passwordShown ? "text" : "password"}
                          className="form-control"
                          name="password"
                          value={Password}
                          onChange={onChangePassword}
                          validations={[required, vpassword]}
                          maxLength="20"
                        />
                        <span class="icon-lock _ico"></span>
                        <span
                          className={"show-password " + showHidePassClass}
                          onClick={showHidePassHandler}
                        ></span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6" id="cPassword">
                    <div className="form-group">
                      <label htmlFor="confirmPassword">Confirm Password*</label>
                      <div class="form-controls">
                        <Input
                          type={cPasswordShown ? "text" : "password"}
                          className="form-control"
                          name="confirmPassword"
                          value={confirmpassword}
                          maxLength="20"
                          onChange={onChangeConfirmPassword}
                          validations={[crequired, vcpassword]}
                        />
                        <span class="icon-lock _ico"></span>
                        <span
                          className={"show-password " + showHideCPassClass}
                          onClick={showHideCPassHandler}
                        ></span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 mt-4">
                    <div class="form-group submit-field">
                      <button className="btn btn-large w-100">Sign Up</button>
                    </div>
                  </div>
                </div>
                <CheckButton style={{ display: "none" }} ref={checkBtn} />
              </Form>
            </div>

          </div>
        </div>
      </div>

    </div>
  );
};

export default ClientSignup;
