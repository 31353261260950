import React, { useState, useEffect } from "react";
import DonutChart from "../../helpers/DonutChart";
import cardArrow from "../../assets/images/icons/bo-arrow.svg";
import addclienticon from "../../assets/images/icons/add-client.svg";
import depositService from "../../services/deposit.service";
import SearchGrid from "../../helpers/SearchGrid";
import { Env } from "../../enviroment/environment";
import { Fragment } from "react";
import DepositClientGrid from "./DepositClientGrid";
import DepositClientSubmit from "./DepositClientSubmit";
import { useSelector } from "react-redux";
import RefreshToken from "../../helpers/RefreshToken";
import { Link } from "react-router-dom";
import DialoguePopup from "../../helpers/DialoguePopup";
import ModelPopup from "../../helpers/ModelPopup";
import BoLoader from "../../helpers/BoLoader";
import ExportToExcel from "../../helpers/ExportToExcel";
const API_URL = Env.DepositApiUrl;
var colorscheme;
let donutChartDepoistData = {};
const DepositClients = () => {
  // const [modalShow, setModalShow] = useState(false);
  const { user: currentUser } = useSelector((state) => state.auth);
  const [confirmmodalShow, setCnfrmModalShow] = useState(false);
  const [depositContent, setDepositContent] = useState();
  const [
    clientAdminDepositDataSuccessful,
    setclientAdminDepositDataSuccessful,
  ] = useState(false);
  const [clientId, setClientUuid] = useState(String(currentUser.user.ClientId));
  //Dialouge Popup Error Messages
  const [modalShow, setModalShow] = useState(false);
  const [modelhdng, setMdlHdng] = useState("");
  const [MdlIcon, setMdlIcon] = useState("");
  const [modelbdy, setMdlBdy] = useState("");
  //Success Message
  const [modalsuccessfullShow, setModalsuccessfullShow] = useState(false);
  const [refereshdata, setRefereshdata] = useState(false);
//Loader
const [boloader, setBoLoader] = useState(false);
//ExportToExcel
const [dataset, setDataset] = useState();



  useEffect(() => {
    if (currentUser) {
      //var themename = currentUser.user.ThemeName;
      let primary = currentUser.user.PrimaryColor;
      let secondary = currentUser.user.SecondaryColor;
      colorscheme=[primary,secondary]
      // if (themename === "theme1") {
      //   colorscheme = ["#89455E", "#C1A6B0", "#E0DCDC"];
      // }
      // if (themename === "theme2") {
      //   colorscheme = ["#175873", "#A9C7BF", "#D0E7E0"];
      // }
      // if (themename === "theme3") {
      //   colorscheme = ["#0077B6", "#48CAE4", "#90E0EF"];
      // }
      // if (themename === "theme4") {
      //   colorscheme = ["#723D46", "#C28981", "#C9CBA3"];
      // }
      // if (themename === "theme5") {
      //   colorscheme = ["#B07D62", "#E6B8A2", "#EDC4B3"];
      // }
      // if (themename === "theme6") {
      //   colorscheme = ["#EA5455", "#FFD460", "#FFE49B"];
      // }
      // if (themename === "theme7") {
      //   colorscheme = ["#0C2D48", "#88C9DF", "#B1D4E0"];
      // }
      // if (themename === "theme8") {
      //   colorscheme = ["#433673", "#0BB3D9", "#05F2AF"];
      // }
      // if (themename === "theme9") {
      //   colorscheme= ["#06225F","#EA7C3A","#767171"]
      // }
    } else {
      colorscheme = ["#89455E", "#E0DCDC"];
    }
  }, []);
  useEffect(() => {
    getCountDeposit();
  }, []);
  const getCountDeposit = () => {
    setRefereshdata(false);
    setclientAdminDepositDataSuccessful(false);
    setBoLoader(true);
    depositService.getDepositStatusData(clientId).then(
      (response) => {
        setRefereshdata(true);
        setBoLoader(false);
        if (response && response.data && response.data.payload) {
          if (response.data.payload.result.totalDeposit > 0) {
            donutChartDepoistData = {
              "Avg Deposit": response.data.payload.result.avgDeposit,
              "Last Deposit": response.data.payload.result.lastDeposit,
              "Total Deposit": response.data.payload.result.totalDeposit,
            };
            setDepositContent(response.data.payload.result);
            setclientAdminDepositDataSuccessful(true);
          }
        }
      },
      (error) => {
        setRefereshdata(true);
        setBoLoader(false);
        (async () => {
          let resp = await RefreshToken(
            error && error.response && error.response.status
          );
          if (resp) {
            window.location.reload();
          }
        })();
      }
    );
  };
  const errorAndSuccessModl = (heading, body, Icon) => {
    setModalShow(true);
    setMdlHdng(heading);
    setMdlBdy(body);
    setMdlIcon(Icon);
  };
  const goToDeposits = () => {
    getCountDeposit();
  };
  return (
    <div className="col-xl-9 col-lg-12 bo-main-content">
      {/* <h3>Stats</h3> */}
      <div className="row mt-20">
      <BoLoader loading={boloader} />
        {/* {clientAdminDepositDataSuccessful && (
          <div className="col-md-6 col-xl-4">
            <div className="col-stats col-stats-chart mb-40">
              <div className="row align-items-center">
                <div className="col-md-6 col-12">
                  <div className="chart-wrap">
                    <DonutChart
                      chartdata={{ ...donutChartDepoistData }}
                      colorscheme={colorscheme}
                    />
                    <div className="donut-center-text">
                      <h5>{depositContent.totalDeposit}</h5>
                      <p>Total Deposits</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="chart-legends ">
                    <div className="row">
                      <div className="col-6 col-md-12">
                        <div className="mb-20">
                          <p>Total Deposits</p>
                          <h5>
                            <span></span>${depositContent.totalDeposit}
                          </h5>
                        </div>
                      </div>
                      <div className="col-6 col-md-12">
                        <div className="mb-20">
                          <p>Last Deposit</p>
                          <h5>
                            <span></span>${depositContent.lastDeposit}
                          </h5>
                        </div>
                      </div>
                      <div className="col-6 col-md-12">
                        <div>
                          <p>Avg. Deposit</p>
                          <h5>
                            <span></span>${depositContent.avgDeposit}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )} */}
        <div
          className="col-md-3 col-xl-2"
          onClick={() => setCnfrmModalShow(true)}
        >
          <div className="col-stats col-stats-action mb-40">
            <div data-bs-target="\clients" data-bs-toggle="modal">
              <span className="icon-add-client">
                <span className="path1"></span>
                <span className="path2"></span>
                <span className="path3"></span>
                <span className="path4"></span>
                <span className="path5"></span>
                <span className="path6"></span>
              </span>
              {/* <img src={addclienticon} /> */}
              <br />
              <Link to="#" className="pt-3">
                Deposit
                <span className="icon-bo-arrow"></span>
                {/* <img src={cardArrow} /> */}
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div>
      {refereshdata && (
        <div>
        <div className="row align-items-center">
        <div className="col-sm-6">
          <h3>Deposit History</h3>
        </div>
        <div className="col-sm-6 toRight">
          <ExportToExcel 
          Dataset={dataset}
          Name="Deposit"
          calledfrom="deposit"
          
          />
        </div>
      </div>
        <DepositClientGrid
          methodname="getdeposits"
          placeholdervalue="Search Ledger"
          headersdata={[
            "REFERENCE NO.",
            "DATE",
            //"AMOUNT",
            "ACCEPTED AMOUNT",
            "SENDING BANK",
            "ACCOUNT NO",
            "STATUS",
            "VIEW",
          ]}
          apiurl={API_URL}
          screenname="deposit"
          setDataset={setDataset}
        />
</div>
      )}  
        <DepositClientSubmit
          show={confirmmodalShow}
          onHide={() => setCnfrmModalShow(false)}
          onAddDeposit={() => {
            setCnfrmModalShow(false);
            setModalsuccessfullShow(true);
          }}
        />
      </div>
      <DialoguePopup
        show={modalShow}
        onHide={() => setModalShow(false)}
        modelheading={modelhdng}
        modelbody={modelbdy}
        modelicon={MdlIcon}
      
      />
      <ModelPopup
        key={1}
        show={modalsuccessfullShow}
        onHide={() => setModalsuccessfullShow(false)}
        modelheading={""}
        showheader={"false"}
        className="smallPopup issueCardSmall"
        dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
        backdrop="static"
        keyboard={false}
        modelbody={[
          <Fragment>
            <div className="row">
              <div className="col-md-12">
                <div className="icon-alert icon-alert-popup">
                  <span className="icon-successful">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </span>
                </div>
                <h2>Successful</h2>
                <div className="mt-16">
                  <div>
                    <form>
                      <div className="row">
                        <div className="col-md-12">
                          <p>Deposit Submitted Successfully</p>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group submit-field mt-40 mb-0">
                            <Link
                              to="#"
                              className="btn btn-large w-100"
                              onClick={() => {
                                setModalsuccessfullShow(false);
                                goToDeposits();
                              }}
                            >
                              Done
                            </Link>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>,
        ]}
      />
    </div>
  );
};

export default DepositClients;
