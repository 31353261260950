import ClientService from "../services/client-managment.service";
import { useState, Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
// import RefreshToken from "./RefreshToken";

const GetClientsLoadDebit = (props) => {
  //const [recallMthd, setMthdRcl] = useState(false);

  useEffect(() => {
    getClientsDropDown();
  }, []);
  const [clientName, setClientName] = useState();
  const [allClientsData, setAllClientsData] = useState();
  const { user: currentUser } = useSelector((state) => state.auth);
  const getClientsDropDown = () => {
    ClientService.getAllClients()
      .then((response) => {
        if (response.data.payload.succeeded) {
          let data = response.data.payload.result;
          data.shift();
          setAllClientsData(data);
          const clientNameVal = data.map((options) => {
            let val = "";
            if (options.id === currentUser.user.ClientId) {
              val = options.clientName;
              return val;
            } else return "";
          });

          const finalVal = clientNameVal.filter((x) => {
            return x !== "";
          });
          setClientName(finalVal);
        }
      })
      .catch((err) => {
        // if (err && err.response && err.response.status === 401) {
        //   (async () => {
        //     let resp = await RefreshToken();
        //     if (resp) setMthdRcl(true);
        //   })();
        // }
      });
  };
  return (
    <Fragment>
      {allClientsData && (
        <Fragment>
              <input
              type="text"
              className="form-controls no-icon"
              value={clientName}
              title={clientName}
              disabled="disabled"
             
            />
        </Fragment>
      )}
    </Fragment>
  );
};

export default GetClientsLoadDebit;
