import React, { useState, useRef,Fragment } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { resetpassword } from "../../actions/account";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import BoPaymentWrapper from "../BoPaymentLeftWrapper";
import BoLoader from "../../helpers/BoLoader";
import useWindowDimensions from "../../helpers/WindowWidth";

const requiredPassword = (value) => {
  if (!value) {
    document.getElementById("rstPass_frmGrp1").classList.add("has_error");
    return <span className="help-block">Please enterd a valid password!</span>;
  } else
    document.getElementById("rstPass_frmGrp1").classList.remove("has_error");
};
const requiredCPassword = (value) => {
  if (!value) {
    document.getElementById("rstPass_frmGrp2").classList.add("has_error");
    return (
      <span className="help-block">Please enterd a valid Confirm Password!</span>
    );
  } else
    document.getElementById("rstPass_frmGrp2").classList.remove("has_error");
};
let passwordValue = "";
const vpassword = (value) => {
  passwordValue = value;
  if (value.length < 8 || value.length > 20) {
    document.getElementById("rstPass_frmGrp1").classList.add("has_error");
    return (
      <span className="help-block">
        The password must be between 8 and 20 characters!
      </span>
    );
  } else
    document.getElementById("rstPass_frmGrp1").classList.remove("has_error");
};

const vcpassword = (value) => {
  if (value.length < 8 || value.length > 20) {
    document.getElementById("rstPass_frmGrp2").classList.add("has_error");
    return (
      <span className="help-block">
        The password must be between 8 and 20 characters!
      </span>
    );
  } else if (value !== passwordValue) {
    document.getElementById("rstPass_frmGrp2").classList.add("has_error");
    return (
      <span className="help-block">
        The password and confirm password must match!
      </span>
    );
  } else
    document.getElementById("rstPass_frmGrp1").classList.remove("has_error");
};
const ResetPassword = () => {
  const { height, width } = useWindowDimensions();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const email = params.get("email");
  const userId = params.get("userId");
  const code = params.get("code");
  const form = useRef();
  const checkBtn = useRef();
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [successful, setSuccessful] = useState(false);
  const [showHidePassClass, setshowHidePassClass] = useState("icon-hidden");
  const [showHideCPassClass, setshowHideCPassClass] = useState("icon-hidden");
  const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();
  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };
  const onChangeConfirmPassword = (e) => {
    const confirmPassword = e.target.value;
    setConfirmPassword(confirmPassword);
  };
  const [passwordShown, setPasswordShown] = useState(false);
  const [cpasswordShown, setCPasswordShown] = useState(false);
  const [boloader, setBoLoader] = useState(false);

  const showHidePassHandler = () => {
    setPasswordShown(passwordShown ? false : true);
    setshowHidePassClass(passwordShown ? "icon-hidden" : "icon-show");
  };
  const showHideCPassHandler = () => {
    setCPasswordShown(cpasswordShown ? false : true);
    setshowHideCPassClass(cpasswordShown ? "icon-hidden" : "icon-show");
  };
  const handleResetPassword = (e) => {
    e.preventDefault();

    setSuccessful(false);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      setBoLoader(true);

      dispatch(resetpassword(userId, code, email, password))
        .then(() => {
          setBoLoader(false);
          setSuccessful(true);
        })
        .catch(() => {
          setBoLoader(false);
          setSuccessful(false);
        });
    }
  };
  return (
    <div className="row minvh-100">
      { width > 767 && <BoPaymentWrapper pagename={["Change", <br />, "Password"]} />}
      
      <BoLoader loading={boloader} />
      <div className="col-md-7 col-lg-8 onbo-content onbo-content-mbl">
        {!successful ? (
          <div className="row">
            <div className="bo-on-screens position-relative">
            {message && (
              <Fragment>
                {!message.success && (
                  <div class="col-sm-12 col-md-10 alert-messages alert-info alert-mbl-common">
                    <span class="icon-info"></span>
                    <p>
                      {message.message.includes(",") ? (
                        <Fragment>
                          {message.message.split(".")[0].replace(",", "")}
                          <br />
                          {message.message.split(".")[1].replace(",", "")}
                          <br />
                          {message.message.split(".")[2].replace(",", "")}
                        </Fragment>
                      ) : (
                        message.message
                      )}
                    </p>
                  </div>
                )}
              </Fragment>
            )}
              <div className="col-md-6 mb-5">
                <h3 className="mb-4">Change Password</h3>
                <h6>
                  Please note that when changing your password, we ask you to
                  set yourself a secure password.
                </h6>
              </div>
              <div className="col-md-8 col-lg-6 col-xl-5">
                <Form onSubmit={handleResetPassword} ref={form}>
                  <div
                    className="form-group mb-20 password-field"
                    id="rstPass_frmGrp1"
                  >
                    <label htmlFor="password">New Password*</label>
                    <div className="form-controls">
                      <Input
                        type={passwordShown ? "text" : "password"}
                        className="form-control"
                        name="password"
                        value={password}
                        onChange={onChangePassword}
                        validations={[requiredPassword, vpassword]}
                        maxLength="21"
                        placeholder="Confirm Password"
                      />
                      <span className="icon-lock _ico"></span>
                      <span
                        className={"show-password " + showHidePassClass}
                        onClick={showHidePassHandler}
                      ></span>
                    </div>
                  </div>
                  <div
                    className="form-group mb-20 password-field"
                    id="rstPass_frmGrp2"
                  >
                    <label htmlFor="confirmPassword">Confirm Password*</label>
                    <div className="form-controls">
                      <Input
                        type={cpasswordShown ? "text" : "password"}
                        className="form-control"
                        name="confirmPassword"
                        value={confirmpassword}
                        onChange={onChangeConfirmPassword}
                        validations={[requiredCPassword, vcpassword]}
                        maxLength="21"
                        placeholder="Enter Confirm Password"
                      />
                      <span className="icon-lock _ico"></span>
                      <span
                        className={"show-password " + showHideCPassClass}
                        onClick={showHideCPassHandler}
                      ></span>
                    </div>
                  </div>
                  <div className="form-group submit-field mt-40">
                    <button className="btn btn-large w-100">Save</button>
                  </div>

                  <CheckButton style={{ display: "none" }} ref={checkBtn} />
                </Form>
              </div>
            </div>
          </div>
        ) : (
          message && (
            <div className="bo-on-screens position-relative onbo-content-center minh-row-mbl">
              {message.success && (
                <div className="col-md-8 col-lg-5">
                  <div className="icon-alert">
                    <span className="icon-successful">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </span>
                  </div>
                  <h3 className="mb-4">Successful</h3>
                  <h6>Your Password has been saved successfully</h6>
                  <div className="form-group submit-field mt-40">
                    <Link className="btn btn-large w-100" to={`Login`}>
                      Got It
                    </Link>
                  </div>
                </div>
              )}
              {/* {!message.success && (
                <div className="col-md-8 col-lg-5">
                  <div className="icon-alert">
                    <span className="icon-error">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </span>
                  </div>
                  <h3 className="mb-4">Unsuccessful</h3>
                  <h6>{message.message}</h6>
                  <div className="form-group submit-field mt-40">
                    <Link className="btn btn-large w-100" to={`Login`}>
                      Got It
                    </Link>
                  </div>
                </div>
              )} */}
            </div>
          )
        )}
      </div>
    </div>
  );
};
export default ResetPassword;
