import React, { useState, useRef, Fragment } from "react";
import arrowright from "../../assets/images/icons/arrow-right.svg";
import transfer from "../../assets/images/icons/transfer.svg";
import recieving from "../../assets/images/icons/receiving.svg";
import withdraw from "../../assets/images/icons/withdraw.svg";
import crossImgs from "../../assets/images/icons/cross.svg";
import ReactDatePickerHelper from "../../helpers/ReactDatePickerHelper";
import validator from "validator";
import cardholdercardsObj from "../../services/cardholder-cards.service";
import { Link } from "react-router-dom";

const SearchGridInputData = [
  {
    Name: "",
    TokenId: "",
    DateIssue: "",
    DateExpiry: "",
    startIndex: 1,
    scrolled: false,
    isFirstTime: true,
  },
];

const SearchGridCards = (props) => {
  const [modalShow, setModalShow] = useState(false);
  const [SearchInputDisabled, setSearchDisabled] = useState(false);
  const [name, setName] = useState("");
  const [tokenid, setTokenId] = useState("");
  const [dateissue, setDateissue] = useState("");
  const [dateexpiry, setDateexpiry] = useState("");
  const [searchData, setSearchData] = useState();
  const [searchGridSuccess, setSearchGridSuccess] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const searchTbl = useRef();

  const handleInputSearchClick = (e) => {
    e.preventDefault();
    setModalShow(true);
    setSearchDisabled(true);
  };
  const HideSearchPopupDiv = () => {
    setModalShow(false);
    setSearchDisabled(false);
  };
  const dateIssueHandler = (e) => {
    SearchGridInputData[0].DateIssue = e;
    if (e)
      SearchGridInputData[0].DateIssue = dateFilter(
        SearchGridInputData[0].DateIssue
      );
    setDateissue(SearchGridInputData[0].DateIssue);
  };
  const dateExpiryHandler = (e) => {
    SearchGridInputData[0].dateexpiry = e;
    if (e)
      SearchGridInputData[0].dateexpiry = dateFilter(
        SearchGridInputData[0].dateexpiry
      );
    setDateexpiry(SearchGridInputData[0].dateexpiry);
  };
  const dateFilter = (date) => {
    return new Date(date);
  };
  const nameChangeHandler = (e) => {
    SearchGridInputData[0].Name = e.target.value;
    setName(SearchGridInputData[0].Name);
  };
  const tokenChangeHandler = (e) => {
    SearchGridInputData[0].TokenId = e.target.value;
    if (validator.isNumeric(SearchGridInputData[0].TokenId))
      setTokenId(SearchGridInputData[0].TokenId);
    else setTokenId("");
  };
  const renderTableHeader = () => {
    let header = Object.values(props.headersdata);
    return header.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  };
  const searhGridCardHoldersCards = () => {
    // e.preventDefault();
    setLoadingData(true);
    if (!SearchGridInputData[0].scrolled) {
      setSearchGridSuccess(false);
    }
    setModalShow(false);
    setSearchDisabled(false);
    cardholdercardsObj
      .searhGridCardHoldersCards(
        name,
        tokenid || "",
        dateissue || new Date("0001-01-01"),
        dateexpiry || new Date("0001-01-01"),
        SearchGridInputData[0].startIndex
      )
      .then((resp) => {
        setLoadingData(false);
        if (resp.data.success) {
          setModalShow(false);
          setSearchDisabled(false);
        }
        if (
          resp.data.payload.result.pageModel.length > 0 ||
          searchData.length > 0
        ) {
          if (SearchGridInputData[0].scrolled) {
            let appendedData = searchData.concat(
              resp.data.payload.result.pageModel
            );
            setSearchData(appendedData);
          } else {
            setSearchData(resp.data.payload.result.pageModel);
            SearchGridInputData[0].startIndex = 1;
          }
          setSearchGridSuccess(true);

          SearchGridInputData[0].scrolled = false;
          // if (SearchGridInputData[0].isFirstTime) {
          searchTbl.current.onscroll = tblScrollHandler;
          SearchGridInputData[0].isFirstTime = false;
          // }
        }
      })
      .catch(() => {
        setSearchGridSuccess(false);
        setLoadingData(false);
      });
  };
  const tblScrollHandler = (e) => {
    if (!SearchGridInputData[0].scrolled) {
      SearchGridInputData[0].scrolled = true;
      SearchGridInputData[0].startIndex =
        SearchGridInputData[0].startIndex + 10;
      searhGridCardHoldersCards();
    }
    //  }
  };

  return (
    <Fragment>
      <div className="row align-items-center mt-20">
        <div className="col-6">
          <h3>Transactions</h3>
        </div>
        <div className="col-6">
          <div className="a-arrow-btn toRight">
            <Link to="#">
              View all <span className="icon-btn_arrow_right"></span>
              {/* <img src={arrowright} alt="arrow" /> */}
            </Link>
          </div>
        </div>
      </div>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="row mt-20 row-search">
          <div className="col-xl-12 col-12">
            <div className="form-group">
              <div className="form-controls">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search Card"
                  onClick={handleInputSearchClick}
                  disabled={SearchInputDisabled}
                />
                <span className="icon-search _ico"></span>
                {/* search box  */}
                <div
                  className="bo-cardsearch"
                  style={{ display: modalShow ? "" : "none" }}
                >
                  <div className="row">
                    <div className="col-md-6">
                      <img
                        className="cross-icon cross-modal"
                        src={crossImgs}
                        aria-label="Close"
                        onClick={HideSearchPopupDiv}
                        alt=""
                      />
                      <div className="form-group form-search mr-20">
                        <label>Name</label>
                        <div className="form-controls">
                          <input
                            className="form-control"
                            value={name}
                            onChange={nameChangeHandler}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group form-search">
                        <label>Token ID</label>
                        <div className="form-controls">
                          <input
                            className="form-control"
                            value={tokenid}
                            onChange={tokenChangeHandler}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group form-search mr-20">
                        <label>Date Issue</label>
                        <div className="form-controls">
                          <ReactDatePickerHelper
                            datevalue={dateissue}
                            onchangedate={dateIssueHandler}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group form-search  mr-20">
                        <label>Date Expiry</label>
                        <div className="form-controls">
                          <ReactDatePickerHelper
                            datevalue={dateexpiry}
                            onchangedate={dateExpiryHandler}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group submit-field form-search">
                        <Link to="#" className="btn btn-large">
                          Search
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <span className="help-block d-none"></span>
            </div>
          </div>
        </div>
      </form>
      <div className="table-wrap">
        <table className="table table-borderless bo-table table-details">
          <thead>
            <tr>{renderTableHeader()}</tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div className="d-flex bo-col-transaction">
                  <div>
                    <i className="icon-receiving">
                      <i className="path1"></i>
                      <i className="path2"></i>
                      <i className="path3"></i>
                    </i>
                    {/* <img src={recieving} alt="arrow" /> */}
                    <span></span>
                  </div>
                  <div>
                    <h6>Receiving</h6>
                    <p> 2808988</p>
                  </div>
                </div>
              </td>
              <td>March 23, 2021</td>
              <td>5:11 PM</td>
              <td>Deposit</td>
              <td>+$800</td>
              <td>
                <span className="icon-show"></span>
              </td>
            </tr>
            <tr>
              <td>
                <div className="d-flex bo-col-transaction">
                  <div>
                    <i className="icon-withdraw">
                      <i className="path1"></i>
                      <i className="path2"></i>
                    </i>
                    {/* <img src={withdraw} alt="arrow" /> */}
                    <span></span>
                  </div>
                  <div>
                    <h6>Withdraw</h6>
                    <p> 2808988</p>
                  </div>
                </div>
              </td>
              <td>March 23, 2021</td>
              <td>5:11 PM</td>
              <td>Bills and Utilities </td>
              <td>-$800</td>
              <td>
                <span className="icon-show"></span>
              </td>
            </tr>
            <tr>
              <td>
                <div className="d-flex bo-col-transaction">
                  <div>
                    <i className="icon-transfer">
                      <i className="path1"></i>
                      <i className="path2"></i>
                    </i>
                    {/* <img src={transfer} alt="arrow" /> */}
                    <span></span>
                  </div>
                  <div>
                    <h6>Transfer</h6>
                    <p> 2808988</p>
                  </div>
                </div>
              </td>
              <td>March 23, 2021</td>
              <td>5:11 PM</td>
              <td>ATM Withdrawal</td>
              <td>+$800</td>
              <td>
                <span className="icon-show"></span>
              </td>
            </tr>
            <tr>
              <td>
                <div className="d-flex bo-col-transaction">
                  <div>
                    <i className="icon-withdraw">
                      <i className="path1"></i>
                      <i className="path2"></i>
                    </i>
                    {/* <img src={withdraw} alt="arrow" /> */}
                  </div>
                  <div>
                    <h6>Withdraw</h6>
                    <p> 2808988</p>
                  </div>
                </div>
              </td>
              <td>March 23, 2021</td>
              <td>5:11 PM</td>
              <td>Travel</td>
              <td>-$500</td>
              <td>
                <span className="icon-show"></span>
              </td>
            </tr>
            <tr>
              <td>
                <div className="d-flex bo-col-transaction">
                  <div>
                    <i className="icon-transfer">
                      <i className="path1"></i>
                      <i className="path2"></i>
                    </i>
                    {/* <img src={transfer} alt="arrow" /> */}
                  </div>
                  <div>
                    <h6>Transfer</h6>
                    <p> 2808988</p>
                  </div>
                </div>
              </td>
              <td>March 23, 2021</td>
              <td>5:11 PM</td>
              <td>Card Transfer</td>
              <td>-$500</td>
              <td>
                <span className="icon-show"></span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="loading-more">
        <div className="loader">
          <div></div>
        </div>
        <p>loading more</p>
      </div>
    </Fragment>
  );
};

export default SearchGridCards;
