import axios from "axios";
import authHeader from "./auth-header";
import { Env } from "../enviroment/environment";

const API_URL = Env.UserApiUrl;
const submitHelpQuery = (UserId, Title, QuerryText) => {
  return axios
    .post(
      API_URL + "querryemail",
      { UserId, Title, QuerryText },
      { headers: authHeader() }
    )
    .then((response) => {
      return response;
    });
};
const HelpObj = {
  submitHelpQuery,
};
export default HelpObj;
