import axios from "axios";
import authHeader from "./auth-header";
import { Env } from "../enviroment/environment";

const API_URL = Env.UserApiUrl; // "http://localhost:8000/api/user/";

const getPublicContent = () => {
  return axios.get(API_URL + "all");
};

const getCardHolders = () => {
  return axios.get(API_URL + "cardholder", { headers: authHeader() });
};

const getClilentAdmin = () => {
  return axios.get(API_URL + "clientadmin", { headers: authHeader() });
};

const getSuperAdminData = () => {
  return axios.get(API_URL + "superadmin", { headers: authHeader() });
};

const Userobj = {
  getPublicContent,
  getCardHolders,
  getClilentAdmin,
  getSuperAdminData,
};
export default Userobj;
