import React,{useState,useEffect} from "react";
import SettingsPageData from "../SettingsPageData";
import SettingRoles from "./SettingRoles";

const Settings = () => {
  const [ProfileTab, setProfileTab] = useState("nav-link d-flex align-items-center");
  const [ProfileTabAreaSelected, setProfileTabAreaSelected] = useState("false");
  const [RolesTab, setRolesTab] = useState("nav-link d-flex align-items-center");
  const [RolesTabAreaSelected, setRolesTabAreaSelected] = useState("false");
  const [content, setContent] = useState(<p>No Record Found</p>);
  useEffect(() => {
    btnProfile();
  }, []);
  const btnProfile=()=>{
    setProfileTab("nav-link active d-flex align-items-center");
    setProfileTabAreaSelected("true");
    setRolesTab("nav-link d-flex align-items-center");
    setRolesTabAreaSelected("false");

    setContent(<SettingsPageData/>);
  }

  const btnRoles=()=>{
    setRolesTab("nav-link active d-flex align-items-center");
    setRolesTabAreaSelected("true");
    setProfileTab("nav-link d-flex align-items-center");
    setProfileTabAreaSelected("false");
    setContent(<SettingRoles />);
  }

  return (
    <div className="col-xl-9 col-lg-12 bo-main-content bo-main-nopad">
      <div className="bo-tabs-mbl">
        <ul
          className="nav nav-tabs nav-tabs-profile"
          id="myTab2"
          role="tablist"
        >
          <li className="nav-item" role="presentation">
            <button
              className={ProfileTab}
              id="profile-tab"
              data-bs-toggle="tab"
              onClick={btnProfile}
              data-bs-target="#profile"
              type="button"
              role="tab"
              aria-controls="profile"
              aria-selected={ProfileTabAreaSelected}
            >
              <span className="icon-client"></span>
              Profile info
            </button>
          </li>
          {/* <li className="nav-item" role="presentation">
            <button
              className={RolesTab}
              id="roles-tab"
              data-bs-toggle="tab"
              onClick={btnRoles}
              data-bs-target="#roles"
              type="button"
              role="tab"
              aria-controls="roles"
              aria-selected={RolesTabAreaSelected}
            >
              <span className="icon-variant"></span>Roles
            </button>
          </li> */}
        </ul>
      </div>
      <div className="bo-main-content-sub">
        <div className="tab-content" id="myTabContent2">
          {/* <div
            className="tab-pane fade show active"
            id="profile"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            <SettingsPageData />
          </div>

          <div
            className="tab-pane fade"
            id="roles"
            role="tabpanel"
            aria-labelledby="roles-tab"
          >
          
          </div> */}
           {content}
        </div>
      </div>
    </div>
  );
};

export default Settings;
