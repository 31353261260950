import axios from "axios";
import { Env } from "../enviroment/environment";
import authHeader from "./auth-header";

const API_URL = Env.CardHolderApiUrl;
const API_URL_CARD = Env.CardsApiUrl;
const API_URL_Transaction = Env.TransactionApiUrl;
const API_URL_ACCOUNT = Env.IBANApiUrl;

const getcardholderDetail = (CardHolderId) => {
  return axios
    .get(API_URL + "getcardholderDetail?CardHolderId=" + CardHolderId, {
      headers: authHeader(),
    })
    .then((res) => {
      return res;
    });
};
const updateCardHolderStatus = (userId, isActive) => {
  return axios
    .post(
      API_URL +
        "updateStatus?userId=" +
        userId +
        "&isActive=" +
        isActive,
      {},
      { headers: authHeader() }
    )
    .then((res) => {
      return res;
    });
};
const gettransactiondata = () => {
  return axios
    .post(API_URL + "getcardholderDetail", {}, { headers: authHeader() })
    .then((res) => {
      return res;
    });
};
const getCardDetail = (userId) => {
  return axios
    .get(API_URL + "getcarddetaildashboard?userId="+userId, {
      headers: authHeader(),
    })
    .then((response) => {
      return response;
    });
};
const suspendCard = (TokenId,OperatorUserId,UserIdCard,IsBlock,ClientId,Description,CardCurrency) => {
  return axios
    .post(
      API_URL_CARD + "blockunblockcard",
      {TokenId,OperatorUserId,UserIdCard,IsBlock,ClientId,Description,CardCurrency},
      {
        headers: authHeader(),
      }
    )
    .then((resp) => {
      return resp;
    });
};
const unSuspendCard = (TokenId,OperatorUserId,UserIdCard,IsBlock,ClientId,Description,CardCurrency) => {
  return axios
    .post(
      API_URL_CARD + "blockunblockcard",
      {TokenId,OperatorUserId,UserIdCard,IsBlock,ClientId,Description,CardCurrency},
      {
        headers: authHeader(),
      }
    )
    .then((resp) => {
      return resp;
    });
};
const onEditCard = (FirstName,LastName,Address,City,Country,ZipCode,Userid,Email) => {
  return axios
    .post(
      API_URL + "editcardholderdetails",
      {FirstName,LastName,Address,City,Country,ZipCode,Userid,Email},
      {
        headers: authHeader(),
      }
    )
    .then((resp) => {
      return resp;
    });
};
const checkPendingCard = (clienId) => {
  return axios
    .post(API_URL + "pendingcardcount?clienId="+clienId, {
      headers: authHeader(),
    })
    .then((response) => {
      return response;
    });
};
const enableActivationProcess = (userId) => {
  return axios
    .post(
      API_URL_CARD +`reenableactivationprocess?userId=${userId}`
      ,
      {
        headers: authHeader(),
      }
    )
    .then((resp) => {
      return resp;
    });
};


const renameActivationProcessHandler = (userId) => {
  return axios
    .post( 
      API_URL_CARD + `resendactivationemail?userId=${userId}`,
      {
        headers: authHeader(),
      }
    )
    .then((resp) => {
      return resp;
    });
};
const refillbalance = (userId) => {
  return axios
    .post(API_URL_Transaction + "refillbalance?userId=" + userId,{}, {
      headers: authHeader(),
    })
    .then((res) => {
      return res;
    });
};
const getCardCvv = (userId) => {
  return axios
    .get(API_URL_CARD + "getcardcvv?userId=" + userId,{}, {
      headers: authHeader(),
    })
    .then((res) => {
      return res;
    });
};
const getibandetail = (accountId) => {
  return axios
    .post(API_URL_ACCOUNT + "getibandetail?accountId=" + accountId,{}, {
      headers: authHeader()
    })
    .then((res) => {
      return res;
    });
};
const cardHolderService = {
  getcardholderDetail,
  updateCardHolderStatus,
  gettransactiondata,
  getCardDetail,
  suspendCard,
  unSuspendCard,
  onEditCard,
  checkPendingCard,
  renameActivationProcessHandler,
  enableActivationProcess,
  refillbalance,
  getCardCvv,
  getibandetail
};
export default cardHolderService;
