import { useState, useEffect,Fragment } from "react";
import DonutChart from "../../helpers/DonutChart";
import SearchGrid from "../../helpers/SearchGrid";
import transactionService from "../../services/transaction.service";
import { Env } from "../../enviroment/environment";
import BoLoader from "../../helpers/BoLoader";
import { useSelector } from "react-redux";
import RefreshToken from "../../helpers/RefreshToken";
import SearchGridClientTransactions from "../clilentadmin/SearchGridClientTransactions";
import ExportToExcel from "../../helpers/ExportToExcel";
import { haspermissions } from "../permissions/PermissionTypes";
import clientmanagmentObj from "../../services/client-managment.service";
import CurrencyFormat from 'react-currency-format';
import ModelPopup from "../../helpers/ModelPopup";
import ReactDatePickerHelper from "../../helpers/ReactDatePickerHelper";
const API_URL = Env.TransactionApiUrl;
var colorscheme;
let donutChartTransData = {};
const SearchGridInputData = [
  {
    Name: "",
    ClientId: "",
    DateFrom: "",
    TokenId: "",
    DateTo: "",
    startIndex: 1,
    scrolled: false,
    isFirstTime: true,
    Status: "",
    TransactionType: "",
    FetchAll: false
  },
];
const Transactions = () => {
  const [transactionContent, settransactionContent] = useState();
  const [TransDataSuccessful, setTransDataSuccessful] = useState(false);
  const [boloader, setBoLoader] = useState(false);
  const { user: currentUser } = useSelector((state) => state.auth);
  const clientId = currentUser.role.includes("Client admin")
    ? currentUser.user.ClientId
    : "";
    const [dataset, setDataset] = useState();
    const [ClientBalance, setClientBalance] = useState();
    const [PaymentProcessor, setPaymentProcessor] = useState(String(currentUser.user?.ClientCurrency));
    const [showExcelDownloadModal, setshowExcelDownloadModal] = useState(false);
    const [dateFrom, setDateFrom] = useState("");
    const [dateTo, setDateTo] = useState("");
    const [isExcelDownload, setiisExcelDownload] = useState(false);
    const [searchGridSuccess, setSearchGridSuccess] = useState(false);
    const [loadingData, setLoadingData] = useState(false);
    const [isOldData, setIsOldData] = useState(false);
    var isledgerhistory = "col-sm-5 toRight ms-auto mt-sm-100 mt-4 mb-4";
    useEffect(() => {
      clientmanagmentObj.getClientBalance(clientId).then(
        (response) => {
          if (response && response.data && response.data.payload)
          setClientBalance(response.data.payload.result);
        },
        (error) => {}
      );
    }, []);
  useEffect(() => {
    setBoLoader(true);
    setTransDataSuccessful(false);
    transactionService.getTransactionStatsData(clientId).then(
      (response) => {
        setBoLoader(false);
        if (response && response.data && response.data.payload) {
          if(response.data.payload.result.totalTransaction>0)
          {
            donutChartTransData = {
              Amount: response.data.payload.result.totalAmount,
              Users: response.data.payload.result.totalUsers,
              Transactions: response.data.payload.result.averageTransactions,
            };
            settransactionContent(response.data.payload.result);
            setTransDataSuccessful(true);
          }
        }
      },
      (error) => {
        (async () => {
          let resp = await RefreshToken(
            error && error.response && error.response.status
          );
          if (resp) {
            window.location.reload();
            setBoLoader(false);
          } else {
            setBoLoader(false);
          }
        })();
      }
    );
  }, []);
  useEffect(() => {
    if (currentUser) {
      let primary = currentUser.user.PrimaryColor;
      let secondary = currentUser.user.SecondaryColor;
      colorscheme=[primary,secondary]
    }
    else{
      colorscheme= ["#89455E", "#E0DCDC"]
    }
  }, []);
  if(currentUser){
    haspermissions.ViewLedgerBalance= currentUser?.user?.Permissions?.includes("View Ledger Balance");
    haspermissions.ViewLedgerHistory= currentUser?.user?.Permissions?.includes("View Ledger History");

  }
  const showExcelModel = (e) => {
    setIsOldData(false);
    setshowExcelDownloadModal(true);
  }
  const downloadExportToExcel = () => {
    setBoLoader(true);
    if(isOldData){
      clientmanagmentObj
      .gettransactionhistory(
        "",
        "",
        currentUser?.user?.ClientId?.toString(),
        dateFrom || new Date("0001-01-01"),
      dateTo || new Date("0001-01-01"),
      SearchGridInputData[0].startIndex,
       
        "",
        currentUser?.user?.UserId,
        "",
        true,
      )
      .then((resp) => {
        setLoadingData(false);
        if (resp && resp.data && resp.data.payload) {
          if (resp.data.success) {
            setBoLoader(false);
             setDataset(resp?.data?.payload?.result?.pageModel)
            setshowExcelDownloadModal(false);
            setiisExcelDownload(true);
           
          }
        }
      })
      .catch((error) => {
        setBoLoader(false);
      setSearchGridSuccess(false);
      setshowExcelDownloadModal(false);
        setSearchGridSuccess(false);
        setLoadingData(false);
        (async () => {
          let resp = await RefreshToken(
            error && error.response && error.response.status
          );
          if (resp) {
            downloadExportToExcel();
          }
        })();
      });
    }
   else{
    clientmanagmentObj
    .searhGridDebit(
      "",
      "",
      currentUser?.user?.ClientId?.toString(),
      dateFrom || new Date("0001-01-01"),
      dateTo || new Date("0001-01-01"),
      SearchGridInputData[0].startIndex,
      "",
      currentUser?.user?.UserId,
      "",
      true
    ).then((resp) => {
      setBoLoader(false);
      setDataset(resp?.data?.payload?.result?.pageModel)
      setshowExcelDownloadModal(false);
      setiisExcelDownload(true);
      setDateFrom("");
      setDateTo("");
    }).catch((error) => {
      setBoLoader(false);
      setSearchGridSuccess(false);
      setshowExcelDownloadModal(false);
      setLoadingData(false);
      (async () => {
        let resp = await RefreshToken(
          error && error.response && error.response.status
        );
        if (resp) {
          downloadExportToExcel();
        }
      })();
    });

   }

  }
  const dateFromHandler = (e) => {
    SearchGridInputData[0].DateFrom = e;
    if (e)
      SearchGridInputData[0].DateFrom = dateFilter(
        SearchGridInputData[0].DateFrom
      );
    setDateFrom(SearchGridInputData[0].DateFrom);
  };
  const dateFilter = (date) => {
    return new Date(date);
  };
  const dateToHandler = (e) => {
    SearchGridInputData[0].DateTo = e;
    if (e)
      SearchGridInputData[0].DateTo = dateFilter(SearchGridInputData[0].DateTo);
    setDateTo(SearchGridInputData[0].DateTo);
  };
  const handleSingleCheck = e => {
    const chckSame = e.target.checked;
    if (chckSame === true) {
      setIsOldData(true);
    }
    if (chckSame === false) {
      setIsOldData(false);
    }
  
  };
  const resetModal=()=>{
    setIsOldData(false);
    setiisExcelDownload(false);
    dateToHandler("");
    dateFromHandler("");
  }
  return (
    <div className="col-xl-9 col-lg-12 bo-main-content">
      <BoLoader loading={boloader} />
      <section>
        {/* <h3>Stats</h3> */}
        <div className="row mt-20">
        <div className="col-md-6 col-xl-4">
 { haspermissions.ViewLedgerBalance && (<div
   className="col-stats col-stats-card mb-40"
   style={{ backgroundImage: `url(${""})` }}
 >
   <p>Ledger Balance</p>
   <h1><CurrencyFormat value={ClientBalance} displayType={'text'} thousandSeparator={true} renderText={value => <h1>{value} {PaymentProcessor===null ?"-":PaymentProcessor}</h1>} />  </h1>
   <span className="icon-Wallet"></span>
   
 </div>)}
 
        </div>
        {
              haspermissions.ViewLedgerHistory && (

                <div className="col-md-3 toRight ms-auto" Style={"width=82px"} >
                  {
                    isExcelDownload ? (
                    <div>
                      <ExportToExcel
                      Dataset={dataset}
                      calledfrom="transaction"
                      Name={"LedgerClient"}
                    />
                     <button id="searchBtnExportToExcel"className="btn btn-large mt-3" onClick={resetModal}>Reset</button>
                    </div>
                    ) : (
                      <button id="searchBtnExportToExcel" className="btn btn-large mr-19" onClick={showExcelModel}> Export</button>)
                  }

                </div>
              )}
          
        </div>
      </section>

    {
      haspermissions.ViewLedgerHistory && ( <section className="">
       
      <div className="row align-items-center">
        <div className="col-sm-6">
          <h3>Ledger History</h3>
        </div>
        
      </div>
      <SearchGridClientTransactions
        placeholdervalue="Search Ledger"
        headersdata={[
          "DATE",
          "TRANSACTION",
          "TRANSACTION TYPE",
          "TOKEN ID",
          "AMOUNT",
          "FIRST NAME",
          "LAST NAME",
          "DESCRIPTION",
     
    
        ]}
        screenname="cardholdertransactions"
        setDataset={setDataset}
      />
    </section>)
    }
     <ModelPopup
        show={showExcelDownloadModal}
        onHide={() => setshowExcelDownloadModal(false)}
        modelheading={""}
        showheader={"false"}
        className="smallPopup issueCardSmall"
        dialogClassName="modal-dialog modal-dialog-centered mwidth-52"
        backdrop="static"
        keyboard={false}
        modelbody={[
          <Fragment>
            <div className="row">
              <div className="col-md-12">
                <h2>Export To Excel</h2>
                <div className="mt-16">
                  <div>
                    <form onSubmit={(e) => e.preventDefault()}>
                      <div className="row">

                        <div className="col-md-6">
                          <div className="form-group form-search mr-20">
                            <label>Date From</label>
                            <div className="form-controls">
                              <ReactDatePickerHelper
                                datevalue={dateFrom}
                                onchangedate={dateFromHandler}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group form-search mr-20">
                            <label>Date To</label>
                            <div className="form-controls">
                              <ReactDatePickerHelper
                                datevalue={dateTo}
                                onchangedate={dateToHandler}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 form-checkbox mb-16 mt-16">
                              <input
                               type="checkbox"
                               name="olderdatachks"
                               id="olderdatachks"
                               onClick={(e) => handleSingleCheck(e)}
                              />
                              <label class="checkbox-label" for="olderdatachks">
                                More Than 3 Months
                              </label>
                            </div>
                        <div className="col-md-12">
                          <div className="form-group submit-field d-flex justify-content-between mt-20 mb-0">

                            <button
                              className="btn btn-large"
                              onClick={downloadExportToExcel}
                            >
                              Done
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>,
        ]}
      /> 
    </div>
  );
};

export default Transactions;
