import React, { useState, useEffect, useRef, Fragment } from "react";
import commonService from "../services/common.service";
import validator from "validator";
import { history } from "../helpers/history";
import DateFormat from "./DateFormat";
import crossImg from "../assets/images/icons/cross.svg";
import SearchGridInputHelper from "./SearchGridInputHelper";
import SearchGridTableHelper from "./SearchGridTableHelper";
import PendingCardHolders from "../components/cardholders/PendingCardHolders";
// import depositSlipImg from "../assets/images/deposit-slip.svg";
import RefreshToken from "./RefreshToken";
const SearchGridInputData = [
  {
    Name: "",
    TokenId: "",
    IssueDate: "",
    ExpiryDate: "",
    DateFrom: "",
    DateTo: "",
    startIndex: 1,
    scrolled: false,
    isFirstTime: true,
    SearchClearClicked: false,
    ClientId: "",
    CardNumber:"",
  },
];
const SearchGrid = (props) => {
  const [modalShow, setModalShow] = useState(false);
  const [searchData, setSearchData] = useState();
  const [searchGridSuccess, setSearchGridSuccess] = useState(false);
  const [name, setCompanyName] = useState("");
  const [tokenId, setTokentId] = useState("");
  const [dateIssued, setDateIssued] = useState();
  const [dateExpiry, setDateExpiry] = useState();
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const searchTbl = useRef();
  const [loadingData, setLoadingData] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [SearchInputDisabled, setSearchDisabled] = useState(false);
  const [clientIdVal, setClientIdVal] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [isShown, setIsShown] = useState(0);
  const [isShownCard, setIsShownCard] = useState(0);
  const nameChangeHandler = (e) => {
    SearchGridInputData[0].Name = e.target.value;
    setCompanyName(SearchGridInputData[0].Name);
  };
  const tokenChangeHandler = (e) => {
    SearchGridInputData[0].TokenId = e.target.value;
    if (validator.isNumeric(SearchGridInputData[0].TokenId))
      setTokentId(SearchGridInputData[0].TokenId);
    else setTokentId("");
  };
  const dateIssueHandler = (e) => {
    SearchGridInputData[0].IssueDate = e;
    if (e)
      SearchGridInputData[0].IssueDate = dateFilter(
        SearchGridInputData[0].IssueDate
      );
    setDateIssued(SearchGridInputData[0].IssueDate);
  };
  const dateExpiryHandler = (e) => {
    SearchGridInputData[0].ExpiryDate = e;
    if (e)
      SearchGridInputData[0].ExpiryDate = dateFilter(
        SearchGridInputData[0].ExpiryDate
      );
    setDateExpiry(SearchGridInputData[0].ExpiryDate);
  };
  const dateFromHandler = (e) => {
    SearchGridInputData[0].DateFrom = e;
    if (e)
      SearchGridInputData[0].DateFrom = dateFilter(
        SearchGridInputData[0].DateFrom
      );
    setDateFrom(SearchGridInputData[0].DateFrom);
  };
  const dateToHandler = (e) => {
    SearchGridInputData[0].DateTo = e;
    if (e)
      SearchGridInputData[0].DateTo = dateFilter(SearchGridInputData[0].DateTo);
    setDateTo(SearchGridInputData[0].DateTo);
  };
  const dateFilter = (date) => {
    return new Date(date);
  };
  const handleInputSearchClick = (e) => {
    if(e)
    e.preventDefault();
    setModalShow(true);
    setSearchDisabled(true);
  };
  const ClientNameChangeHandler = (e) => {
    const clientName = e.target.selectedOptions[0].value;
    setClientIdVal(clientName);
  };
  const SearchClickHandler = (e, isFromUseEffect) => {
    if(props?.clientId==="" ||props?.clientId===null)
      return;
    if (e) {
      e.preventDefault();
      SearchGridInputData[0].startIndex = 1;
    }
    if (isFromUseEffect){
     SearchGridInputData[0].startIndex = 1;
     handleInputSearchClick();
    }
    if (props.clientId || clientIdVal !== "")
      SearchGridInputData[0].ClientId = !props.clientId
        ? clientIdVal
        : props.clientId;
    else SearchGridInputData[0].ClientId = "";
    setLoadingData(true);
    HideSearchPopupDiv();
    if (!SearchGridInputData[0].scrolled) {
      setSearchGridSuccess(false);
    }
    commonService
      .searhGridCardNumber(
        name,
        tokenId,
        cardNumber,
        "",
        dateIssued || "0001-01-01",
        dateExpiry || "0001-01-01",
        dateFrom || "0001-01-01",
        dateTo || "0001-01-01",
        SearchGridInputData[0].startIndex,
        props.methodname,
        props.apiurl,
        SearchGridInputData[0].ClientId.toString(),
        props.screenname === "newcards" ? "Pending" : "true"
      )
      .then((resp) => {
        setLoadingData(false);
        setClientIdVal("");
        if (resp.data.success) {
          // if(isFromUseEffect)
          // setModalShow(true);
          // else
          setModalShow(false);
          setTotalCount(resp.data.payload.result.totalCount);
        }
        if (resp.data.payload.result.pageModel.length > 0 || searchData.length > 0) {
          setTotalCount(resp.data.payload.result.totalCount);
          if (SearchGridInputData[0].scrolled) {
            let appendedData = searchData.concat(resp.data.payload.result.pageModel);
            setSearchData(appendedData);
            props?.setDataset&& props?.setDataset(appendedData);
          } else {
            setSearchData(resp.data.payload.result.pageModel);
            props?.setDataset&&props.setDataset(resp.data.payload.result.pageModel);
            SearchGridInputData[0].startIndex = 1;
          }
          setSearchGridSuccess(true);

          SearchGridInputData[0].scrolled = false;
          searchTbl.current.onscroll = tblScrollHandler;
          SearchGridInputData[0].isFirstTime = false;
          // }
        } else {
          SearchGridInputData[0].startIndex = 1;
          setTotalCount(resp.data.payload.result.totalCount);
        }
      })
      .catch((error) => {
        setSearchGridSuccess(false);
        setLoadingData(false);
        (async () => {
          let resp = await RefreshToken(
            error && error.response && error.response.status
          );
          if (resp) {
            SearchClickHandler();
          }
        })();
      });
  };
  useEffect(() => {
    SearchClickHandler(null, true);
  }, []);
  const showCardHolderDetailPage = (id,userId) => {
    history.push("/cardholderdetails", {id,userId});
  };

  const tblScrollHandler = (e) => {
    //if (e.currentTarget.className === "") {
    if (
      !SearchGridInputData[0].scrolled &&
      SearchGridInputData[0].startIndex + 10 <= totalCount
    ) {
      SearchGridInputData[0].scrolled = true;
      SearchGridInputData[0].startIndex =
        SearchGridInputData[0].startIndex + 10;
      SearchClickHandler();
    }
    //  }
  };
  const ClearSearchData = () => {
    setCompanyName("");
    setTokentId("");
    setCardNumber("");
    ClearInputData();
  };
  const ClearInputData = () => {
    dateFromHandler("");
    dateToHandler("");
    dateExpiryHandler("");
    dateIssueHandler("");
    if (document.getElementById("clientNameDD") !== null)
      document.getElementById("clientNameDD").value = "";
    setClientIdVal("");
    setTimeout(() => {
      [...document.getElementsByClassName("r-input-group")].forEach(
        (element) => {
          element.children[0].value = "";
        }
      );
      document.getElementById("searchBtn").click();
    }, 500);
  };
  const renderTableHeader = () => {
    let header = Object.values(props.headersdata);
    return header.map((key, index) => {
      if (props?.screenname === "cardholder") {
        if(!props?.hascorporatepermission && key=="CVV")
        return
      }
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  };
  const renderTableData = () => {
    return searchData.map((data, index) => {
      if (
        props.screenname === "cardholder" //||
        //props.screenname === "newcards"
      ) {
        const {
          cardHolderUuid,
          cardHolderFirstName,
          cardHolderLastName,
          tokenId,
          cardNumber,
          companyName,
          expiryDate,
          issueDate,
          cardStatus,
          userId,
          unMaskedCardNumber,
          cvv
        } = data; //destructuring
        return (
          <tr key={cardHolderUuid}>
           <td style={{ textTransform: "capitalize" }}  title={cardHolderFirstName}>{cardHolderFirstName?.substr(0,10)??"-"}{cardHolderFirstName?.length>10 &&("...")}</td>
            <td style={{ textTransform: "capitalize" }}  title={cardHolderLastName}>{cardHolderLastName?.substr(0,10)??"-"}{cardHolderLastName?.length>10 &&("...")}</td>
            <td>{tokenId}</td>
            {
              props?.hascorporatepermission ?( <td onMouseLeave={()=> {setIsShownCard(cardHolderUuid)}} onMouseEnter={()=> {setIsShownCard(cardHolderUuid)}}>{
                isShownCard===cardHolderUuid ?(unMaskedCardNumber):("*****")
              }</td>):( <td>{cardNumber}</td>)
            }
            {
              props?.hascorporatepermission ?(
                <td onMouseLeave={()=> {setIsShown(cardHolderUuid)}} onMouseEnter={()=> {setIsShown(cardHolderUuid)}}>{
                  isShown===cardHolderUuid ?(cvv):("*****")
                }</td>
              ):("")
            }
              
            <td style={{ textTransform: "capitalize" }} title={companyName}>{companyName?.substr(0,20)??"-"}{companyName?.length>20 &&("...")}</td> 
            {
              props?.IssuerName !== "IssuerG" ?(
                <td>
                <DateFormat date={expiryDate} />
              </td>
              ):(<td></td>)
            }
           
            <td>
              <DateFormat date={issueDate} />
            </td>
            
            <td>{cardStatus}</td>
            <td>
              {/* {props.screenname === "cardholder" ? ( */}
              <span
                title="View Card Holder Details"
                className="icon-show"
                onClick={() => showCardHolderDetailPage(cardHolderUuid,userId)}
              ></span>
              {/* //) : (
              //   <span
              //     title="View Card Holder Details"
              //     className="icon-show"
              //     onClick={() => {
              //       setNewCardModalShow(true);
              //       setCardHolderUuId(cardHolderUuid);
              //     }}
              //   ></span>
              // )} */}
            </td>
          </tr>
        );
      }
      // else if (props.screenname === "deposit") {
      // }
      else if (props.screenname === "transactions") {
        const {
          id,
          amount,
          cardHolderName,
          currency,
          details,
          status,
          tokenId,
        } = data; //destructuring
        return (
          <tr key={id}>
            <td style={{ textTransform: "capitalize" }}>{cardHolderName}</td>
            <td>{tokenId}</td>
            <td>{amount}</td>
            <td>{currency}</td>
            <td>{status}</td>
            <td>{details}</td>
            <td>
              {/* <span
                title="View Transaction Details"
                className="icon-show"
                onClick={() => showDepositPopup(id)}
              ></span> */}
            </td>
          </tr>
        );
      } else {
        return <tr>No Data Found</tr>;
      }
    });
  };

  const HideSearchPopupDiv = () => {
    setModalShow(false);
    setSearchDisabled(false);
  };
  const cardNumberChangeHandler = (e) => {
    SearchGridInputData[0].CardNumber = e.target.value;
    setCardNumber(SearchGridInputData[0].CardNumber);
  };
  return (
    <Fragment>
      <SearchGridInputHelper
        placeholdervalue={props.placeholdervalue}
        handleInputSearchClick={handleInputSearchClick}
        searchinputdisabled={SearchInputDisabled}
        modalshow={modalShow}
        crossimg={crossImg}
        hidesearchpopupdiv={HideSearchPopupDiv}
        name={name}
        namechangehandler={nameChangeHandler}
        screenname={props.screenname}
        tokenid={tokenId}
        tokenchangehandler={tokenChangeHandler}
        dateissued={dateIssued}
        dateissuehandler={dateIssueHandler}
        dateexpiry={dateExpiry}
        dateexpiryhandler={dateExpiryHandler}
        datefrom={dateFrom}
        datefromhandler={dateFromHandler}
        dateto={dateTo}
        datetohandler={dateToHandler}
        clearsearchdata={ClearSearchData}
        searchclickhandler={SearchClickHandler}
        clientnamechangehandler={ClientNameChangeHandler}
        cardNumber={cardNumber}
        cardchangehandler={cardNumberChangeHandler}
      />
      {props.screenname !== "deposit" && props.screenname !== "newcards" && (
        <SearchGridTableHelper
          searchgridsuccess={searchGridSuccess}
          searchtbl={searchTbl}
          tblscrollhandler={tblScrollHandler}
          rendertableheader={renderTableHeader}
          renderTableData={renderTableData}
          loadingdata={loadingData}
          totalcount={totalCount}
          screenname={props.screenname}
        />
      )}
     
      {props.screenname === "newcards" && (
        <PendingCardHolders
          //  onshow={newcardmodalShow}
          //  onhide={() => setNewCardModalShow(false)}
          //  uuid={cardHolderUuId}
          searchdata={searchData}
          searchclickhandler={SearchClickHandler}
          searchgridsuccess={searchGridSuccess}
          searchtbl={searchTbl}
          tblscrollhandler={tblScrollHandler}
          rendertableheader={renderTableHeader}
          renderTableData={renderTableData}
          loadingdata={loadingData}
          totalcount={totalCount}
        />
      )}
    </Fragment>
  );
};

export default SearchGrid;
