

import DialoguePopup from "../../helpers/DialoguePopup";
import React, { useState, useRef, useEffect, Fragment } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import cardsService from "../../services/cards.services";
import clientmanagmentObj from "../../services/client-managment.service";
import BoLoader from "../../helpers/BoLoader";
import moment from "moment";
import DateFormat from "../../helpers/DateFormat";
import { subYears } from "date-fns";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ReactDatePickerHelper from "../../helpers/ReactDatePickerHelper";
import { useSelector } from "react-redux";
import GetAllClients from "../../helpers/GetAllClients";
import RefreshToken from "../../helpers/RefreshToken";
import ReactFlagsSelect from "react-flags-select";
import { TrimCharacters } from "../../helpers/TrimCharacters";

const firstNameRequired = value => {
  if (!value) {
    document.getElementById("id_firstname").classList.add("has_error");
    return <span className="help-block">First Name is required!</span>;
  } else document.getElementById("id_firstname").classList.remove("has_error");
};
const firstNameLength = value => {
  if (value.length < 2) {
    document.getElementById("id_firstname").classList.add("has_error");
    return <span className="help-block">First Name must contain 2 characters!</span>;
  } else document.getElementById("id_firstname").classList.remove("has_error");
};
const lastNameRequired = value => {
  if (!value) {
    document.getElementById("id_lastname").classList.add("has_error");
    return <span className="help-block">Last Name is required!</span>;
  } else document.getElementById("id_lastname").classList.remove("has_error");
};
const lastNameLength = value => {
  if (value.length < 2) {
    document.getElementById("id_lastname").classList.add("has_error");
    return <span className="help-block">Last Name must contain 2 characters!</span>;
  } else document.getElementById("id_lastname").classList.remove("has_error");
};
const prefferedNameRequired = value => {
  if (!value) {
    document.getElementById("id_preferredname").classList.add("has_error");
    return <span className="help-block">Preferred Name is required!</span>;
  } else
    document.getElementById("id_preferredname").classList.remove("has_error");
};
const prefferedNameLength = value => {
  if (value.length < 2) {
    document.getElementById("id_preferredname").classList.add("has_error");
    return <span className="help-block">Preferred Name must contain 2 characters!</span>;
  } else
    document.getElementById("id_preferredname").classList.remove("has_error");
};
const AccountTitleRequired = value => {
  if (!value) {
    document.getElementById("id_accounttitle").classList.add("has_error");
    return <span className="help-block">Account Name is required!</span>;
  } else
    document.getElementById("id_accounttitle").classList.remove("has_error");
};
const AccountNameLength = value => {
  if (value.length < 2) {
    document.getElementById("id_accounttitle").classList.add("has_error");
    return <span className="help-block">Account Name must contain 2 characters!</span>;
  } else
    document.getElementById("id_accounttitle").classList.remove("has_error");
};
const deliveryAddressRequired = value => {
  if (!value) {
    document.getElementById("deliveryaddress").classList.add("has_error");
    return <span className="help-block"> Address is required!</span>;
  } else
    document.getElementById("deliveryaddress").classList.remove("has_error");
};
const deliveryAddresslength = value => {
  if (value.length < 8) {
    document.getElementById("deliveryaddress").classList.add("has_error");
    return <span className="help-block"> Address must contain 8 characters!</span>;
  } else
    document.getElementById("deliveryaddress").classList.remove("has_error");
};

const deliveryCityRequired = value => {
  if (!value) {
    document.getElementById("id_deliverycity").classList.add("has_error");
    return <span className="help-block"> City is required!</span>;
  } else
    document.getElementById("id_deliverycity").classList.remove("has_error");
};
const deliveryCityLength = value => {
  if (value.length < 3) {
    document.getElementById("id_deliverycity").classList.add("has_error");
    return <span className="help-block"> City must contain 3 characters!</span>;
  } else
    document.getElementById("id_deliverycity").classList.remove("has_error");
};
const deliveryZipCodeRequired = value => {
  if (!value) {
    document.getElementById("id_deliveryzipcode").classList.add("has_error");
    return <span className="help-block"> Postal Code is required!</span>;
  } else
    document.getElementById("id_deliveryzipcode").classList.remove("has_error");
};
const deliveryZipCodeLength = value => {
  if (value.length < 3) {
    document.getElementById("id_deliveryzipcode").classList.add("has_error");
    return <span className="help-block"> Postal Code must contain 3 characters!</span>;
  } else
    document.getElementById("id_deliveryzipcode").classList.remove("has_error");
};


const Emailrequired = value => {
  if (!value) {
    document.getElementById("Email-frmgrp").classList.add("has_error");
    return <span className="help-block">Email is required!</span>;
  } else document.getElementById("Email-frmgrp").classList.remove("has_error");
};

const phonerequired = value => {
  if (!value) {
    document.getElementById("phone-frmgrp").classList.add("has_error");
    return <span className="help-block">Phone Number is required!</span>;
  } else document.getElementById("phone-frmgrp").classList.remove("has_error");
};
const validEmail = value => {
  if (!isEmail(value)) {
    document.getElementById("Email-frmgrp").classList.add("has_error");
    return <span className="help-block">Invalid Email!</span>;
  } else document.getElementById("Email-frmgrp").classList.remove("has_error");
};

const AddIbanAccountsBody = props => {
  const { user: currentUser } = useSelector(state => state.auth);

  const [cardHolderDetailsAreaSelected, setCardHolderDetailsAreaSelected] =
    useState("true");
  const [cardDetailsAreaSelected, setCardDetailsAreaSelected] =
    useState("false");
  const [cardHolderDetailsClassName, setTcardHolderDetailsClassName] =
    useState("nav-link active");
  const [cardDetailsClassName, setTcardDetailsClassName] = useState("nav-link");
  const [cardHolderContentClassName, setCardHolderContentClassName] = useState(
    "tab-pane fade show active"
  );
  const [cardContentClassName, setCardContentClassName] =
    useState("tab-pane fade");
  const [modalShow, setModalShow] = React.useState(false);
  const form = useRef();
  const checkBtn = useRef();
  const [username, setUsername] = useState("");
 
  const [phoneNumber, setPhoneNumber] = useState("");
  const [dob, setDob] = useState();
  const [modelhdng, setMdlHdng] = useState("");
  const [MdlIcon, setMdlIcon] = useState("");
  const [modelbdy, setMdlBdy] = useState("");
  const [DeliveryMode, setDeliveryMode] = useState('');
  const [disableBtns, setDisabledBtns] = useState(false);
  const [showDOBErr, setShowDOBErr] = useState("d-none");
  const [showPhoneErr, setshowPhoneErr] = useState("d-none");
  const [boloader, setBoLoader] = useState(false);
  const [ClientNameVal, setClientNameVal] = useState("");
  const [isValidPhoneNumber, setisValidPhoneNumber] = useState(true);
  
  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [PreferredName, setPreferredName] = useState("");
  const [AccountTitle, setAccountTitle] = useState("");
  const [Gender, setGender] = useState("Male");
  const [DateOfBirth, setDateOfBirth] = useState();
  const [Email, setEmail] = useState("");
  const [Mobile, setMobile] = useState("");
  const [Nationality, setNationality] = useState("");
  const [DeliveryCountry, setDeliveryCountry] = useState("");
  const [BillingCountry, setBillingCountry] = useState("");

  const [CountryCode, setCountryCode] = useState("");
  const [PaymentProcessorClient, setPaymentProcessorClient] = useState("");
  const [DeliveryAddress1, setDeliveryAddress1] = useState("");
  const [DeliveryCity, setDeliveryCity] = useState("");
  const [DeliveryZipCode, setDeliveryZipCode] = useState("");
  const [BillingAddress1, setBillingAddress1] = useState("");
  const [BillingCity, setBillingCity] = useState("");
  const [BillingZipCode, setBillingZipCode] = useState("");
  const [cardFeeCurrencyCode, setcardFeeCurrencyCode] = useState();
  const [cardCurrency, setcardCurrency] = useState();
  const [cardType, setcardType] = useState("GPR_VIR");
  const [cardIssuanceAction, setcardIssuanceAction] = useState("NEW");
  const [showNationalityErr, setshowNationalityErr] = useState("d-none");
  const [showDeliveryCountryErr, setshowDeliveryCountryErr] =
    useState("d-none");
  const [showDeliveryModeErr, setshowDeliveryModeErr] =
    useState("d-none");
  const [showClientErr, setShowClientErr] = useState("d-none");
  //Issuer-B Spending Limits
  const [SpendingLimits, setSpendingLimits] = useState();
  //Issuer S or ISsuer B For m-Swipe
  const [IssuerCategory, setIssuerCategory] = useState();
  const [CardFlow, setCardFlow] = useState();
  const [DeliveryState, setDeliveryState] = useState("");
  const [CardColor, setCardColor] = useState("Green");
  const [showCardColorErr, setShowCardColorErr] =
    useState("d-none");
  const [CardVisaMaster, setCardVisaMaster] = useState("");
  const [CheckcardVisaMaster, setCheckCardVisaMaster] = useState("Select");
  const [showCardCategoryErr, setshowCardCategoryErr] = useState("d-none");
  //Validations Dropdown Starts
  const requiredDob = value => {
    if (!value) {
      document.getElementById("dob-frmgrp").classList.add("has_error");
      setShowDOBErr("");
      return false;
    } else {
      document.getElementById("dob-frmgrp").classList.remove("has_error");
      setShowDOBErr("d-none");
      return true;
    }
  };
  const requiredPhoneNo = value => {
    if (phoneNumber.length < 3) setisValidPhoneNumber(false);
    else setisValidPhoneNumber(true);
    if (!value) {
      document.getElementById("phone-frmgrp").classList.add("has_error");
      setshowPhoneErr("");
      return false;
    } else {
      document.getElementById("phone-frmgrp").classList.remove("has_error");
      setshowPhoneErr("d-none");
      return true;
    }
  };
  const requiredNationality = value => {
    if (!value) {
      document.getElementById("id_nationality").classList.add("has_error");
      setshowNationalityErr("");
      return false;
    } else {
      document.getElementById("id_nationality").classList.remove("has_error");
      setshowNationalityErr("d-none");
      return true;
    }
  };
  const requiredDeliveryCountry = value => {
    if (!value) {
      document.getElementById("id_deliverycountry").classList.add("has_error");
      setshowDeliveryCountryErr("");
      return false;
    } else {
      document
        .getElementById("id_deliverycountry")
        .classList.remove("has_error");
      setshowDeliveryCountryErr("d-none");
      return true;
    }
  };


  //Validations Dropdown End
  const onChangeEmail = e => {
    const email = e.target.value;
    setEmail(email);
    setUsername(email);
  };
  const onChangeDOB = value => {
    let DOB = value;
    if (DOB) DOB = new Date(DOB);
    setDob(DOB);
    setDateOfBirth(DOB);
    requiredDob(value);
  };
  const onChangePhoneNumber = e => {
    requiredPhoneNo(e);
    setPhoneNumber(e);
    setMobile(e);
  };

  //UseEffects Start
  useEffect(() => {
    if (phoneNumber.length === 0) setisValidPhoneNumber(true);
    else if (phoneNumber.length < 3) setisValidPhoneNumber(false);
    else setisValidPhoneNumber(true);
  }, [phoneNumber]);
  useEffect(() => {
    setCountryCode(Nationality);
  }, [Nationality]);

  useEffect(() => {
    let dateOfBirth = moment(DateOfBirth).format("YYYY-MM-DD");
    setDateOfBirth(dateOfBirth);
  }, [dob]);
  //UseEffects End
  //API Call Start
  const IssueCardHolderHandler = e => {
    if (e) e.preventDefault();
    form.current.validateAll();
    let dobValid = requiredDob(dob);
    let phoneValid = requiredPhoneNo(phoneNumber);
    // let nationality = requiredNationality(Nationality);
    // let deliverycountry = requiredDeliveryCountry(DeliveryCountry);
  
    // let checkcardcategory = requiredCardCategory(CardVisaMaster);


      if (
        checkBtn.current.context._errors.length === 0 &&
        dobValid &&
        phoneValid 
        // nationality &&
        // deliverycountry &&
        //  checkcardcategory
      ) {
        setBoLoader(true);
        cardsService
          .createiban(
            FirstName,
            FirstName,
            PreferredName,
            CountryCode,
            CountryCode,
            "M",
            Mobile,
            DateOfBirth,
            DeliveryAddress1,
            DeliveryCity,
            DeliveryZipCode,
            DeliveryCountry,
            Email,
            AccountTitle,
            "GBP",
            Number(ClientNameVal) || Number(currentUser.user.ClientId),
          )
          .then(cardData => {
            setBoLoader(false);
            if (cardData && cardData.data && cardData.data.success) {
              setCardHolderDetailsAreaSelected("false");
              setCardDetailsAreaSelected("true");
              setTcardHolderDetailsClassName("nav-link");
              setTcardDetailsClassName("nav-link active");
              setCardHolderContentClassName("tab-pane fade");
              setCardContentClassName("tab-pane fade show active");
            }
          })
          .catch(err => {
            setBoLoader(false);
            let msgBody =
              err && err.response && err.response.data
                ? err.response.data.message
                : "Error Occured While Creating Account";
            let errIcon = "icon-error";
            errorAndSuccessModl("Error", msgBody, errIcon);
            (async () => {
              setBoLoader(true);
              let resp = await RefreshToken(
                err && err.response && err.response.status
              );
              if (resp) {
                IssueCardHolderHandler();
                setBoLoader(false);
              } else {
                setBoLoader(false);
              }
            })();
          });
      }
    
  };
  //API Call End
  const errorAndSuccessModl = (heading, body, Icon) => {
    setModalShow(true);
    setMdlHdng(heading);
    setMdlBdy(body);
    setMdlIcon(Icon);
  };
  /////Note Please do not remove this code as this is used for back button, currently commented but will be uncommented in future/////
  // const goBackToCardHolderHandler = (e) => {
  //   setCardHolderDetailsAreaSelected("true");
  //   setCardDetailsAreaSelected("false");
  //   setTcardHolderDetailsClassName("nav-link active");
  //   setTcardDetailsClassName("nav-link");
  //   setCardHolderContentClassName("tab-pane fade show active");
  //   setCardContentClassName("tab-pane fade");
  // };
  const IssueCardDoneHandler = e => {
    e.preventDefault();
    // setDisabledBtns(true);
    props.onHide();
  };
  const ClientNameChangeHandler = async e => {
    const clientName = e.target.selectedOptions[0].value;
    await clientmanagmentObj
      .getprofiledetails(String(clientName))
      .then((response) => {
        setPaymentProcessorClient(response?.data?.payload?.result?.paymentProcesor)
      })
    const paymentProcessor =
      e?.target?.selectedOptions[0]?.attributes[1]?.nodeValue ?? "EUR";
    const currency = e?.target?.selectedOptions[0]?.attributes[0]?.nodeValue ?? "-";
    requiredClient(clientName);
    const issuercat = e?.target?.selectedOptions[0]?.attributes[4]?.nodeValue;
    const cardflow = e?.target?.selectedOptions[0]?.attributes[3]?.nodeValue;
    const checkVisaMaster = e?.target?.selectedOptions[0]?.attributes[5]?.nodeValue;

    setClientNameVal(clientName);
    setcardFeeCurrencyCode(currency);
    setcardCurrency(currency);
    setIssuerCategory(issuercat);
    setCardFlow(cardflow);
    setCheckCardVisaMaster(checkVisaMaster);
  };

  const requiredClient = value => {
    if (!value) {
      document.getElementById("id_client").classList.add("has_error");
      setShowClientErr("");
      return false;
    } else {
      document.getElementById("id_client").classList.remove("has_error");
      setShowClientErr("d-none");
      return true;
    }
  };
  const requiredCardCategory = value => {
    if (CheckcardVisaMaster === "Both") {
      if (value === "Select") {
        document.getElementById("id_cardType").classList.add("has_error");
        setshowCardCategoryErr("");
        return false;
      } else {
        document.getElementById("id_cardType").classList.remove("has_error");
        setshowCardCategoryErr("d-none");
        return true;
      }
    }
    else {
      return true
    }

  };


  const onChangeFirstName = e => {
    let value = e.target.value;
    value = value.replace(/[^a-z0-9 ]/gi, '')
    setFirstName(value);
  };
  const onChangeLastName = e => {
    let value = e.target.value;
    value = value.replace(/[^a-z0-9 ]/gi, '')
    setLastName(value);
  };
  const onChangePreferredName = e => {
    let value = e.target.value;
    value = value.replace(/[^a-z0-9 ]/gi, '')
    setPreferredName(value);
  };
  const onChangeAccountTitle = e => {
    let value = e.target.value;
    value = value.replace(/[^a-z0-9 ]/gi, '')
    setAccountTitle(value);
  };
  const onChangeGender = e => {
    const name = e.target.value;
    setGender(name);
  };
  const onChangeDeliveryAddress = e => {
    const name = e.target.value;
    setDeliveryAddress1(name);
    setBillingAddress1(name);
  };
  const onChangeDeliveryCity = e => {
    let value = e.target.value;
    value = value.replace(/[^A-Za-z ]/ig, '')
    setDeliveryCity(value);
    setBillingCity(value);
  };
  const onChangeDeliveryZipCode = e => {
    let value = e.target.value;
    value = value.replace(/[^a-z0-9 ]/gi, '')
    setDeliveryZipCode(value);
    setBillingZipCode(value);
  };

 
  const onChangeDeliveryMode = e => {
    const name = e.target.value;
    setDeliveryMode(name);
  };

  const onChangeSpendingLimit = e => {
    let limit = e.target.value;
    if (limit !== "") {
      let value = parseFloat(limit);
      setSpendingLimits(value);
    }

  };
  const onChangeCardColor = e => {
    const name = e.target.value;
    setCardColor(name);
  };
  const onChangeCardVisaMaster = (e) => {
    const name = e?.target?.selectedOptions[0]?.value;
    setCardVisaMaster(name);
    requiredCardCategory(name);
  }
  return (
    <Fragment>
      <div className="row">
        <BoLoader loading={boloader} />
        <div className="col-md-12">
          <h2>Create Account </h2>
          <div className="mt-16">
            <div className="row">
              <div className="col-md-12">
                <div className="bo-tabs-mbl">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button
                        className={cardHolderDetailsClassName} //""
                        id="cardHolder-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#cardHolder"
                        type="button"
                        role="tab"
                        aria-controls="cardHolder"
                        aria-selected={cardHolderDetailsAreaSelected}
                      >
                        AccountHolder Details
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className={cardDetailsClassName}
                        id="cardDetails-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#cardDetails"
                        type="button"
                        role="tab"
                        aria-controls="cardDetails"
                        aria-selected={cardDetailsAreaSelected}
                      >
                        Account Details
                      </button>
                    </li>
                  </ul>
                </div>

                <div className="tab-content" id="myTabContent">
                  <div
                    className={cardHolderContentClassName}
                    id="cardHolder"
                    role="tabpanel"
                    aria-labelledby="cardHolder-tab"
                  >
                    <div>
                      <h3>Enter Account details</h3>
                      <Form onSubmit={(e) => { e.preventDefault() }} ref={form}>
                        <div className="row mt-16">
                          <div className="col-md-6" id="id_client">
                            <div className="form-group">
                              {/* <label>Select Client </label> */}
                              {/* <div className="form-controls">
                                <GetAllClients
                                  clientnamechangehandler={
                                    ClientNameChangeHandler
                                  }
                                  searchgriddata="Y"
                                />
                                <span
                                  className={
                                    "help-block " + showClientErr
                                  }
                                >
                                  Client is required!
                                </span>
                              </div> */}
                            </div>
                          </div>
                          <div className="col-md-6"></div>
                          <div className="col-md-6">
                            <div className="form-group" id="id_firstname">
                              <label>Company Name*</label>
                              <div className="form-controls">
                                <Input
                                  type="text"
                                  className="form-control"
                                  name="cardholderName"
                                  value={FirstName}
                                  onChange={onChangeFirstName}
                                  onKeyDown={(evt) => {
                                    let chck = TrimCharacters.checkSpecialCharacters(evt.key)
                                    if (chck)
                                      evt.preventDefault();
                                  }
                                  }
                                  validations={[firstNameRequired, firstNameLength]}
                                  maxLength="20"
                                />
                                <span className="icon-user _ico"></span>
                              </div>
                            </div>
                          </div>
                          {/* <div className="col-md-6">
                            <div className="form-group" id="id_lastname">
                              <label>Account Title*</label>
                              <div className="form-controls">
                                <Input
                                  type="text"
                                  className="form-control"
                                  name="LastName"
                                  value={LastName}
                                  onChange={onChangeLastName}
                                  onKeyDown={(evt) => {
                                    let chck = TrimCharacters.checkSpecialCharacters(evt.key)
                                    if (chck)
                                      evt.preventDefault();
                                  }
                                  }
                                  validations={[lastNameRequired, lastNameLength]}
                                  maxLength="20"
                                />
                                <span className="icon-user _ico"></span>
                              </div>
                            </div>
                          </div> */}
                          <div className="col-md-6">
                            <div className="form-group" id="id_preferredname">
                              <label>Point Of Contact*</label>
                              <div className="form-controls">
                                <Input
                                  type="text"
                                  className="form-control"
                                  name="PreferredName"
                                  value={PreferredName}
                                  onChange={onChangePreferredName}
                                  onKeyDown={(evt) => {
                                    let chck = TrimCharacters.checkSpecialCharacters(evt.key)
                                    if (chck)
                                      evt.preventDefault();
                                  }
                                  }
                                  validations={[AccountTitleRequired, AccountNameLength]}
                                  maxLength="20"
                                />
                                <span className="icon-user _ico"></span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group" id="id_accounttitle">
                              <label>Account title*</label>
                              <div className="form-controls">
                                <Input
                                  type="text"
                                  className="form-control"
                                  name="AccountTitle"
                                  value={AccountTitle}
                                  onChange={onChangeAccountTitle}
                                  onKeyDown={(evt) => {
                                    let chck = TrimCharacters.checkSpecialCharacters(evt.key)
                                    if (chck)
                                      evt.preventDefault();
                                  }
                                  }
                                  validations={[prefferedNameRequired, prefferedNameLength]}
                                  maxLength="20"
                                />
                                <span className="icon-user _ico"></span>
                              </div>
                            </div>
                          </div>
                          {/* <div className="col-md-6">
                            <div className="form-group">
                              <label>Gender*</label>
                              <div className="form-controls">
                                <select
                                  className="form-control no-icon"
                                  onChange={onChangeGender}
                                >
                                  <option value="Male">Male</option>
                                  <option value="Female">Female</option>
                                </select>
                              </div>
                            </div>
                          </div> */}
                          <div className="col-md-6">
                            <div className="form-group mb-3" id="dob-frmgrp">
                              <label>Date Of Registration* </label>
                              <div className="form-controls">
                                <ReactDatePickerHelper
                                  datevalue={dob}
                                  onchangedate={onChangeDOB}
                                  // maximumdate={subYears(new Date(), 18)}
                                />
                                <span className="icon-calender _ico"></span>
                                <span className="icon-round icon-calender-mini"></span>
                                {/* <span className="fs-12 clr-submarine mt-2 d-block">
                                  Age should be greater than 18 years
                                </span> */}
                                {/* <span className="icon-round icon-calender-mini"></span> */}
                                <span className={"help-block " + showDOBErr}>
                                  DOR is required!
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group" id="Email-frmgrp">
                              <label>Contact Email*</label>
                              <div className="form-controls">
                                <Input
                                  type="text"
                                  className="form-control"
                                  name="email"
                                  value={Email}
                                  onChange={onChangeEmail}
                                  validations={[Emailrequired, validEmail]}
                                  maxLength="50"
                                />
                                <span className="icon-email _ico"></span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group" id="phone-frmgrp">
                              <label>Contact Phone Number*</label>
                              <div className="form-controls">
                                <PhoneInput
                                  class="form-control "
                                  country={"gb"}
                                  onChange={onChangePhoneNumber}
                                  value={phoneNumber}
                                  validations={phonerequired}
                                />
                                <span className={"help-block " + showPhoneErr}>
                                  Phone No is required!
                                </span>
                                {!isValidPhoneNumber && (
                                  <span className="help-block has_error">
                                    Minimum three number are required!
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                          {/* <div className="col-md-6">
                            <div className="form-group" id="id_nationality">
                              <label>Nationality*</label>
                              <div className="form-controls">
                                <ReactFlagsSelect

                                  className="form-control no-icon"
                                  selected={Nationality}
                                  onSelect={code => {
                                    setNationality(code);
                                    requiredNationality(code);
                                  }}
                                  searchable={true}
                                />
                                <span
                                  className={"help-block " + showNationalityErr}
                                >
                                  Nationality is required!
                                </span>
                              </div>
                            </div>
                          </div> */}

                          <div className="col-md-6" id="deliveryaddress">
                            <div className="form-group" >
                              <label>Company Address*</label>
                              <div className="form-controls">
                                <Input
                                  type="text"
                                  className="form-control"
                                  name="deliveryaddress"
                                  value={DeliveryAddress1}
                                  onChange={onChangeDeliveryAddress}
                                  validations={[deliveryAddressRequired, deliveryAddresslength]}
                                  maxLength="40"
                                />
                                <span className="icon-home _ico"></span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group" id="id_deliverycountry">
                              <label>Company Country*</label>
                              <div className="form-controls">
                                <ReactFlagsSelect
                                  className="form-control no-icon"
                                  selected={DeliveryCountry}
                                  onSelect={code => {
                                    setDeliveryCountry(code);
                                    setBillingCountry(code);
                                    setDeliveryState(code);
                                    requiredDeliveryCountry(code);
                                  }}
                                  searchable={true}
                                />
                                <span
                                  className={
                                    "help-block " + showDeliveryCountryErr
                                  }
                                >
                                  Delivery Country is required!
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group" id="id_deliverycity">
                              <label>Company City*</label>
                              <div className="form-controls">
                                <Input
                                  type="text"
                                  className="form-control no-icon"
                                  name="address"
                                  value={DeliveryCity}
                                  onChange={onChangeDeliveryCity}
                                  onKeyDown={(evt) => {
                                    let chck = TrimCharacters.checkAlpha(evt.key)
                                    if (chck)
                                      evt.preventDefault();
                                  }
                                  }
                                  validations={[deliveryCityRequired, deliveryCityLength]}
                                  maxLength="20"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group" id="id_deliveryzipcode">
                              <label>Company Postal Code*</label>
                              <div className="form-controls">
                                <Input
                                  type="text"
                                  className="form-control no-icon"
                                  name="address"
                                  value={DeliveryZipCode}
                                  onChange={onChangeDeliveryZipCode}
                                  onKeyDown={(evt) => {
                                    let chck = TrimCharacters.checkSpecialCharacters(evt.key)
                                    if (chck)
                                      evt.preventDefault();
                                  }
                                  }
                                  validations={[deliveryZipCodeRequired, deliveryZipCodeLength]}
                                  maxLength="10"
                                />
                              </div>
                            </div>
                          </div>
                          {
                            (IssuerCategory === "IssuerS" || IssuerCategory === "IssuerB") && CheckcardVisaMaster === "Both" && cardCurrency === "USD" ? (
                              <div className="col-md-6" id="id_cardType">
                                <div className="form-group">
                                  <label>Network*</label>
                                  <div className="form-controls">
                                    <select
                                      onChange={onChangeCardVisaMaster}
                                      className="form-control no-icon"
                                    >
                                      <option select="selected" value={"Select"}>
                                        Select
                                      </option>
                                      <option select="selected" value={"Visa"}>
                                        Visa
                                      </option>
                                      <option select="selected" value={"Master"}>
                                        MC
                                      </option>
                                    </select>
                                    <span
                                      className={
                                        "help-block " + showCardCategoryErr
                                      }
                                    >
                                      Card Category Is Required!
                                    </span>
                                  </div>

                                </div>
                              </div>
                            )
                              : (IssuerCategory === "IssuerS" || IssuerCategory === "IssuerB") && cardCurrency === "USD" ? (
                                <div className="col-md-6" id="id_cardType">
                                  <div className="form-group">
                                    <label>Card Category*</label>
                                    <div className="form-controls"></div>
                                    <input
                                      type="text"
                                      value={CheckcardVisaMaster}
                                      disabled="disabled"
                                      className="form-controls"
                                    />
                                  </div>
                                </div>
                              ) : (<div id="id_cardType"></div>)
                          }

                          {IssuerCategory === "IssuerG" ? (
                            <Fragment>
                              <div className="col-md-6" id="cardcolor">
                                <div className="form-group">
                                  <label>Card Color*</label>
                                  <div className="form-controls">
                                    <select
                                      onChange={onChangeCardColor}
                                      className="form-control no-icon"
                                      id="delivery_mode"

                                    >
                                      <option value="">Select Card Color</option>
                                      <option select="selected" value='Green'>
                                        Green
                                      </option>
                                      <option value='Silver'>
                                        Black
                                      </option>

                                    </select>
                                    <span
                                      className={
                                        "help-block " + showCardColorErr
                                      }
                                    >
                                      Card Color is required!
                                    </span>

                                  </div>
                                </div>
                              </div>
                            </Fragment>
                          ) : (<Fragment><div id="cardcolor"></div></Fragment>)}
                          {PaymentProcessorClient === 'Nium' && cardType === 'GPR_PHY' && <div className="col-md-6">
                            <div className="form-group">
                              <label>Delivery Process*</label>
                              <div className="form-controls">
                                <select
                                  onChange={onChangeDeliveryMode}
                                  className="form-control no-icon"
                                  id="delivery_mode"

                                >
                                  <option value="">Select Delivery Mode</option>
                                  <option select="selected" value='NORMAL_DELIVERY_LOCAL'>
                                    Normal Delivery
                                  </option>
                                  <option value='EXPRESS_DELIVERY_LOCAL'>
                                    Express Delivery
                                  </option>
                                  <option value='INTERNATIONAL_DELIVERY'>
                                    International Delivery
                                  </option>

                                </select>
                                <span
                                  className={
                                    "help-block " + showDeliveryModeErr
                                  }
                                >
                                  Delivery Process is required!
                                </span>

                              </div>
                            </div>
                          </div>}
                          {
                            (CardFlow === "CREDIT" && IssuerCategory === "IssuerB") && (
                              <div className="col-md-6" id="billingzipcode">
                                <div className="form-group" >
                                  <label>Spending Limit</label>
                                  <div className="form-controls">
                                    <Input
                                      type="number"
                                      className="form-control no-icon"
                                      name="spendinglimit"
                                      value={SpendingLimits}
                                      onChange={onChangeSpendingLimit}
                                      maxLength="10"
                                    />
                                  </div>
                                </div>
                              </div>
                            )
                          }
                          <div className="col-md-12">
                            <div className="form-group submit-field d-flex justify-content-end mt-20 mb-0">
                              <a
                                className="btn btn-outline mr-19"
                                data-bs-dismiss="modal"
                                onClick={props.onHide}
                              >
                                Cancel
                              </a>
                              <a className="btn btn-large" onClick={IssueCardHolderHandler}>
                                Create Account
                              </a>
                            </div>
                          </div>
                        </div>
                        <CheckButton
                          style={{ display: "none" }}
                          ref={checkBtn}
                        />
                      </Form>
                    </div>
                  </div>
                  <div
                    className={cardContentClassName}
                    id="cardDetails"
                    role="tabpanel"
                    aria-labelledby="cardDetails-tab"
                  >
                    <div className="row row-card-details-popup">
                      <div className="col-md-12">
                        <ul className="ul-card-details-popup ul-issue-card">
                          <li className="mb-20">
                            <div className="row">
                              <div className="col-4">
                                <label>Company Name</label>
                              </div>
                              <div className="col-8">
                                <span>{FirstName + "   " + LastName}</span>
                              </div>
                            </div>
                          </li>
                          <li className="mb-20">
                            <div className="row">
                              <div className="col-4">
                                <label>Email</label>
                              </div>
                              <div className="col-8">
                                <span>{Email}</span>
                              </div>
                            </div>
                          </li>
                          <li className="mb-20">
                            <div className="row">
                              <div className="col-4">
                                <label>Phone Number</label>
                              </div>
                              <div className="col-8">
                                <span>{phoneNumber}</span>
                              </div>
                            </div>
                          </li>
                          <li className="mb-20">
                            <div className="row">
                              <div className="col-4">
                                <label>Date of Birth</label>
                              </div>
                              <div className="col-8">
                                <span>
                                  <DateFormat date={dob} />
                                </span>
                              </div>
                            </div>
                          </li>
                          <li className="mb-20">
                            <div className="row">
                              <div className="col-4">
                                <label>Available Balance</label>
                              </div>
                              <div className="col-8">
                                <span>0</span>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group submit-field d-flex justify-content-end mt-20 mb-0">
                          <button
                            className="btn btn-large"
                            data-bs-dismiss="modal"
                            onClick={IssueCardDoneHandler}
                            disabled={disableBtns}
                          >
                            Done
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DialoguePopup
        show={modalShow}
        onHide={() => setModalShow(false)}
        modelheading={modelhdng}
        modelbody={modelbdy}
        modelicon={MdlIcon}
        hideallpopups={props.onHide}
      />
    </Fragment>
  );
};
export default AddIbanAccountsBody;
