import React, { useState, useRef, Fragment, useEffect } from "react";
import DateFormat from "../../helpers/DateFormat";
import crossImgs from "../../assets/images/icons/cross.svg";
import RefreshToken from "../../helpers/RefreshToken";
import ReactDatePickerHelper from "../../helpers/ReactDatePickerHelper";
import emptyclient from "../../assets/images/icons/clients-empty.svg";
import transactionService from "../../services/transaction.service";
import { useSelector } from "react-redux";
import moment from "moment";
import ModelPopup from "../../helpers/ModelPopup";
import commonService from "../../services/common.service";
import cardsService from "../../services/cards.services";
import cardHolderService from "../../services/cardholder.service";
import { Link } from "react-router-dom";
import BoLoader from "../../helpers/BoLoader";
import RegisterGlobal from "../issuerGlobal/RegisterGlobal";

const SearchGridInputData = [
  {
    startIndex: 1,
    Name: "",
    userId: "",
    StartDate: "",
    EndDate: "",
    Size: 10,
    scrolled: false,
    isFirstTime: true,
    ClientId: "",
    Status: "false",
  },
];
var fontStyle = {
  textTransform: "uppercase",
};

const SearchGridPendingCards = (props) => {
  const [modalShow, setModalShow] = useState(false);
  const [name, setCompanyName] = useState("");
  const [StartDate, setDateFrom] = useState("");
  const [EndDate, setDateTo] = useState("");
  const searchTbl = useRef();
  const [loadingData, setLoadingData] = useState(false);
  const [searchData, setSearchData] = useState();
  const [searchGridSuccess, setSearchGridSuccess] = useState(false);
  const [SearchInputDisabled, setSearchDisabled] = useState(false);
  const { user: currentUser } = useSelector((state) => state.auth);
  const [ClientId, setClientId] = useState(String(currentUser.user.ClientId));
  const [userId, setuserId] = useState();
  const [totalCount, setTotalCount] = useState(0);
  //Transaction Models Showing Values
  const [TransactionsType, setTransactionsType] = useState("");
  const [status, setStatus] = useState("");
  const [CurrencyCode, setCurrencyCode] = useState("");
  const [Amount, setAmount] = useState("");
  const [Date, setDate] = useState("");
  const [ReferenceNumber, setReferenceNumber] = useState("");
  //Model popup Transactions
  const [modalShowTransactions, setModalShowTransactions] = useState(false);
  const [tokenId, setTokentId] = useState("");
  const [dateIssued, setDateIssued] = useState();
  const [dateExpiry, setDateExpiry] = useState();
  const [dateFrom, setDateFroms] = useState();
  const [dateTo, setDateTos] = useState();
 
  //popup model values
  const [popupname, setpopupname] = useState();
  const [popuptokenid, setpopuptokenid] = useState();
  const [popupcompanyname, setpopupcompanyname] = useState();
  const [popupexpirydate, setpopupexpirydate] = useState();
  const [popupissuedate, setpopupissuedate] = useState();
  const [popupstatus, setpopupstatus] = useState();
  const [amountpopup, setAmountpopup] = useState();
  const [showidtype, setShowidtype] = useState();
  const [showproofofaddress, setShowproofofaddress] = useState();
  //Success Message
  const [modalsuccessfullShow, setModalsuccessfullShow] = useState(false);
  const [reEnableActivationStatus, setreEnableActivationStatus] = useState(false);
  const [resendActivationMessage, setresendActivationMessage] = useState(false);
  const [refereshdata, setRefereshdata] = useState(false);
  const [modalGlobalIssuer, setModalGlobalIssuer] = useState(false);
  const [iskycsubmitted, setIskycsubmitted] = useState();
  const [KycIssuerNotes, setKycIssuerNotes] = useState();
  const [isKycApprovedByIssuer, setIsKycApprovedByIssuer] = useState();
  const [updatecardinfo, setupdatecardinfo] = useState();
  const [IssuerCategory, setIssuerCategory] = useState(currentUser?.user?.IssuerName);

//Loader
const [boloader, setBoLoader] = useState(false);

  const styleObj = {
    fontSize: 10,
  };
  useEffect(() => {
    SearchGridInputData[0].startIndex = 1;
    SearchClickHandler();
   // getCardDetail();
  }, []);
  const getCardDetail = (cardholderuuid) => {
    setBoLoader(true);
    cardHolderService
      .getcardholderDetail(cardholderuuid)
      .then((res) => {
        setBoLoader(false);
        if (res && res.data && res.data.success) {
          if (res && res.data && res.data.payload) {
            setupdatecardinfo(res?.data?.payload?.result);
            setIssuerCategory(res?.data?.payload?.result?.issuerCategory);
            if(currentUser?.user?.IssuerName==="IssuerG"){
              let showproofofaddress=res?.data?.payload?.result?.filePathAddressProof?.split(",");
              if(showproofofaddress[1]!==undefined)
              setShowproofofaddress(showproofofaddress[1]);
              else
              setShowproofofaddress(res?.data?.payload?.result?.filePathAddressProof);
              let showidtype=res?.data?.payload?.result?.filePathIdType?.split(",");
              if(showidtype[1]!==undefined)
              setShowidtype(showidtype[[1]]);
              else
              setShowidtype(res?.data?.payload?.result?.filePathIdType);
            }
            else{
              setShowproofofaddress(res?.data?.payload?.result?.filePathAddressProof);
              setShowidtype(res?.data?.payload?.result?.filePathIdType);
            }
            setAmountpopup(res.data.payload.result.availableBalance);
            setIskycsubmitted(res?.data?.payload?.result?.isKycSubmitted);
            setIsKycApprovedByIssuer(res?.data?.payload?.result?.isKycApprovedByIssuer);
            setKycIssuerNotes(res?.data?.payload?.result?.kycIssuerNotes)
            
          } 
        }
      })
      .catch((err) => {
        setBoLoader(false);
        (async () => {
          let resp = await RefreshToken(
            err && err.response && err.response.status
          );
          if (resp) {
            window.location.reload();
          } else {
          }
        })();
      });
  };
  const handleInputSearchClick = (e) => {
    e.preventDefault();
    setModalShow(true);
    setSearchDisabled(true);
    setCompanyName("");
    dateToHandler("");
    dateFromHandler("");
  };
  const nameChangeHandler = (e) => {
    SearchGridInputData[0].Name = e.target.value;
    setCompanyName(SearchGridInputData[0].Name);
  };
  const dateFromHandler = (e) => {
    SearchGridInputData[0].StartDate = e;
    if (e) {
      let date = SearchGridInputData[0].StartDate;
      if (SearchGridInputData[0].StartDate !== "") {
        SearchGridInputData[0].StartDate = moment(
          SearchGridInputData[0].StartDate
        ).format("YYYY-MM-DD");
        setDateFrom(date);
      } else {
        setDateFrom(date);
      }
    }
  };
  const dateFilter = (date) => {
    return new Date(date);
  };
  const dateToHandler = (e) => {
    SearchGridInputData[0].EndDate = e;
    if (e) {
      let date = SearchGridInputData[0].EndDate;
      if (SearchGridInputData[0].EndDate !== "") {
        SearchGridInputData[0].EndDate = moment(
          SearchGridInputData[0].EndDate
        ).format("YYYY-MM-DD");
        setDateTo(date);
      } else {
        setDateTo(date);
      }
    }
  };
  const SearchClickHandler = (e,calledfrom) => {

    setLoadingData(true);
    let clientid="";
    if(props?.calledfrom=="superclient")
    {
      clientid=String(props?.clientId);
    }
    else
    clientid=ClientId
    if(calledfrom==="filter")
    SearchGridInputData[0].startIndex=1;
    if (!SearchGridInputData[0].scrolled) {
      setSearchGridSuccess(false);
    }
    setModalShow(false);
    setSearchDisabled(false);
    commonService
      .searhGrid(
        name,
        tokenId,
        dateIssued || "0001-01-01",
        dateExpiry || "0001-01-01",
        dateFrom || "0001-01-01",
        dateTo || "0001-01-01",
        SearchGridInputData[0].startIndex,
        props.methodname,
        props.apiurl,
        clientid,
        //props.screenname === "newcards" ? "Pending" : "",
        SearchGridInputData[0].Status
      )
      .then((resp) => {
        setLoadingData(false);
        if (resp && resp.data && resp.data.payload) {
          if (resp.data.success) {
            setModalShow(false);
            setSearchDisabled(false);
          }
          if (
            resp.data.payload.result.pageModel.length > 0 ||
            searchData.length > 0
          ) {
            setTotalCount(resp.data.payload.result.totalCount);
            if (SearchGridInputData[0].scrolled) {
              let appendedData = searchData.concat(
                resp.data.payload.result.pageModel
              );
              setSearchData(appendedData);
            } else {
              setSearchData(resp.data.payload.result.pageModel);
              SearchGridInputData[0].startIndex = 1;
            }
            setSearchGridSuccess(true);

            SearchGridInputData[0].scrolled = false;
            // if (SearchGridInputData[0].isFirstTime) {
            searchTbl.current.onscroll = tblScrollHandler;
            SearchGridInputData[0].isFirstTime = false;
            // }
          }
        }
      })
      .catch((error) => {
        setSearchGridSuccess(false);
        setLoadingData(false);
        (async () => {
          let resp = await RefreshToken(
            error && error.response && error.response.status
          );
          if (resp) {
            SearchClickHandler();
          }
        })();
      });
  };
  const tblScrollHandler = (e) => {
    if (
      !SearchGridInputData[0].scrolled &&
      SearchGridInputData[0].startIndex + 10 <= totalCount
    ) {
      SearchGridInputData[0].scrolled = true;
      SearchGridInputData[0].startIndex =
        SearchGridInputData[0].startIndex + 10;
      SearchClickHandler();
    }
  };
  const renderTableHeader = () => {
    let header = Object.values(props.headersdata);
    return header.map((key, index) => {
      if(props?.hasviewPermission===false && key==="VIEW")
      return;
      else
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  };
  const showApproveKycPopup = (
                  popupcardHolderUuid,
                  popupcardHolderName,
                  popuptokenId,
                  popupcompanyName,
                  popupexpiryDate,
                  popupissueDate,
                  popupcardStatus,
                  popupuserId
  ) => {
    getCardDetail(popupcardHolderUuid);
    setuserId(popupuserId);
    setpopupname(popupcardHolderName);
    setpopuptokenid(popuptokenId);
    setpopupcompanyname(popupcompanyName);
    setpopupexpirydate(popupexpiryDate);
    setpopupissuedate(popupissueDate);
    setpopupstatus(popupcardStatus);
    setModalShowTransactions(true);

  };
  const renderTableData = () => {
    return searchData.map((data, index) => {
      const {
        cardHolderUuid,
        cardHolderFirstName,
        cardHolderLastName,
        tokenId,
        cardNumber,
        companyName,
        expiryDate,
        issueDate,
        cardStatus,
        userId,
        
      } = data; //destructuring
      return (
        <tr key={cardHolderUuid}>
           <td style={{ textTransform: "capitalize" }} title={cardHolderFirstName}>{cardHolderFirstName?.substr(0,10)??"-"}{cardHolderFirstName?.length>10 &&("...")}</td>
          <td style={{ textTransform: "capitalize" }} title={cardHolderLastName}>{cardHolderLastName?.substr(0,10)??"-"}{cardHolderLastName?.length>10 &&("...")}</td>         
         
          <td>{tokenId}</td>
          <td>{cardNumber}</td>
          <td style={{ textTransform: "capitalize" }}>{companyName}</td>
          {
            currentUser?.user?.IssuerName !== "IssuerG"? (
              <td>
              <DateFormat date={expiryDate} />
            </td>
            ):(
              <td>
              
            </td>
            )
          }
         
          <td>
            <DateFormat date={issueDate} />
          </td>
          <td>{cardStatus}</td>
          {props?.hasviewPermission===true &&(
              <td>
              <span
                className="icon-show"
                onClick={() =>
                  showApproveKycPopup(
                    cardHolderUuid,
                    cardHolderLastName,
                    tokenId,
                    companyName,
                    expiryDate,
                    issueDate,
                    cardStatus,
                    userId
                  )
                }
              ></span>
            </td>
          )}
        
        </tr>
      );
    });
  };
  const HideSearchPopupDiv = () => {
    setModalShow(false);
    setSearchDisabled(false);
    setCompanyName("");
  };
  const ClearSearchData = () => {
    setCompanyName("");
    dateToHandler("");
    dateFromHandler("");
    ClearInputData();
  };
  const ClearInputData = () => {
    if (document.getElementById("clientNameDD") !== null)
      document.getElementById("clientNameDD").value = "";
    setTimeout(() => {
      [...document.getElementsByClassName("r-input-group")].forEach(
        (element) => {
          element.children[0].value = "";
        }
      );
      document.getElementById("searchBtnClients").click();
    }, 500);
  };
  const approveRejectKyc = (stat) => {
    setBoLoader(true);
    setStatus(stat);
    cardsService
      .approverejectkyc(userId,stat)
      .then((res) => {
        setBoLoader(false);
        if (res && res.data && res.data.success) {
          setModalShowTransactions(false);
                    setModalsuccessfullShow(true);

        }
      })
      .catch((err) => {
        setBoLoader(false);
        // let msgBody = err.response.data
        //   ? err.response.data.message
        //   : "Error Occured while Registering card holder";
        // let errIcon = "icon-error";
        // errorAndSuccessModl("Error", msgBody, errIcon);
        // (async () => {
        //   let resp = await RefreshToken(
        //     err && err.response && err && err.response && err.response.status
        //   );
        //   if (resp) {
        //     approveRejectKyc(false);
        //   }
        // })();
      });
  };
  
  const renderTransactionDetails = () => {
    return (
      <div class="row">
          <BoLoader loading={boloader} />
         
               <div class="col-md-12">
        {(currentUser?.user?.IssuerName==="IssuerG" &&!iskycsubmitted ) ? (<h4>
            <b>
            {KycIssuerNotes ? KycIssuerNotes:`User has not submitted KYC yet. It will appear here once submitted`}</b>
            </h4>)
          : (currentUser?.user?.IssuerName==="IssuerG" && iskycsubmitted && !isKycApprovedByIssuer) ?(
          <h4>
          <b> {KycIssuerNotes ?
          (KycIssuerNotes)
          :`KYC Decision is pending at Issuer End`} 
          <Fragment>
          <div className="col-md-12">
                <div className="form-group submit-field d-flex justify-content-between mt-40 mb-0">
                  <button
                    className="btn btn-outline"
                    data-bs-dismiss="modal"
                    onClick={() => showModelIssuerGlobal()}>
                    Update Document 
                  </button>
                </div>
              </div>
          </Fragment>
          </b></h4>
          ):(
        <Fragment>
          <h3>New Card Details</h3>
          <div class="mt-16">
            <form onSubmit={(e) => e.preventDefault()}>
              <ul class="ul-card-details-popup">
                <li class="d-flex justify-content-between mb-3">
                  <label>Name</label>
                  <span>{popupname}</span>
                </li>
                <li class="d-flex justify-content-between mb-3">
                  <label>Token ID</label>
                  <span>{popuptokenid}</span>
                </li>
                <li class="d-flex justify-content-between mb-3">
                  <label>Company Name</label>
                  <span>{popupcompanyname}</span>
                </li>
                <li class="d-flex justify-content-between mb-3">
                  <label>Card Expiry Date</label>
                  <span>
                    <DateFormat date={popupexpirydate} />
                  </span>
                 
                </li>
                <li class="d-flex justify-content-between mb-3">
                  <label>Card Issue Date</label>
                  <span>
                    <DateFormat date={popupissuedate} />
                  </span>
                </li>
                <li class="d-flex justify-content-between mb-3">
                  <label>Available Balance</label>
                  <span>
                   $ {amountpopup}
                  </span>
                </li>
                <li class="d-flex justify-content-between mb-3">
                  <label>Status</label>
                  <span>{popupstatus}</span>
                </li>
              </ul>
              {showidtype || showproofofaddress ? (<Fragment>
                <h3>ID Type</h3>
                <div className="deposit-slip-img">
                  <img
                    className="img-fluid"
                    src={
                      showidtype
                    }
                    alt="deposit"
                  />
                </div>
                <h3>Proof of Address</h3>
                <div className="deposit-slip-img">
                  <img
                    className="img-fluid"
                    src={
                      showproofofaddress
                    }
                    alt="deposit"
                  />
                </div>
                <div className="col-md-12">
                  <div className="form-group submit-field d-flex justify-content-between mt-40 mb-0">
                    <button
                      className="btn btn-outline"
                      data-bs-dismiss="modal"
                      onClick={() => approveRejectKyc(false)}
                    >
                      Reject
                    </button>
                    <button
                      className="btn btn-large"
                      data-bs-dismiss="modal"
                      onClick={() => approveRejectKyc(true)}
                    >
                      Accept
                    </button>
                  </div>
                </div>




              </Fragment>) : ("")}
              <div className="col-md-12" >
                <div className="form-group submit-field d-flex justify-content-between mt-40 mb-0">
                  <button
                    style={{ fontSize: '1.2rem', maxWidth: "24px" }}
                    className="btn btn-large"
                    data-bs-dismiss="modal"
                    onClick={renameActivationProcessHandler}>
                    Resend Activation Email
                  </button>
                  <button
                    style={{ fontSize: '1.2rem', maxWidth: "24px" }}
                    className="btn btn-large"
                    data-bs-dismiss="modal"
                    onClick={enableActivationProcessHandler}
                  >
                    Reenable Activation Process
                  </button>
                </div>
              </div>
            </form>
          </div>
          </Fragment>
           )}
        </div>
      </div>
    );
  };
  const renameActivationProcessHandler = () => {

    setBoLoader(true);
    cardHolderService
      .renameActivationProcessHandler(userId)
      .then((res) => {
        setBoLoader(false);
        if (res && res.data && res.data.success) {
          if (res && res.data && res.data.payload) {
            setresendActivationMessage(true)
            setModalShowTransactions(false);
            setModalsuccessfullShow(true);
          }
        }
      })
      .catch((err) => {
        setBoLoader(false);
        (async () => {
          let resp = await RefreshToken(
            err && err.response && err.response.status
          );
          if (resp) {
            window.location.reload();
          } else {
          }
        })();
      });
  }
  const enableActivationProcessHandler = () => {
    setBoLoader(true);
    cardHolderService
      .enableActivationProcess(userId)
      .then((res) => {
        setBoLoader(false);
        if (res && res.data && res.data.success) {
          if (res && res.data && res.data.payload) {
            setreEnableActivationStatus(true)
            setModalShowTransactions(false);
            setModalsuccessfullShow(true);

          }

        }
      })
      .catch((err) => {
        setBoLoader(false);
        (async () => {
          let resp = await RefreshToken(
            err && err.response && err.response.status
          );
          if (resp) {
            window.location.reload();
          } else {
          }
        })();
      });
  }
  const showModelIssuerGlobal = (stat) => {
    setModalGlobalIssuer(true);
};
const clearFieldGlobal=()=>{
  setIskycsubmitted();
  setIsKycApprovedByIssuer();
  setKycIssuerNotes();
}
  return (
    <Fragment>
      <div className="row align-items-center mt-14">
        <div className="col-6"></div>
      </div>
      <div className="mt-0 mt-sm-4">
        <div className="" id="myTabContent2">
          <div
            className="tab-pane fade show active"
            id="allClients"
            role="tabpanel"
            aria-labelledby="allClients-tab"
          >
            {/* no client found  */}
            <div className="d-flex align-content-center justify-content-center no-clients-msg d-none">
              <div className="text-center">
              <span className="icon-issued-card">
                  <span className="path1"></span><span className="path2"></span>
                  <span className="path3"></span><span className="path4"></span>
                  <span className="path5"></span><span className="path6"></span>
                  <span className="path7"></span><span className="path8"></span>
                  <span className="path9"></span><span className="path10"></span>
                  <span className="path11"></span>
                </span>
                <h3>Empty Card Listing</h3>
                <h6>There is no pending card(s) available for now.</h6>
              </div>
            </div>
            <Fragment>
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="row row-search">
                  <div className="col-xl-12 col-12">
                    <div className="form-group">
                      <div className="form-controls">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={props.placeholdervalue}
                          onClick={handleInputSearchClick}
                          disabled={SearchInputDisabled}
                        />
                        <span className="icon-search _ico"></span>
                        {/* search box  */}
                        <div
                          className="bo-cardsearch"
                          style={{ display: modalShow ? "" : "none" }}
                        >
                          <div className="row">
                            <div className="col-md-12">
                              <img
                                className="cross-icon cross-modal"
                                src={crossImgs}
                                aria-label="Close"
                                onClick={HideSearchPopupDiv}
                                alt=""
                              />
                            </div>
                            <div className="col-md-6">
                              <div className="form-group form-search mr-20">
                                <label>Date From</label>
                                <div className="form-controls">
                                  <ReactDatePickerHelper
                                    datevalue={StartDate}
                                    onchangedate={dateFromHandler}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group form-search mr-20">
                                <label>Date To</label>
                                <div className="form-controls">
                                  <ReactDatePickerHelper
                                    datevalue={EndDate}
                                    onchangedate={dateToHandler}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="form-group submit-field form-search">
                                <button
                                  id="searchBtnClients"
                                  className="btn btn-large mr-19"
                                  onClick={()=>SearchClickHandler("","filter")}
                                >
                                  Search
                                </button>
                                <button
                                  className="btn btn-outline"
                                  onClick={ClearSearchData}
                                >
                                  Clear Search
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <span className="help-block d-none"></span>
                    </div>
                  </div>
                </div>
              </form>
            </Fragment>
          </div>

          {searchGridSuccess && (
            <div className="table-wrap" ref={searchTbl}>
              <table
                className="table table-borderless bo-table table-cards"
                onScroll={tblScrollHandler}
                onWheel={tblScrollHandler}
              >
                <thead>
                  <tr>{renderTableHeader()}</tr>
                </thead>
                <tbody>{renderTableData()}</tbody>
              </table>
            </div>
          )}
          {loadingData ? (
            <div className="loading-more">
              <div className="loader">
                <div></div>
              </div>
              <p>loading data</p>
            </div>
          ) : totalCount === 0 ? (
            <div className="d-flex align-content-center justify-content-center no-clients-msg ">
              <div className="text-center">
              <span className="icon-issued-card">
                  <span className="path1"></span><span className="path2"></span>
                  <span className="path3"></span><span className="path4"></span>
                  <span className="path5"></span><span className="path6"></span>
                  <span className="path7"></span><span className="path8"></span>
                  <span className="path9"></span><span className="path10"></span>
                  <span className="path11"></span>
                </span>
                <h3>Empty Cards Listing</h3>
                <h6>There are no cards available for now.</h6>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div>
        <ModelPopup
          key={1}
          show={modalShowTransactions}
          onHide={() => {setModalShowTransactions(false);clearFieldGlobal();}}
          modelheading={""}
          modelbody={renderTransactionDetails()}
          showheader={"false"}
          dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
          className="smallPopup issueCardSmall"
          backdrop="static"
          keyboard={false}
        />
         <ModelPopup
        key={2}
        show={modalsuccessfullShow}
        onHide={() => setModalsuccessfullShow(false)}
        modelheading={""}
        showheader={"false"}
        className="smallPopup issueCardSmall"
        dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
        backdrop="static"
        keyboard={false}
        modelbody={[
          <Fragment>
            <div className="row">
              <div className="col-md-12">
                <div className="icon-alert icon-alert-popup">
                  <span className="icon-successful">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </span>
                </div>
                <h2>Successful</h2>
                <div className="mt-16">
                  <div>
                    <form>
                      <div className="row">
                        <div className="col-md-12">
                         {/* {status && (
                          <p>Card Approved Successfully</p>
                         )
                         } 
                          {!status && (
                          <p>Card Rejected Successfully</p>
                         )
                         }  */}
                         {resendActivationMessage === true && (
                              <p>Activation Email Sent Successfully</p>
                            )
                            }
                            {reEnableActivationStatus === true && (
                              <p>Re Enable Activation Process successfully</p>
                            )
                            } 
                        </div>
                        <div className="col-md-12">
                          <div className="form-group submit-field mt-40 mb-0">
                            <Link
                              to="#"
                              className="btn btn-large w-100"
                              onClick={() => {
                                setModalsuccessfullShow(false);
                                window.location.reload();
                              }}
                            >
                              Done
                            </Link>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>,
        ]}
      />
      <ModelPopup
        key={2}
        show={modalGlobalIssuer}
        onHide={() => setModalGlobalIssuer(false)}
        modelheading={""}
        showheader={"false"}
        className="smallPopup issueCardSmall"
        dialogClassName="modal-dialog modal-dialog-centered mwidth-76"
        backdrop="static"
        keyboard={false}
        modelbody={[
          <Fragment>
           <RegisterGlobal UserId={userId} onHide={()=>{setModalGlobalIssuer(false)}} updatecardinfo={updatecardinfo} />
          </Fragment>,
        ]}
      />
      </div>
    </Fragment>
  );
};

export default SearchGridPendingCards;
