import DateFormat from "../../helpers/DateFormat";
import ModelPopup from "../../helpers/ModelPopup";
import { Fragment, useState } from "react";
import cardHolderService from "../../services/cardholder.service";
import cardService from "../../services/cards.services";
import RefreshToken from "../../helpers/RefreshToken";
import SearchGridTableHelper from "../../helpers/SearchGridTableHelper";
import DialoguePopup from "../../helpers/DialoguePopup";
import BoLoader from "../../helpers/BoLoader";

const PendingCardHolders = (props) => {
  const [cardHolderDetail, setCardHolderDetail] = useState();
  const [cardHolderDetailSuccessful, setcardHolderDetailSuccessful] =
    useState(false);
  const [noDataFound, setNoDataFound] = useState(false);
  const [showAccptCnfrmPopup, setShowAccptCnfrmPopup] = useState(false);
  const [ShowRjctCnfrmPopup, setShowRjctCnfrmPopup] = useState(false);
  const [newcardmodalShow, setNewCardModalShow] = useState(false);
  const [modelhdng, setMdlHdng] = useState("");
  const [MdlIcon, setMdlIcon] = useState("");
  const [modelbdy, setMdlBdy] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [boloader, setBoLoader] = useState(false);
  const loadCardHolderDetails = (chUuId) => {
    setcardHolderDetailSuccessful(false);
    setNoDataFound(false);
    setBoLoader(true);
    cardHolderService
      .getcardholderDetail(chUuId)
      .then((res) => {
        setBoLoader(false);
        if (res && res.data && res.data.success) {
          if (res.data.payload) {
            setCardHolderDetail(res.data.payload);
            setcardHolderDetailSuccessful(true);
          } else {
            setNoDataFound(true);
          }
        }
      })
      .catch((err) => {
        setBoLoader(false);
        setcardHolderDetailSuccessful(false);
        setNoDataFound(true);
        (async () => {
          let resp = await RefreshToken(
            err && err.response && err.response.status
          );
          if (resp) {
            loadCardHolderDetails();
          }
        })();
      });
  };
  const renderRjctCnfrmPopup = (e) => {
    return (
      <div className="row">
        <div className="col-md-12">
          <h2>Confirmation</h2>
          <div className="mt-16">
            <div>
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="row">
                  <div className="col-md-12">
                    <p>Are you sure to reject selected card?</p>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group submit-field d-flex justify-content-between mt-20 mb-0">
                      <button
                        className="btn btn-outline"
                        data-bs-dismiss="modal"
                        onClick={() => setShowRjctCnfrmPopup(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="btn btn-large"
                        data-bs-dismiss="modal"
                        onClick={RejectCardHandler}
                      >
                        Reject
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderAccptCnfrmPopup = (e) => {
    return (
      <div className="row">
        <div className="col-md-12">
          <h2>Confirmation</h2>
          <div className="mt-16">
            <div>
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="row">
                  <div className="col-md-12">
                    <p>Are you sure to approve selected card?</p>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group submit-field d-flex justify-content-between mt-20 mb-0">
                      <button
                        className="btn btn-outline"
                        data-bs-dismiss="modal"
                        onClick={() => setShowAccptCnfrmPopup(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="btn btn-large"
                        data-bs-dismiss="modal"
                        onClick={ApproveCardHandler}
                      >
                        Accept
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const ApproveCardHandler = (e) => {
    e.preventDefault();
    ApproveRejectCardHandler(true);
  };
  const RejectCardHandler = (e) => {
    e.preventDefault();
    ApproveRejectCardHandler(false);
  };
  const ApproveRejectCardHandler = (approveRejectVal) => {
    setBoLoader(true);
    cardService
      .approveRejectCardHolder(approveRejectVal, cardHolderDetail.cardUuid)
      .then((res) => {
        setBoLoader(false);
        if (res && res.data && res.data.success) {
          if (res.data.payload) {
            props.searchclickhandler();
            if (approveRejectVal) {
              setShowAccptCnfrmPopup(false);
              errorAndSuccessModl(
                "Success",
                "Card Approved Successfully.",
                "icon-successful"
              );
            } else {
              setShowRjctCnfrmPopup(false);
              errorAndSuccessModl(
                "Success",
                "Card Rejected Successfully.",
                "icon-successful"
              );
            }
          } else {
          }
        }
      })
      .catch((err) => {
        setBoLoader(false);
        let msgBody = err.response.data
          ? err.response.data.message
          : "Error Occured while Registering card holder";
        let errIcon = "icon-error";
        errorAndSuccessModl("Error", msgBody, errIcon);
        (async () => {
          let resp = await RefreshToken(
            err && err.response && err && err.response && err.response.status
          );
          if (resp) {
            ApproveRejectCardHandler(approveRejectVal);
          }
        })();
      });
  };
  const errorAndSuccessModl = (heading, body, Icon) => {
    setModalShow(true);
    setMdlHdng(heading);
    setMdlBdy(body);
    setMdlIcon(Icon);
  };
  const renderTableData = () => {
    return props.searchdata.map((data, index) => {
      const {
        cardHolderUuid,
        cardHolderName,
        companyName,
        tokenId,
        expiryDate,
        issueDate,
        balance,
        cardStatus,
      } = data; //destructuring
      return (
        <tr key={cardHolderUuid}>
          <td style={{ textTransform: "capitalize" }}>{cardHolderName}</td>
          <td>{tokenId}</td>
          <td style={{ textTransform: "capitalize" }}>{companyName}</td>
          <td>
            <DateFormat date={expiryDate} />
          </td>
          <td>
            <DateFormat date={issueDate} />
          </td>
          <td>{balance}</td>
          <td>{cardStatus}</td>
          <td>
            <span
              title="View Card Holder Details"
              className="icon-show"
              onClick={() => {
                setNewCardModalShow(true);
                loadCardHolderDetails(cardHolderUuid);
              }}
            ></span>
          </td>
        </tr>
      );
    });
  };
  return (
    <Fragment>
      <BoLoader loading={boloader} />
      <SearchGridTableHelper
        searchgridsuccess={props.searchgridsuccess}
        searchtbl={props.searchtbl}
        tblscrollhandler={props.tblscrollhandler}
        rendertableheader={props.rendertableheader}
        renderTableData={renderTableData}
        loadingdata={props.loadingdata}
        totalcount={props.totalcount}
        screenname="newcards"
      />
      <ModelPopup
        show={newcardmodalShow}
        key={12}
        onHide={() => setNewCardModalShow(false)}
        modelheading={""}
        showheader={"false"}
        className="smallPopup issueCardSmall"
        dialogClassName="modal-dialog modal-dialog-centered mwidth-76"
        modelbody={[
          <Fragment>
            {cardHolderDetailSuccessful && (
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="row">
                  <div className="col-md-12">
                    <h2>New Card Detail</h2>
                    <ul className="ul-card-details-popup mt-16 ul-issue-card">
                      <li className="mb-16">
                        <div className="row">
                          <div className="col-4">
                            <label>Name</label>
                          </div>
                          <div className="col-8">
                            <span>{cardHolderDetail.cardHolderName}</span>
                          </div>
                        </div>
                      </li>
                      <li className="mb-16">
                        <div className="row">
                          <div className="col-4">
                            <label>Token ID</label>
                          </div>
                          <div className="col-8">
                            <span>{cardHolderDetail.tokenId}</span>
                          </div>
                        </div>
                      </li>
                      <li className="mb-16">
                        <div className="row">
                          <div className="col-4">
                            <label>Company Name</label>
                          </div>
                          <div className="col-8">
                            <span>{cardHolderDetail.companyName}</span>
                          </div>
                        </div>
                      </li>
                      <li className="mb-16">
                        <div className="row">
                          <div className="col-4">
                            <label>Card Expiry Date</label>
                          </div>
                          <div className="col-8">
                            <span>
                              <DateFormat date={cardHolderDetail.expiryDate} />
                            </span>
                          </div>
                        </div>
                      </li>
                      <li className="mb-16">
                        <div className="row">
                          <div className="col-4">
                            <label>Card Issue Date</label>
                          </div>
                          <div className="col-8">
                            <span>
                              <DateFormat date={cardHolderDetail.issueDate} />
                            </span>
                          </div>
                        </div>
                      </li>
                      <li className="mb-16">
                        <div className="row">
                          <div className="col-4">
                            <label>Available Balance</label>
                          </div>
                          <div className="col-8">
                            <span>${cardHolderDetail.availableBalance}</span>
                          </div>
                        </div>
                      </li>
                      <li className="mb-32">
                        <div className="row">
                          <div className="col-4">
                            <label>Status</label>
                          </div>
                          <div className="col-8">
                            {/* <span>{cardHolderDetail.cardHolderStatus}</span> */}
                            <span>Pending</span>
                          </div>
                        </div>
                      </li>
                      <li className="mb-24">
                        <div className="row">
                          <div className="col-4">
                            <label>ID Type</label>
                          </div>
                          <div className="col-8">
                            <span>{cardHolderDetail.idType}</span>
                          </div>
                        </div>
                      </li>
                      <li className="mb-40">
                        <div className="text-center id-doc">
                          <img
                            src={cardHolderDetail.filePathIdType}
                            alt="id document"
                          />
                        </div>
                      </li>
                      <li className="mb-24">
                        <div className="row">
                          <div className="col-4">
                            <label>Proof of Address</label>
                          </div>
                          <div className="col-8">
                            <span>{cardHolderDetail.addressProof}</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="text-center add-paper">
                          <img
                            src={cardHolderDetail.filePathAddressProof}
                            alt="house dodument"
                          />
                        </div>
                      </li>
                    </ul>
                    <div className="form-group submit-field d-flex justify-content-end mt-30 mb-0">
                      <button
                        data-bs-dismiss="modal"
                        className="btn btn-outline mr-20"
                        onClick={() => {
                          setNewCardModalShow(false);
                          setShowRjctCnfrmPopup(true);
                        }}
                      >
                        Reject
                      </button>
                      <button
                        className="btn btn-large"
                        onClick={() => {
                          setNewCardModalShow(false);
                          setShowAccptCnfrmPopup(true);
                        }}
                      >
                        Approve
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            )}
            {noDataFound && <p>No details found for selected card holder</p>}
          </Fragment>,
        ]}
      />
      <ModelPopup
        key={13}
        show={showAccptCnfrmPopup}
        onHide={() => setShowAccptCnfrmPopup(false)}
        modelheading={""}
        modelbody={renderAccptCnfrmPopup()}
        showheader={"false"}
        dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
        className="smallPopup issueCardSmall"
      />
      <ModelPopup
        key={14}
        show={ShowRjctCnfrmPopup}
        onHide={() => setShowRjctCnfrmPopup(false)}
        modelheading={""}
        modelbody={renderRjctCnfrmPopup()}
        showheader={"false"}
        dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
        className="smallPopup issueCardSmall"
      />
      <DialoguePopup
        show={modalShow}
        onHide={() => setModalShow(false)}
        modelheading={modelhdng}
        modelbody={modelbdy}
        modelicon={MdlIcon}
        hideallpopups={() => setModalShow(false)}
      />
    </Fragment>
  );
};

export default PendingCardHolders;
