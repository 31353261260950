import axios from "axios";
import authHeader from "./auth-header";
import { Env } from "../enviroment/environment";

const API_URL = Env.TransactionApiUrl;
const API_IBAN=Env.IBANApiUrl;
const getTransactionStatsData = (clientId) => {
  return axios
    .get(API_URL + "getTransactionstats?clientId=" + clientId, {
      headers: authHeader(),
    })
    .then((res) => {
      return res;
    });
};
const getVerificationPayee = (
  TokenId,
  Amount,
  SourceCurrencyCode,
  DestinationCurrencyCode,
  ClientId,
  UserId
) => {
  return axios
    .post(
      API_URL + "GetFundWalletVerification",
      {
        TokenId,
        Amount,
        SourceCurrencyCode,
        DestinationCurrencyCode,
        ClientId,
        UserId,
      },
      { headers: authHeader() }
    )
    .then((response) => {
      return response;
    });
};
const saveFundWalletClient = (
  Amount,
  SourceCurrencyCode,
  DestinationCurrencyCode,
  ClientId,
  UserId,
  Email,
  CustomerHashId,
  WalletHashId,
  TokenId,
  ReceiverName,
  CardHashId,
) => {
  return axios
    .post(
      API_URL + "FundWalletClient",
      {
        Amount,
        SourceCurrencyCode,
        DestinationCurrencyCode,
        ClientId,
        UserId,
        Email,
        CustomerHashId,
        WalletHashId,
        TokenId,
        ReceiverName,
        CardHashId,
      },
      { headers: authHeader() }
    )
    .then((response) => {
      return response;
    });
};
const searhGridTransactionsClients = (
  userId,
  DateFrom,
  DateTo,
  ClientId,
  Status,
  PageNumber,
  Size,
  Order,
) => {
  return axios
    .post(
      API_URL + "gettransaction?userId=" + userId,
      {
        userId,
        DateFrom,
        DateTo,
        ClientId,
        Status,
        PageNumber,
        Size,
        Order,
        ClientId
      },
      { headers: authHeader() }
    )
    .then((resp) => {
      return resp;
    });
};
const searhGridTransactionsCards = (
  UserId,
  StartDate,
  EndDate,
  // Status,
  PageNumber,
  // Size,
  // Order
) => {
  return axios
    .post(
      API_URL + "GetCardTransactions ",
      {
        UserId,
        StartDate,
        EndDate,
        // Status,
        PageNumber,
        // Size,
        // Order
      },
      { headers: authHeader() }
    )
    .then((resp) => {
      return resp;
    });
};
const debitCard = (
  
  AmountDebit,
  TokenId,
  CardFeeCurrencyCode,
  ClientId,Description,UserId
  
) => {
return axios
.post(
API_URL + "debitamountfromcardholder",
{
  TokenId,
  AmountDebit,
  CardFeeCurrencyCode,
  ClientId,Description,
  UserId
},
{ headers: authHeader() }
)
.then((response) => {
return response;
});
};
const searhGridDebit = (name, ClientId, dateFrom, dateTo, startIndex, Status,userId) => {
  return axios
    .post(
      API_URL + "gettransaction",
      {
        name,
        ClientId,
        dateFrom,
        dateTo,
        startIndex,
        Status,
      },
      { headers: authHeader() }
    )
    .then((resp) => {
      return resp;
    });
};
const GetCardTransactionsspecificmonth = (
  UserId,
  Month,
  Year
) => {
  return axios
    .post(
      API_URL + "GetCardTransactionsspecificmonth ",
      {
      UserId,
       Month,
       Year
      },
      { headers: authHeader() }
    )
    .then((resp) => {
      return resp;
    });
};
//API Call for Data Older than 3 months !!!
const gettransactionhistory = (name, tokenId,ClientId, dateFrom, dateTo, startIndex, Status, userId,TransactionType,FetchAll) => {
  
  return axios
    .post(
      API_URL + "gettransactionhistory",
      {
        name,
        tokenId,
        ClientId,
        dateFrom,
        dateTo,
        startIndex,
        Status,
        TransactionType,
        FetchAll
      },
      { headers: authHeader() }
    )
    .then((resp) => {
      return resp;
    });
};
const searhGridIBANTransactions = (
  AccountId,
  DateFrom,
  DateTo,
  PageSize,
) => {
  return axios
    .post(
      API_IBAN + "getaccounttransactions ",
      {
        AccountId,
        DateFrom,
        DateTo,
        PageSize,
      },
      { headers: authHeader() }
    )
    .then((resp) => {
      return resp;
    });
};
const getListOfBeneficiaries = (
  accountId,
  
) => {
  return axios
    .post(
      API_IBAN +"getListOfBeneficiaries?accountId=" + accountId,
      {
       
      },
      { headers: authHeader() }
    )
    .then((resp) => {
      return resp;
    });
};
const addbeneficiary = (
   AccountId 
  ,Currency 
  ,FirstName 
  ,MiddleName 
  ,LastName 
  ,AddressLine1 
  ,AddressLine2 
  ,City 
  ,ZipCode 
  ,Type 
  ,AccountNumber 
  ,AccountCode
  ,IBAN 
) => {
return axios
.post(
API_IBAN + "addbeneficiary",
{
   AccountId 
  ,Currency 
  ,FirstName 
  ,MiddleName 
  ,LastName 
  ,AddressLine1 
  ,AddressLine2 
  ,City 
  ,ZipCode 
  ,Type 
  ,AccountNumber 
  ,AccountCode
  ,IBAN 
},
{ headers: authHeader() }
)
.then((response) => {
return response;
});
};
const accountTransfer = (
  FromAccountId ,
  Currency ,
  Amount ,
  Reason ,
  Reference ,
  ToAccountId ,
) => {
return axios
.post(
  API_IBAN + "acounttransfer",
{
  FromAccountId ,
  Currency ,
  Amount ,
  Reason ,
  Reference ,
  ToAccountId ,
},
{ headers: authHeader() }
)
.then((response) => {
return response;
});
};
const benificaryTransfer = (
  FromAccountId ,
  Currency ,
  Amount ,
  Reason ,
  BeneficaryId,
) => {
return axios
.post(
  API_IBAN + "accounttobeneficary",
{
  FromAccountId ,
  Currency ,
  Amount ,
  Reason ,
  BeneficaryId,
},
{ headers: authHeader() }
)
.then((response) => {
return response;
});
};
const transactionObj = {
  getTransactionStatsData,
  getVerificationPayee,
  saveFundWalletClient,
  searhGridTransactionsClients,
  searhGridTransactionsCards,
  debitCard,
  searhGridDebit,
  GetCardTransactionsspecificmonth,
  gettransactionhistory,
  searhGridIBANTransactions,
  addbeneficiary,
  getListOfBeneficiaries,
  accountTransfer,
  benificaryTransfer
};
export default transactionObj;
