import axios from "axios";
import { Env } from "../enviroment/environment";
import authHeader from "./auth-header";

const API_URL = Env.DepositApiUrl;
const getDepositStatusData = (clientId) => {
  return axios
    .post(
      API_URL + "getdepositstats?clientId=" + clientId,
      {},
      { headers: authHeader() }
    )
    .then((res) => {
      return res;
    });
};
const approverejectdeposit = (status, depositUuid) => {
  return axios
    .post(
      API_URL +
        "approverejectdeposit?status=" +
        status +
        "&depositUuid=" +
        depositUuid,
      {},
      { headers: authHeader() }
    )
    .then((res) => {
      return res;
    });
};
const depositService = {
  getDepositStatusData,
  approverejectdeposit,
};
export default depositService;
