import { Button } from "bootstrap";
import React, { useState, useEffect, Fragment } from "react";
import ReactExport from "react-export-excel";
 import xls from "../../src/assets/images/xls.svg";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ExportToExcel = props => {
  const ExcelColmn = () => {
    if (props.calledfrom === "transaction") {
      return (
        <ExcelFile
          element={
            <button className="btn btn-outline">
              <img style={{ width: "20px", marginTop: "-3px" }} src={xls} />{" "}
              Download{" "}
            </button>
          }
        >
          <ExcelSheet data={props.Dataset} name={props.Name}>
            <ExcelColumn label="Date" value="createdDate" />
            <ExcelColumn label="Transaction" value="debitCredit" />
            <ExcelColumn label="Transaction TYPE" value="transactionType" />
            <ExcelColumn label="TOKEN ID" value="tokenId" />
            <ExcelColumn label="AMOUNT" value="amount" />
            {/* <ExcelColumn label="FIRST NAME" value="firstName" />
            <ExcelColumn label="LAST NAME" value="lastName" /> */}
          </ExcelSheet>
        </ExcelFile>
      );
    }
    else if (props.calledfrom === "cardtransaction") {
      return (
        <ExcelFile
          element={
            <button className="btn btn-outline">
              <img style={{ width: "20px", marginTop: "-3px" }} src={xls} />{" "}
              Export To Excel{" "}
            </button>
          }
        >
          <ExcelSheet data={props.Dataset} name={props.Name}>
            <ExcelColumn label="MERCHANT NAME" value="merchantName" />
            <ExcelColumn label="STATUS" value="status" />
            <ExcelColumn label="AMOUNT" value="authAmount" />
            <ExcelColumn label="DATE" value="dateOfTransaction" />
            <ExcelColumn label="REF #" value="retrievalReferenceNumber" />
          </ExcelSheet>
        </ExcelFile>
      );
    } else if (props.calledfrom === "client") {
      return (
        <ExcelFile
          element={
            <button className="btn btn-outline">
              <img style={{ width: "20px", marginTop: "-3px" }} src={xls} />{" "}
              Export To Excel{" "}
            </button>
          }
        >
          <ExcelSheet data={props.Dataset} name={props.Name}>
            <ExcelColumn label="COMPANY NAME" value="clientName" />
            <ExcelColumn label="ADDRESS" value="address" />
            <ExcelColumn label="REG.DATE" value="registrationDate" />
            <ExcelColumn label="CONTACT PERSON" value="pointOfContact" />
            <ExcelColumn label="STATUS" value="clientStatus" />
          </ExcelSheet>
        </ExcelFile>
      );
    } else if(props.calledfrom==="deposit"){
      return(
          <ExcelFile
          element={
            <button className="btn btn-outline">
              <img style={{ width: "20px", marginTop: "-3px" }} src={xls} /> Export
              To Excel{" "}
            </button>
          }
        >
          <ExcelSheet data={props.Dataset} name={props.Name}>
         
             <ExcelColumn label="REFERENCE NO." value="requestId" />
             <ExcelColumn label="DATE" value="depositDate" />
             <ExcelColumn label="ACCPT. AMOUNT" value="acceptedAmount" />
             <ExcelColumn label="SENDING BANK" value="bank" />
             <ExcelColumn label="ACCOUNT NO" value="accountNumber" />
             <ExcelColumn label="STATUS" value="depositStatus" />

            
          </ExcelSheet>
        </ExcelFile>   
      )
  }
    else if (props.calledfrom === "cards") {
      if(props?.hascorporatepermission){
        return (
          <ExcelFile
            element={
              <button className="btn btn-outline">
                <img style={{ width: "20px", marginTop: "-3px" }} src={xls} />{" "}
                Export To Excel{" "}
              </button>
            }
          >
            <ExcelSheet data={props.Dataset} name={props.Name}>
            <ExcelColumn label="FIRST NAME" value="cardHolderFirstName" />
              <ExcelColumn label="LAST NAME" value="cardHolderLastName" />
              <ExcelColumn label="TOKEN ID" value="tokenId" />
              <ExcelColumn label="CARD NO." value="unMaskedCardNumber" />
              <ExcelColumn label="CVV" value="cvv" />
              <ExcelColumn label="EXPIRY DATE" value="expiryDate" />
              <ExcelColumn label="STATUS" value="cardStatus" />
            </ExcelSheet>
          </ExcelFile>
        );
      }
      else{
        return (
          <ExcelFile
            element={
              <button className="btn btn-outline">
                <img style={{ width: "20px", marginTop: "-3px" }} src={xls} />{" "}
                Export To Excel{" "}
              </button>
            }
          >
            <ExcelSheet data={props.Dataset} name={props.Name}>
            <ExcelColumn label="FIRST NAME" value="cardHolderFirstName" />
              <ExcelColumn label="LAST NAME" value="cardHolderLastName" />
              <ExcelColumn label="TOKEN ID" value="tokenId" />
              <ExcelColumn label="CARD NO." value="cardNumber" />
              <ExcelColumn label="EXPIRY DATE" value="expiryDate" />
             
              <ExcelColumn label="STATUS" value="cardStatus" />
            </ExcelSheet>
          </ExcelFile>
        );
      }
     
    } 
    else if(props.calledfrom==="suspendedcards"){
      return(
          <ExcelFile
          element={
            <button className="btn btn-outline">
              <img style={{ width: "20px", marginTop: "-3px" }} src={xls} /> Export
              To Excel{" "}
            </button>
          }
        >
          <ExcelSheet data={props.Dataset} name={props.Name}>
         
             <ExcelColumn label="CARD HOLDER" value="cardHolderName" />
             <ExcelColumn label="TOKEN ID" value="tokenId" />
             <ExcelColumn label="CLIENT NAME" value="clientName" />
             <ExcelColumn label="CREATED DATE" value="createdAt" />
             <ExcelColumn label="Description" value="description" />
             <ExcelColumn label="STATUS" value="blockAction" />

            
          </ExcelSheet>
        </ExcelFile>   
      )
  }else {
      return (
        <ExcelFile
          element={
            <button className="btn btn-outline">
              <img style={{ width: "20px", marginTop: "-3px" }} src={xls} />{" "}
              Export To Excel{" "}
            </button>
          }
        >
          <ExcelSheet data={props.Dataset} name={"No Data"}></ExcelSheet>
        </ExcelFile>
      );
    }
  };
  return <ExcelColmn />;
};

export default ExportToExcel;
