import { useState, useEffect, Fragment } from "react";
import { Env } from "../../enviroment/environment";
import BoLoader from "../../helpers/BoLoader";
import { Link } from "react-router-dom";
import { history } from "../../helpers/history";
import ModelPopup from "../../helpers/ModelPopup";
import { useDispatch, useSelector } from "react-redux";
import { haspermissions } from "../permissions/PermissionTypes";
import SearchGridClient from "../superadmin/SearchGridClients";
import IssueCard from "../cardholders/IssueCard";
import LoadCard from "../cardholders/LoadCard";
import IssuePhysicalCard from "../cardholders/IssuePhysicalCard";
const API_URL = Env.ClientAdmin;
const prop = history;
const SuperClientDetails = (props) => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const [DataSuccessful, setDataSuccessful] = useState(true);
  const [boloader, setBoLoader] = useState(false);
  const [ledgerBalances, setLedgerBalance] = useState();
  const [modalShowPhysical, setModalShowPhysical] = useState(false);
  const [confirmmodalShowPhysical, setCnfrmModalPhysical] = useState(false);
  const [cardcount, setCardcount] = useState(0);

  const [modalShow, setModalShow] = useState(false);
  const [showIssueCard, setShowIssueCard] = useState(false);
  const [modalShowLoadCard, setmodalShowLoadCard] = useState(false);
  const [confirmmodalShow, setCnfrmModalShow] = useState(false);
  if (currentUser) {
    haspermissions.SuperClientActivatePhysical =
      currentUser?.user?.Permissions?.includes("Activate Physical Card");
    haspermissions.SuperClientLoadCard =
      currentUser?.user?.Permissions?.includes("Load Card");
      haspermissions.SuperClientIssueVirtualCard =
      currentUser?.user?.Permissions?.includes("Issue Virtual Card");
  }
  const checkIssueCard=()=>{
    if(haspermissions.SuperClientIssuePhysicalCard && haspermissions.IssuePhysicalCard)
    setShowIssueCard(true);
    else if(haspermissions.SuperClientIssueVirtualCard && haspermissions.IssueVirtualCard)
    setShowIssueCard(true);
    else
    setShowIssueCard(false);
  }
  useEffect(() => {

    let val = ledgerBalances?.split(",");
    if (val?.Length > 0) {

    }
  }, [setLedgerBalance])
  const IssueCardPhysicalPopupHandler = () => {
    setModalShowPhysical(true);
    setCnfrmModalPhysical(false);
  };
  const IssueCardPopupHandler = () => {
    setModalShow(true);
    setCnfrmModalShow(false);
  };
  const LoadCardPopupHandler = () => {
    setmodalShowLoadCard(true);
  };
  return (
    <div className="col-xl-9 col-lg-12 bo-main-content">

      <BoLoader loading={boloader} />
      <div className="row">
        {
          haspermissions.SuperClientActivatePhysical &&(
            <div
            className="col-md-3 col-8 col-sm-6 col-xl-2"
            onClick={() => setCnfrmModalPhysical(true)}
          >
            <div className="col-stats col-stats-action mb-40">
              <div>
                <span className="icon-issued-card">
                  <span className="path1"></span>
                  <span className="path2"></span>
                  <span className="path3"></span>
                  <span className="path4"></span>
                  <span className="path5"></span>
                  <span className="path6"></span>
                  <span className="path7"></span>
                  <span className="path8"></span>
                  <span className="path9"></span>
                  <span className="path10"></span>
                  <span className="path11"></span>
                </span>
                <br />
                <Link to="#" className="mt-3">
                  Activate Physical Card
                  <span className="icon-bo-arrow"></span>
                </Link>
              </div>
            </div>
          </div>
          )
        }
     {
      haspermissions.SuperClientLoadCard &&(
        <div className="col-md-3 col-6 col-xl-2" onClick={LoadCardPopupHandler}>
        <div className="col-stats col-stats-action mb-40">
          <div>
            <span className="icon-load-card">
              <span className="path1"></span>
              <span className="path2"></span>
              <span className="path3"></span>
              <span className="path4"></span>
              <span className="path5"></span>
              <span className="path6"></span>
              <span className="path7"></span>
              <span className="path8"></span>
              <span className="path9"></span>
              <span className="path10"></span>
              <span className="path11"></span>
              <span className="path12"></span>
              <span className="path13"></span>
              <span className="path14"></span>
            </span>
            <br />
            <Link to="#" className="pt-3">
              Load Card
              <span className="icon-bo-arrow"></span>
            </Link>
          </div>
        </div>
      </div>
      )
     }
     {
      haspermissions.SuperClientIssueVirtualCard &&( <div
        className="col-md-3 col-6 col-xl-2"
        onClick={() => setCnfrmModalShow(true)}
      >
        <div className="col-stats col-stats-action mb-40">
          <div>
            <span className="icon-issued-card">
              <span className="path1"></span>
              <span className="path2"></span>
              <span className="path3"></span>
              <span className="path4"></span>
              <span className="path5"></span>
              <span className="path6"></span>
              <span className="path7"></span>
              <span className="path8"></span>
              <span className="path9"></span>
              <span className="path10"></span>
              <span className="path11"></span>
            </span>
            <br />
            <Link to="#" className="pt-3">
              Issue Card
              <span className="icon-bo-arrow"></span>
            </Link>
          </div>
        </div>
      </div>
)
     }
     
      </div>
     
      {DataSuccessful && (
        <Fragment>
          <SearchGridClient
            methodname="getclients"
            placeholdervalue="Search Client"
            headersdata={[
              "LOGO",
              "CLIENT NAME",
              "REG. DATE",
              "STATUS",
              "LEDGER BALANCE(S)",
              "VIEW",
            ]}
            apiurl={API_URL}
            screenname="clients"
            createclientpermission={true}
            calledfrom={"superclient"}
            superclientid={currentUser?.user?.SuperClientId}
            ledgerBalances={setLedgerBalance}
          />
        </Fragment>
      )}
      <ModelPopup
        show={confirmmodalShowPhysical}
        onHide={() => setCnfrmModalPhysical(false)}
        modelheading={""}
        showheader={"false"}
        className="smallPopup issueCardSmall"
        dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
        backdrop="static"
        keyboard={false}
        modelbody={[
          <Fragment>
            <div className="row">
              <div className="col-md-12">
                <h2>Activate Physical Card</h2>
                <div className="mt-16">
                  <div>
                    <form onSubmit={(e) => e.preventDefault()}>
                      <div className="row">
                        <div className="col-md-12">
                          <p>Do you want to Activate Physical Card?</p>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group submit-field d-flex justify-content-between mt-20 mb-0">

                            <button
                              className="btn btn-large"
                              onClick={IssueCardPhysicalPopupHandler}
                            >
                              Activate Physical Card
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>,
        ]}
      />
      <ModelPopup
        show={confirmmodalShow}
        onHide={() => setCnfrmModalShow(false)}
        modelheading={""}
        showheader={"false"}
        className="smallPopup issueCardSmall"
        dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
        backdrop="static"
        keyboard={false}
        modelbody={[
          <Fragment>
            <div className="row">
              <div className="col-md-12">
                <h2>Issue Card</h2>
                <div className="mt-16">
                  <div>
                    <form onSubmit={e => e.preventDefault()}>
                      <div className="row">
                        <div className="col-md-12">
                          <p>Do you want to Issue Card?</p>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group submit-field d-flex justify-content-between mt-20 mb-0">
                            {/* <button
                                className="btn btn-outline"
                                //onClick="openModalBulkIssue()"
                              >
                                Bulk Issue
                              </button> */}
                            <button
                              className="btn btn-large"
                              onClick={IssueCardPopupHandler}
                            >
                              Issue Card
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>,
        ]}
      />
       <ModelPopup
          show={confirmmodalShow}
          onHide={() => setCnfrmModalShow(false)}
          modelheading={""}
          showheader={"false"}
          className="smallPopup issueCardSmall"
          dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
          backdrop="static"
          keyboard={false}
          modelbody={[
            <Fragment>
              <div className="row">
                <div className="col-md-12">
                  <h2>Issue Card</h2>
                  <div className="mt-16">
                    <div>
                      <form onSubmit={e => e.preventDefault()}>
                        <div className="row">
                          <div className="col-md-12">
                            <p>Do you want to Issue Card?</p>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group submit-field d-flex justify-content-between mt-20 mb-0">
                              {/* <button
                                className="btn btn-outline"
                                //onClick="openModalBulkIssue()"
                              >
                                Bulk Issue
                              </button> */}
                              <button
                                className="btn btn-large"
                                onClick={IssueCardPopupHandler}
                              >
                                Issue Card
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>,
          ]}
        />
      <IssueCard
        show={modalShow}
        onHide={() => setModalShow(false)}

      />
      <LoadCard
        show={modalShowLoadCard}
        onHide={() => setmodalShowLoadCard(false)}
      />
      <IssuePhysicalCard
        show={modalShowPhysical}
        onHide={() => setModalShowPhysical(false)}

      />
    </div>
  );
};

export default SuperClientDetails;
