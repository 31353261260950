import React, { useState, useRef, Fragment, useEffect } from "react";
import { history } from "../../helpers/history";
import clientmanagmentObj from "../../services/client-managment.service";
import validator from "validator";
import DateFormat from "../../helpers/DateFormat";
import crossImgs from "../../assets/images/icons/cross.svg";
import RefreshToken from "../../helpers/RefreshToken";
import ReactDatePickerHelper from "../../helpers/ReactDatePickerHelper";
import GetAllClients from "../../helpers/GetAllClients";
import ExportToExcel from "./ExportToExcel";
import { haspermissions } from "../permissions/PermissionTypes";
import { useDispatch, useSelector } from "react-redux";
import CurrencyFormat from 'react-currency-format';
import ModelPopup from "../../helpers/ModelPopup";
import BoLoader from "../../helpers/BoLoader";

const SearchGridInputData = [
  {
    Name: "",
    ClientId: "",
    DateFrom: "",
    TokenId: "",
    DateTo: "",
    startIndex: 1,
    scrolled: false,
    isFirstTime: true,
    Status: "",
    TransactionType: "",
    FetchAll: false
  },
];
var fontStyle = {
  textTransform: "uppercase",
};
var isledgerhistory = "col-sm-5 toRight ms-auto mt-sm-100 mt-4 mb-4";

const SearchGridDebitTransaction = (props) => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const [modalShow, setModalShow] = useState(false);
  const [name, setCompanyName] = useState("");
  const [clientId, setclientId] = useState("");
  const [tokenId, setTokenId] = useState("");
  const [TransactionType, setTransactionType] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const searchTbl = useRef();
  const [loadingData, setLoadingData] = useState(false);
  const [searchData, setSearchData] = useState();
  const [searchGridSuccess, setSearchGridSuccess] = useState(false);
  const [AllClientClassName, setAllClientClassName] = useState("nav-link");
  const [NewClientClassName, setNewClientClassName] = useState("nav-link");
  const [AllClientAreaSelected, setAllClientAreaSelected] = useState("false");
  const [NewClientsAreaSelected, setNewClientsAreaSelected] = useState("false");
  const [SearchInputDisabled, setSearchDisabled] = useState(false);
  const [clientIdVal, setClientIdVal] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [classContent, setClassContent] = useState("tab-content");
  const [Status, setStatus] = useState("");
  const [dataset, setDataset] = useState();
  const [balance, setBalance] = useState();
  const [apiBalanceKaped, setApiBalanceKaped] = useState();
  const [isKapedClient, setisKapedClient] = useState(false);
  const [isExcelDownload, setiisExcelDownload] = useState(false);
  const [showExcelDownloadModal, setshowExcelDownloadModal] = useState(false);
  const [boloader, setBoLoader] = useState(false);
  const [currency, setcurrency] = useState("");
  const [clientprofile, setClientprofile] = useState();
  const [isOldData, setIsOldData] = useState(false);
  if (currentUser) {
    haspermissions.ViewLedgerBalance = currentUser?.user?.ClientPermissions?.includes("View ledger balance");
    haspermissions.ViewLedgerBalance = currentUser?.user?.Permissions?.includes("Ledger Balance");
    if (!haspermissions.ViewLedgerBalance)
      isledgerhistory = "col-sm-5 toRight ms-auto"
    haspermissions.ViewLedgerHistory = currentUser?.user?.ClientPermissions?.includes("View ledger history");
    haspermissions.ViewLedgerHistory = currentUser?.user?.Permissions?.includes("View Ledger History");

  }

  useEffect(() => {
    allClients();
    if (props?.calledfrom === "dashboard")
      setClassContent("");
    clientmanagmentObj
      .getprofiledetails(props?.clientId).then((response) => {
        let res = response.data.payload.result;
        // let value=(+res?.balance).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')

        setBalance(res?.balance);
        setClientprofile(res);
        setcurrency(res?.currencyCode);
        if (res?.issuerCategory === "IssuerB") {
          setisKapedClient(true);
          getApiLegderBalanceForKAped();
        }
      }).catch();


  }, []);
  const getApiLegderBalanceForKAped = () => {
    clientmanagmentObj
      .getIssuebMainBalance().then((response) => {
        let res = response?.data?.payload?.result;
        setApiBalanceKaped(res);
      }).catch();
  }
  const handleInputSearchClick = (e) => {
    e.preventDefault();
    setModalShow(true);
    setSearchDisabled(true);
    setclientId("");
    setTokenId("");
    setCompanyName("");
    dateToHandler("");
    dateFromHandler("");
  };
  const nameChangeHandler = (e) => {
    SearchGridInputData[0].Name = e.target.value;
    setCompanyName(SearchGridInputData[0].Name);
  };
  const tokenChangeHandler = (e) => {
    SearchGridInputData[0].TokenId = e.target.value;


    setTokenId(e.target.value);
  };
  const transactionTypeChangeHandler = (e) => {
    SearchGridInputData[0].TransactionType = e.target.value;
    setTransactionType(e.target.value);

  };


  const dateFromHandler = (e) => {
    SearchGridInputData[0].DateFrom = e;
    if (e)
      SearchGridInputData[0].DateFrom = dateFilter(
        SearchGridInputData[0].DateFrom
      );
    setDateFrom(SearchGridInputData[0].DateFrom);
  };
  const dateFilter = (date) => {
    return new Date(date);
  };
  const dateToHandler = (e) => {
    SearchGridInputData[0].DateTo = e;
    if (e)
      SearchGridInputData[0].DateTo = dateFilter(SearchGridInputData[0].DateTo);
    setDateTo(SearchGridInputData[0].DateTo);
  };
  const SearchClickHandler = (e, isFromUseEffect) => {

    if (e) {
      e.preventDefault();
      SearchGridInputData[0].startIndex = 1;
    }
    if (clientId || clientIdVal !== "")
      SearchGridInputData[0].ClientId = !clientId
        ? clientIdVal
        : clientId;
    else SearchGridInputData[0].ClientId = "";
    setLoadingData(true);
    HideSearchPopupDiv();
    if (!SearchGridInputData[0].scrolled) {
      setSearchGridSuccess(false);
    }
    if (isOldData) {
      clientmanagmentObj
        .gettransactionhistory(
          name,
          tokenId,
          props?.clientId.toString(),
          dateFrom || new Date("0001-01-01"),
          dateTo || new Date("0001-01-01"),
          SearchGridInputData[0].startIndex,
          Status,
          props?.userId,
          TransactionType,
          SearchGridInputData[0].FetchAll
        )
        .then((resp) => {
          setLoadingData(false);
          if (resp && resp.data && resp.data.payload) {
            if (resp.data.success) {

              setModalShow(false);
              setSearchDisabled(false);
            }
            if (
              resp.data.payload.result.pageModel.length > 0 ||
              searchData.length > 0
            ) {
              setTotalCount(resp.data.payload.result.totalCount);
              if (SearchGridInputData[0].scrolled) {
                let appendedData = searchData.concat(
                  resp.data.payload.result.pageModel
                );
                setSearchData(appendedData);
                setDataset(appendedData);
              } else {
                setSearchData(resp.data.payload.result.pageModel);
                setDataset(resp.data.payload.result.pageModel);
                SearchGridInputData[0].startIndex = 1;
              }
              setSearchGridSuccess(true);
              SearchGridInputData[0].scrolled = false;
              searchTbl.current.onscroll = tblScrollHandler;
              SearchGridInputData[0].isFirstTime = false;
            }
          }
        })
        .catch((error) => {
          setSearchGridSuccess(false);
          setLoadingData(false);
          (async () => {
            let resp = await RefreshToken(
              error && error.response && error.response.status
            );
            if (resp) {
              SearchClickHandler();
            }
          })();
        });
    } else {
      clientmanagmentObj
        .searhGridDebit(
          name,
          tokenId,
          props?.clientId.toString(),
          dateFrom || new Date("0001-01-01"),
          dateTo || new Date("0001-01-01"),
          SearchGridInputData[0].startIndex,
          Status,
          props?.userId,
          TransactionType,
          SearchGridInputData[0].FetchAll
        )
        .then((resp) => {
          setLoadingData(false);
          if (resp && resp.data && resp.data.payload) {
            if (resp.data.success) {
              setModalShow(false);
              setSearchDisabled(false);
            }
            if (
              resp.data.payload.result.pageModel.length > 0 ||
              searchData.length > 0
            ) {
              setTotalCount(resp.data.payload.result.totalCount);
              if (SearchGridInputData[0].scrolled) {
                let appendedData = searchData.concat(
                  resp.data.payload.result.pageModel
                );
                setSearchData(appendedData);
                setDataset(appendedData);
              } else {
                setSearchData(resp.data.payload.result.pageModel);
                setDataset(resp.data.payload.result.pageModel);
                SearchGridInputData[0].startIndex = 1;
              }
              setSearchGridSuccess(true);
              SearchGridInputData[0].scrolled = false;
              searchTbl.current.onscroll = tblScrollHandler;
              SearchGridInputData[0].isFirstTime = false;

            }
          }
        })
        .catch((error) => {
          setSearchGridSuccess(false);
          setLoadingData(false);
          (async () => {
            let resp = await RefreshToken(
              error && error.response && error.response.status
            );
            if (resp) {
              SearchClickHandler();
            }
          })();
        });
    }

  };


  const tblScrollHandler = (e) => {
    if (
      !SearchGridInputData[0].scrolled &&
      SearchGridInputData[0].startIndex + 30 <= totalCount
    ) {
      SearchGridInputData[0].scrolled = true;
      SearchGridInputData[0].startIndex =
        SearchGridInputData[0].startIndex + 30;
      SearchClickHandler();
    }
  };

  const renderTableHeader = () => {
    let header = Object.values(props.headersdata);
    return header.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  };
  const allClients = () => {
    setAllClientClassName("nav-link active d-flex align-items-center");
    setAllClientAreaSelected("true");
    setNewClientClassName("nav-link d-flex align-items-center");
    setNewClientsAreaSelected("false");
    setSearchDisabled(false);
    SearchGridInputData[0].startIndex = 1;
    SearchGridInputData[0].ClientId = "";
    setCompanyName("");
    dateToHandler("");
    dateFromHandler("");
    setclientId("");
    setClientIdVal("");
    SearchClickHandler();
  };

  const showCardHolderDetailPage = (id, userId, issuerCategory) => {
    if (id && issuerCategory && userId)
      history.push("/cardholderdetails", { id, userId, issuerCategory });
  };
  const renderTableData = () => {
    return searchData.map((data, index) => {
      const {
        clientName,
        toClient,
        tokenId,
        amount,
        createdDate,
        debitCredit,
        currencyCode,
        description,
        transactionType,
        uniqueUserId,
        cardHolderUuid,
        issuerCategory,
        firstName,
        lastName
      } = data; //destructuring
      return (
        <tr key={tokenId}>
          <td >
            <DateFormat date={createdDate} />
          </td>
          <td style={fontStyle} title={debitCredit}>{debitCredit}</td>
          <td style={fontStyle} title={transactionType}>{transactionType}</td>
          {
            (tokenId !== "0" && transactionType !== "Deposit") ? (<td>  <span style={{ color: '#0d6efd', cursor: 'pointer' }}
              title="View Card Holder Details" onClick={() => showCardHolderDetailPage(cardHolderUuid, uniqueUserId, issuerCategory)} >
              {tokenId}</span></td>) : (<td></td>)
          }

          <td style={fontStyle}><CurrencyFormat value={amount} displayType={'text'} thousandSeparator={true} /> {currencyCode}</td>
          {/* {
            transactionType==="Deposit" ?(
              <td style={fontStyle} title={ toClient} >
              {toClient?.substr(0, 20)}
              {toClient?.length > 10 && ("...")}</td> 
            ):(
            <Fragment>
              {transactionType==="SuspendCard"?(<Fragment>
                <td style={fontStyle} title={toClient} >
                      {toClient?.substr(0, 20)}
                      {toClient?.length > 10 && ("...")}</td>
              </Fragment>):
              
              (<Fragment>
                {transactionType==="DebitCard"  ?
              (<td>{toClient} </td>):

              (
              
              <td style={fontStyle} title={(debitCredit==="Credit" && transactionType==="LedgertoLedger") ? toClient:clientName} >
            {
            (debitCredit==="Credit" && transactionType==="LedgertoLedger")?toClient?.substr(0, 20):clientName?.substr(0, 20)}
            {clientName?.length > 10 && ("...")}
            </td>
            
            )}
              </Fragment>)}
             
            
            </Fragment>
            )
          } */}
          {/* {
            transactionType==="Deposit" ?(
              <td style={fontStyle} title={ clientName} >
              {clientName?.substr(0, 20)}
              {clientName?.length > 10 && ("...")}</td> 
            ):(
              <Fragment>
              {transactionType==="LoadCard"  ?(<td>{toClient} </td>):
              
              (
                <Fragment>
                  {(debitCredit==="Credit" && transactionType==="DebitCard")?(
                     <td style={fontStyle} title={clientName} >
                     {clientName?.substr(0, 20)}
                     {clientName?.length > 10 && ("...")}</td>
                  ):(<Fragment>
                    {
                      transactionType==="SuspendCard"?(
                      <td style={fontStyle} title={clientName} >
                      {clientName?.substr(0, 20)}
                      {clientName?.length > 10 && ("...")}</td>):(
                      
                      <td style={fontStyle} title={(debitCredit==="Credit" && transactionType==="LedgertoLedger") ? clientName:toClient} >
                      {(debitCredit==="Credit" && transactionType==="LedgertoLedger")?clientName?.substr(0, 20):toClient?.substr(0, 20)}
                      {toClient?.length > 10 && ("...")}</td>)
                    }
                  </Fragment>
                    
                  )}

                
                 </Fragment>
              )}
             
            </Fragment>
            )
          } */}
<td  title={firstName} >{firstName}{firstName?.length > 20 && ("...")}</td>
            <td  title={lastName} >{lastName}{lastName?.length > 20 && ("...")}</td>
          <td style={fontStyle} title={description}>{description?.substr(0, 10)}{description?.length > 10 && ("...")}</td>
        </tr>
      );
    });
  };
  const HideSearchPopupDiv = () => {
    setModalShow(false);
    setSearchDisabled(false);
    setclientId("");
    setCompanyName("");
  };
  const ClearSearchData = () => {
    setclientId("");
    setCompanyName("");
    setTokenId("");
    dateToHandler("");
    dateFromHandler("");
    setStatus("");
    setTransactionType("");
    setIsOldData(false);
    ClearInputData();
  };
  const ClearInputData = () => {
    if (document.getElementById("clientNameDD") !== null)
      document.getElementById("clientNameDD").value = "";
    setClientIdVal("");
    setTimeout(() => {
      [...document.getElementsByClassName("r-input-group")].forEach(
        (element) => {
          element.children[0].value = "";
        }
      );
      document.getElementById("searchBtnClients").click();
    }, 500);
  };
  const ClientNameChangeHandler = (e) => {
    const clientName = e.target.selectedOptions[0].value;
    setClientIdVal(clientName);
  };
  const onChangestatus = (e) => {
    const status = e.target.selectedOptions[0].value;
    if (status != "") {
      setStatus(status);
    }
  };
  const showExcelModel = (e) => {
    setshowExcelDownloadModal(true);
  }
  const downloadExportToExcel = () => {
    setBoLoader(true);
    clientmanagmentObj
      .searhGridDebit(
        name,
        tokenId,
        props?.clientId.toString(),
        dateFrom || new Date("0001-01-01"),
        dateTo || new Date("0001-01-01"),
        SearchGridInputData[0].startIndex,
        Status,
        props?.userId,
        TransactionType,
        true
      ).then((resp) => {
        setBoLoader(false);
        setDataset(resp?.data?.payload?.result?.pageModel)
        setshowExcelDownloadModal(false);
        setiisExcelDownload(true);
        setDateFrom("");
        setDateTo("");
      }).catch((error) => {
        setBoLoader(false);
        setSearchGridSuccess(false);
        setshowExcelDownloadModal(false);
        setLoadingData(false);
        (async () => {
          let resp = await RefreshToken(
            error && error.response && error.response.status
          );
          if (resp) {
            downloadExportToExcel();
          }
        })();
      });


  }
  const handleSingleCheck = e => {
    const chckSame = e.target.checked;
    if (chckSame === true) {
      setIsOldData(true);
    }
    if (chckSame === false) {
      setIsOldData(false);
    }

  };
  return (
    <div className="">
      <BoLoader loading={boloader} />
      <div id="myTabContent2">
        <div
          className="tab-pane fade show active"
          id="allClients"
          role="tabpanel"
          aria-labelledby="allClients-tab"
        >
          <div className="row" >

            {haspermissions.ViewLedgerBalance && (
              <Fragment>
                <div className="col-md-6 col-xl-4">
                <div
                  className="col-stats col-stats-card mb-40"
                  style={{ backgroundImage: `url(${""})` }}
                >
                  <h3>{clientprofile?.clientName}</h3>
                  <h3>Ledger Balance</h3>
                  <h3><CurrencyFormat value={balance} displayType={'text'} thousandSeparator={true} renderText={value => <h3>{value} {currency}</h3>} /> 
                  
                  </h3>
                  
                  <span className="icon-Wallet"></span>

                </div>
                </div>
              </Fragment>
            )}


            {
              haspermissions.ViewLedgerHistory && (

                <div className={isledgerhistory} Style={"width=82px"} >
                  {
                    isExcelDownload ? (<ExportToExcel
                      Dataset={dataset}
                      calledfrom="ledgerclient"
                      Name={"LedgerClient"}
                    />) : (
                      <button id="searchBtnExportToExcel" className="btn btn-large mr-19 mt-100" onClick={showExcelModel}> Export</button>)
                  }

                </div>
              )}

          </div>
          {haspermissions.ViewLedgerHistory && (
            <Fragment>
              {/* no client found  */}
              <div className="d-flex align-content-center justify-content-center no-clients-msg d-none">
                <div className="text-center">
                  <span className="icon-clients-empty">
                    <span className="path1"></span><span className="path2"></span>
                    <span className="path3"></span><span className="path4"></span>
                    <span className="path5"></span><span className="path6"></span>
                  </span>
                  {/* <img src="../../assets/images/icons/clients-empty.svg" /> */}
                  <h3>Empty Transaction Listing</h3>
                  <h6>There is no Transactio(s) available for now.</h6>
                </div>
              </div>
              <Fragment>
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="row row-search">

                    <div className="col-xl-12 col-12">
                      <div className="form-group">
                        <div className="form-controls">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={props?.placeholdervalue}
                            onClick={handleInputSearchClick}
                            disabled={SearchInputDisabled}
                          />
                          <span className="icon-search _ico"></span>
                          {/* search box  */}
                          <div
                            className="bo-cardsearch"
                            style={{ display: modalShow ? "" : "none" }}
                          >
                            <div className="row">
                              <div className="col-sm-6">
                                <div className="col-md-4">
                                  <img
                                    className="cross-icon cross-modal"
                                    src={crossImgs}
                                    aria-label="Close"
                                    onClick={HideSearchPopupDiv}
                                    alt=""
                                  />
                                </div>
                                <div className=" col-md-12 form-group form-search mr-20">

                                  <label>Name</label>
                                  <div className="form-controls mr-20">
                                    <input
                                      type="text"
                                      value={name}
                                      onChange={nameChangeHandler}
                                      className="form-control"
                                      maxLength={40}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6">

                                <div className="form-group form-search mr-20">
                                  <label>Date From</label>
                                  <div className="form-controls">
                                    <ReactDatePickerHelper
                                      datevalue={dateFrom}
                                      onchangedate={dateFromHandler}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group form-search mr-20">
                                  <label>Date To</label>
                                  <div className="form-controls">
                                    <ReactDatePickerHelper
                                      datevalue={dateTo}
                                      onchangedate={dateToHandler}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="form-group form-search mr-20">
                                  <label>Token Id</label>
                                  <div className="form-controls">
                                    <input type="number" pattern="\d*" maxlength="10"
                                      value={tokenId}
                                      onChange={tokenChangeHandler}
                                      className="form-control" />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group form-search mr-20">
                                  <label>Transaction Type</label>
                                  <div className="form-controls">
                                    <input type="text"
                                      value={TransactionType}
                                      onChange={transactionTypeChangeHandler}
                                      className="form-control" />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6 form-checkbox mb-16 mt-16">
                                <input
                                  type="checkbox"
                                  name="olderdatachk"
                                  id="olderdatachk"
                                  onClick={(e) => handleSingleCheck(e)}
                                />
                                <label class="checkbox-label" for="olderdatachk">
                                  More Than 3 Months
                                </label>
                              </div>

                              <div className="col-md-12">
                                <div className="form-group submit-field form-search">
                                  <button
                                    id="searchBtnClients"
                                    className="btn btn-large mr-19"
                                    onClick={SearchClickHandler}
                                  >
                                    Search
                                  </button>
                                  <button
                                    className="btn btn-outline"
                                    onClick={ClearSearchData}
                                  >
                                    Clear Search
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <span className="help-block d-none"></span>
                      </div>
                    </div>
                  </div>
                </form>
              </Fragment>
            </Fragment>
          )}


        </div>


        {
          haspermissions.ViewLedgerHistory && (
            <Fragment>
              {searchGridSuccess && (
                <div className="table-wrap" ref={searchTbl}>

                  <table
                    className="table table-borderless bo-table table-cards"
                    onScroll={tblScrollHandler}
                    onWheel={tblScrollHandler}
                  >
                    <thead>
                      <tr>{renderTableHeader()}</tr>
                    </thead>
                    <tbody>{renderTableData()}</tbody>
                  </table>
                </div>
              )}
            </Fragment>
          )
        }
        {
          haspermissions.ViewLedgerHistory && (
            <Fragment>
              {loadingData ? (
                <div className="loading-more">
                  <div className="loader">
                    <div></div>
                  </div>
                  <p>loading data</p>
                </div>
              ) : totalCount === 0 ? (
                <div className="d-flex align-content-center justify-content-center  no-clients-msg">
                  <div className="text-center">
                    <span className="icon-clients-empty">
                      <span className="path1"></span><span className="path2"></span>
                      <span className="path3"></span><span className="path4"></span>
                      <span className="path5"></span><span className="path6"></span>
                    </span>
                    {/* <img src={emptyclient} /> */}
                    <h3>Empty Transaction Listing</h3>
                    <h6>There is no Transation(s) available for now.</h6>
                  </div>
                </div>
              ) : (
                ""
              )}
            </Fragment>
          )
        }
      </div>
      <ModelPopup
        show={showExcelDownloadModal}
        onHide={() => setshowExcelDownloadModal(false)}
        modelheading={""}
        showheader={"false"}
        className="smallPopup issueCardSmall"
        dialogClassName="modal-dialog modal-dialog-centered mwidth-52"
        backdrop="static"
        keyboard={false}
        modelbody={[
          <Fragment>
            <div className="row">
              <div className="col-md-12">
                <h2>Export To Excel</h2>
                <div className="mt-16">
                  <div>
                    <form onSubmit={(e) => e.preventDefault()}>
                      <div className="row">

                        <div className="col-md-6">
                          <div className="form-group form-search mr-20">
                            <label>Date From</label>
                            <div className="form-controls">
                              <ReactDatePickerHelper
                                datevalue={dateFrom}
                                onchangedate={dateFromHandler}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group form-search mr-20">
                            <label>Date To</label>
                            <div className="form-controls">
                              <ReactDatePickerHelper
                                datevalue={dateTo}
                                onchangedate={dateToHandler}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group submit-field d-flex justify-content-between mt-20 mb-0">

                            <button
                              className="btn btn-large"
                              onClick={downloadExportToExcel}
                            >
                              Done
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>,
        ]}
      />
    </div>
  );
};

export default SearchGridDebitTransaction;
