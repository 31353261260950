import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import clientmanagmentObj from "../../services/client-managment.service";
import identityverificationObj from "../../services/identityverification.service";
import { history } from "../../helpers/history";
import Form from "react-validation/build/form";
import ModelPopup from "../../helpers/ModelPopup";
import crossimg from "../../assets/images/icons/cross.svg";
import fileuploadimg from "../../assets/images/icons/file-upload.svg";
import { useSelector } from "react-redux";
import ReactDatePickerHelper from "../../helpers/ReactDatePickerHelper";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import BoLoader from "../../helpers/BoLoader";
import DialoguePopup from "../../helpers/DialoguePopup";
import { Fragment } from "react";
import RefreshToken from "../../helpers/RefreshToken";
import {TrimCharacters} from "../../helpers/TrimCharacters"
import GetAllClients from "../../helpers/GetAllClients";
import GetAllSubClients from "../superclient/GetAllSubClients";
var depositDate = "";
const DepositClientSubmit = (props) => {
  return (
    <ModelPopup
      show={props.show}
      onHide={props.onHide}
      modelheading={""}
      showheader={"false"}
      className="smallPopup issueCardSmall"
      dialogClassName="modal-dialog modal-dialog-centered mwidth-76"
      backdrop="static"
      keyboard={false}
      modelbody={<DepositClient show={props.show} onHide={props.onHide} onAddDeposit={props.onAddDeposit} />}
    />
  );
};

const DepositClient = (props) => {
  let currencycodes = [ { id: "USD", value: "USD" },{
    id: "EUR",
    value: "EUR"
  }, { id: "GBP", value: "GBP" },];
  const form = useRef();
  const checkBtn = useRef();
  const { user: currentUser } = useSelector((state) => state.auth);
  const [RequestId, setRequestId] = useState();
  const [AccountNumber, setAccountNumberr] = useState("");
  const [Bank, setBank] = useState("");
  const [DepositDate, setDepositDate] = useState("");
  const [DepositAmount, setDepositAmount] = useState();
  const [LogoName, setlogoName] = useState("");
  const [WireConfirmationUrl, setWireConfirmationUrl] = useState("");
  const [LogoSize, setlogoSize] = useState();
  const [ClientId, setClientId] = useState();
  const [DateValid, setDateValid] = useState(true);
  const [isValidFileId, setisValidFileId] = useState(true);
  const [ClientCurrency, setClientCurrency] = useState("USD");
  const [isSuperClient, setIsSuperClient] = useState(false);
  
  //loader
  const [boloader, setBoLoader] = useState(false);
  //Dialogue Popup
  const [modalShow, setModalShow] = useState(false);
  const [modelhdng, setMdlHdng] = useState("");
  const [modelbdy, setMdlBdy] = useState("");
  const [MdlIcon, setMdlIcon] = useState("");
  const [showDateErr, setshowDateErr] = useState("d-none");
  const [showLogoErr, setshowLogoErr] = useState("d-none");
  const [showClientErr, setShowClientErr] = useState("d-none");
  useEffect(() => {
    setDateValid(true);
  }, [DepositDate]);
  useEffect(() => {
    if(currentUser.user.SuperClientId>0)
    setIsSuperClient(true);
    if(!isSuperClient)
    setClientId(currentUser.user.ClientId);
  }, []);
  const requestIdRequired = (value) => {
    if (!value) {
      document.getElementById("id_request").classList.add("has_error");
      return <span className="help-block">Reference No is required!</span>;
    } else document.getElementById("id_request").classList.remove("has_error");
  };
  const accountNumberRequired = (value) => {
    if (!value) {
      document.getElementById("id_accountnumber").classList.add("has_error");
      return <span className="help-block">Account number is required!</span>;
    } else
      document.getElementById("id_accountnumber").classList.remove("has_error");
  };
  const bankNameRequired = (value) => {
    if (!value) {
      document.getElementById("id_bankname").classList.add("has_error");
      return <span className="help-block">Bank name is required!</span>;
    } else document.getElementById("id_bankname").classList.remove("has_error");
  };
  const deposiAmountRequired = (value) => {
    if (!value) {
      document.getElementById("id_depositamount").classList.add("has_error");
      return <span className="help-block">Deposit amount is required!</span>;
    } else
      document.getElementById("id_depositamount").classList.remove("has_error");
  };
  // const dateRequired = (value) => {
  //   if (!value) {
  //     document.getElementById("id_date").classList.add("has_error");
  //     return <span className="help-block">deposit amount is required</span>;
  //   } else document.getElementById("id_date").classList.remove("has_error");
  // };
  const dateRequired = (value) => {
    if (!value) {
      document.getElementById("id_date").classList.add("has_error");
      setshowDateErr("");
      return false;
    } else {
      document.getElementById("id_date").classList.remove("has_error");
      setshowDateErr("d-none");
      return true;
    }
  };
  const logoRequired = (value) => {
    if (value === "" || value === undefined) {
      document.getElementById("id_fileupload").classList.add("has_error");
      setshowLogoErr("");
      return false;
    } else {
      document.getElementById("id_fileupload").classList.remove("has_error");
      setshowLogoErr("d-none");
      return true;
    }
  };
  const required = (value) => {
    if (!value) {
      return <span className="help-block">this field is required</span>;
    }
  };

  const onChangeRequestId = (e) => {
    let requestId = e.target.value;
    requestId=requestId.replace(/[^0-9 ]/gi, '')
    if (requestId != "") {
      let value = parseInt(requestId);
      setRequestId(value);
    }
  };
  const onChangepAccountNumber = (e) => {
    let value = e.target.value;
    value=value.replace(/[^a-z0-9 ]/gi, '')
    setAccountNumberr(value);
  };

  const onChangeClientCurrency = e => {
    const name = e.target.selectedOptions[0].value;
    setClientCurrency(name);
  }
  const onChangeBank = (e) => {
    let value = e.target.value;
    value=value.replace(/[^a-z0-9 ]/gi, '')
    setBank(value);
  };

  const onChangeDepositAmount = (e) => {
    const depositAmount = e.target.value;
    if (depositAmount != "") {
      let value = parseFloat(depositAmount);
      setDepositAmount(value);
    }
  };

  const onChangeLogo = (e) => {
    const file_data = e.target.files[0];
    const formdata = new FormData();
    let reg = /(.*?)\.(jpg|bmp|jpeg|pdf|PNG|png)$/;
    if (!file_data?.name.match(reg)) {
      setisValidFileId(false);
      return false;
    }
    else if (file_data?.size > 9000000) {
      setisValidFileId(false);
      return false;
    }
    setlogoName(file_data.name);
    var size = formatBytes(file_data.size);
    setlogoSize(size);
    formdata.append("File", file_data);
    setBoLoader(true);
    identityverificationObj
      .uploaduserdocs(formdata)
      .then((response) => {
        let name = response.data.payload;
        setWireConfirmationUrl(name);
        logoRequired(name);
        setBoLoader(false);

      })
      .catch((error) => {
        setBoLoader(false);
        logoRequired(WireConfirmationUrl);

        (async () => {
          let resp = await RefreshToken(
            error && error.response && error.response.status
          );
          if (resp) {
            window.location.reload();
          }
        })();
      });
  };
  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };
  const saveUser = (e) => {
    e.preventDefault();
    form.current.validateAll();

    let checkdate = dateRequired(DepositDate);
    let checklogo = logoRequired(WireConfirmationUrl);
    if (checkBtn.current.context._errors.length === 0 && checkdate && checklogo) {
      setBoLoader(true);
      clientmanagmentObj
        .depositamount(
          RequestId,
          AccountNumber,
          Bank,
          ClientCurrency,
          DepositDate,
          DepositAmount,
          WireConfirmationUrl,
          ClientId
        )
        .then((response) => {
          setBoLoader(false);
          if (response?.data?.message === "Success") {
            //history.push("depositclients");
            //window.location.reload();
            props.onAddDeposit();
          }
        })
        .catch((err) => {
          setBoLoader(false);
          let msgBody = err?.response?.data?.title
            ? err?.response?.data?.title
            : "Error Occured while saving data ";
          let errIcon = "icon-error";
          errorAndSuccessModl("Error", msgBody, errIcon);
          (async () => {
            let resp = await RefreshToken(
              err && err.response && err.response.status
            );
            if (resp) {
              if (resp) {
                saveUser(e);
                setBoLoader(false);
              } else {
                setBoLoader(false);
              }
            }
          })();
        });
    }
  };
  const errorAndSuccessModl = (heading, body, errIcon) => {
    setModalShow(true);
    setMdlHdng(heading);
    setMdlBdy(body);
    setMdlIcon(errIcon);
  };
  const onCrossImg = () => {
    setlogoName("");
    setWireConfirmationUrl("");
  };

  const onChangeDepositDate = (e) => {
    depositDate = e;
    if (e) {
      depositDate = dateFilter(depositDate);
      setDepositDate(depositDate);
    }
    dateRequired(depositDate);
  };
  const dateFilter = (date) => {
    return new Date(date);
  };
  const maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(0, object.target.maxLength)
    }
    var t = object.target.value;
    if(t.includes("."))
    object.target.value = (t?.indexOf(".") >= 0) ? (t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)) : t;
  }
  const ClientNameChangeHandler = async e => {
    const clientName = e.target.selectedOptions[0].value;
    if(clientName!=""){
    let val =parseInt(clientName);
    setClientId(val);
    requiredClient(val);
    }
  };
  const requiredClient = value => {
    if (!value) {
      document.getElementById("id_client").classList.add("has_error");
      setShowClientErr("");
      return false;
    } else {
      document.getElementById("id_client").classList.remove("has_error");
      setShowClientErr("d-none");
      return true;
    }
  };

  return (
    <Fragment>
      <div className="row">
        <BoLoader loading={boloader} />
        
        <div className="col-md-12">
          <h2>Submit Deposit</h2>
          <div className="mt-24">
            <Form onSubmit={saveUser} ref={form}>
              <div className="row">
                {isSuperClient &&(
                  <div className="col-md-6" id="id_client">
                            <div className="form-group">
                              <label>Select Program</label>
                              <div className="form-controls">
                                <GetAllSubClients
                                  clientnamechangehandler={
                                    ClientNameChangeHandler
                                  }
                                  searchgriddata="Y"
                                />
                                <span
                                  className={
                                    "help-block " + showClientErr
                                  }
                                >
                                  Client is required!
                                </span>
                              </div>
                            </div>
                          </div>
                )}
              
                <div className="col-md-6" id="id_request">
                  <div className="form-group">
                    <label>Reference Details.</label>
                    <div className="form-controls">
                      <Input
                        type="number"
                        maxLength="9"
                        onInput={maxLengthCheck}
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                        className="form-control no-icon"
                        name="Name"
                        onChange={onChangeRequestId}
                        //validations={[requestIdRequired, required]}
                        value={RequestId}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6" id="id_accountnumber">
                  <div className="form-group">
                    <label>Account Number*</label>
                    <div className="form-controls">
                      <Input
                        type="text"
                        className="form-control no-icon"
                        name="email"
                        onKeyDown={(evt) => {
                          let chck = TrimCharacters.checkSpecialCharacters(evt.key)
                          if (chck)
                            evt.preventDefault();
                        }
                        }
                        maxLength={20}
                        onChange={onChangepAccountNumber}
                        validations={[accountNumberRequired, required]}
                        value={AccountNumber}
                        // maxLength="14"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6" id="id_bankname">
                  <div className="form-group">
                    <label>Bank*</label>
                    <div className="form-controls">
                      <Input
                        type="text"
                        className="form-control no-icon"
                        name="phoneNumber"
                        onChange={onChangeBank}
                        onKeyDown={(evt) => {
                          let chck = TrimCharacters.checkSpecialCharacters(evt.key)
                          if (chck)
                            evt.preventDefault();
                        }
                        }
                        validations={[bankNameRequired, required]}
                        value={Bank}
                        maxLength="40"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6" id="id_bankname">
                <div className="form-group">
                  <label>Deposit Currency*</label>
                  <div className="form-controls">
                    <select
                      className="form-control no-icon"
                      onChange={onChangeClientCurrency}
                    >
                      {currencycodes.map((options) => (
                        <option key={options.id} value={options.value} >
                          {options.value}
                        </option>
                      ))}

                    </select>
                  </div>
                </div>
                </div>
                <div className="col-md-6" id="id_date">
                  <div className="form-group">
                    <label>Date*</label>
                    <div className="form-controls">
                      <ReactDatePickerHelper
                        datevalue={DepositDate}
                        onchangedate={onChangeDepositDate}
                      />
                      <span className="icon-calender _ico"></span>
                      <span className="icon-round icon-calender-mini"></span>
                      <span className={"help-block " + showDateErr}>
                        Date is required!
                      </span>
                    </div>

                  </div>
                </div>
                <div className="col-md-6" id="id_depositamount">
                  <div className="form-group">
                    <label>Deposit Amount*</label>
                    <div className="form-controls">
                      <Input
                        type="number"
                        className="form-control no-icon"
                        name="prefundedAmount"
                        onWheel={(e) => e.target.blur()}
                        onChange={onChangeDepositAmount}
                        onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                        validations={[deposiAmountRequired, required]}
                        value={DepositAmount}
                        maxLength="9"
                        onInput={maxLengthCheck}
                      />
                    </div>
                  </div>
                </div>

                {WireConfirmationUrl === "" ? (
                  <div className="col-md-6" id="id_fileupload">
                    <div className="form-group">
                      <label>Upload Wire Confirmation*</label>
                      <div className="form-controls">
                        <div className="bo-file-control">
                          <Input
                            className="form-control file-control-input"
                            type="file"
                            readOnly
                            onChange={onChangeLogo}
                          />
                          <h5 className="form-control file-control file-control-text">
                            <label>Drag here to upload or</label> Browse file
                          </h5>
                          {!isValidFileId && (
                            <span className="help-block has_error">
                              Invalid file format!
                            </span>
                          )}
                          <span className="icon-upload-file _ico">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </span>
                        </div>
                      </div>
                      <span className="fs-12 clr-submarine mt-2 d-block">
                        Supported file types JPG/PNG/PDF file.
                      </span>
                      <span className={"help-block " + showLogoErr}>
                        Upload wire confirmation is required!
                      </span>
                    </div>

                  </div>
                ) : (
                  <div className="col-md-6" id="id_fileupload" >
                    <div className="form-group">
                      <div className="bo-file d-flex">
                        <div className="file-name">
                          <span className="icon-upload-file _ico">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </span>
                          {/* <img src={fileuploadimg} alt="" /> */}
                          <h5>
                            <span>{LogoName}</span>
                          </h5>
                        </div>
                        <div className="ml-auto file-size" onClick={onCrossImg}>
                          <span>{LogoSize}</span>
                          <img
                            className="cross-icon"
                            src={crossimg}
                            onClick={onCrossImg}
                            alt=""
                          />
                        </div>
                      </div>
                      <span className={"help-block " + showLogoErr}>
                        Please upload valid wire confirmation!
                      </span>
                    </div>

                  </div>

                )}

                <div className="col-md-12">
                  <div className="form-group submit-field d-flex justify-content-center mt-40 mb-0">
                    <a
                      className="btn btn-outline mr-19"
                      data-bs-dismiss="modal"
                      onClick={props.onHide}
                    >
                      Cancel
                    </a>
                    <a className="btn btn-large" onClick={saveUser}>
                      Submit
                    </a>
                  </div>
                </div>
              </div>
              <CheckButton style={{ display: "none" }} ref={checkBtn} />
            </Form>
          </div>
        </div>
      </div>
      <DialoguePopup
        show={modalShow}
        onHide={() => setModalShow(false)}
        modelheading={modelhdng}
        modelbody={modelbdy}
        modelicon={MdlIcon}
      />
    </Fragment>
  );
};

export default DepositClientSubmit;
