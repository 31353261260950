import { useState, useRef, useEffect, Fragment } from "react";
import ModelPopup from "../../helpers/ModelPopup";
import { useSelector } from "react-redux";
import Input from "react-validation/build/input";
import BoLoader from "../../helpers/BoLoader";
import Form from "react-validation/build/form";
import DialoguePopup from "../../helpers/DialoguePopup";
import CheckButton from "react-validation/build/button";
import RefreshToken from "../../helpers/RefreshToken";
import transactionService from "../../services/transaction.service";
import CurrencyFormat from 'react-currency-format';
import GetAllSubClients from "../superclient/GetAllSubClients";
import IsEmptyObj from "../../helpers/IsEmpty";
const DebitCard = props => {
  const { user: currentUser } = useSelector(state => state.auth);

  const CheckNegativeAmount = value => {
    let obj = parseFloat(value);
    if (obj < 0) {
      document.getElementById("id_Amount").classList.add("has_error");
      return <span className="help-block">Amount cannot be negative!</span>;
    } else document.getElementById("id_Amount").classList.remove("has_error");
  };
  const CheckZeroAmount = value => {
    let obj = parseFloat(value);
    if (obj === 0) {
      document.getElementById("id_Amount").classList.add("has_error");
      return (
        <span className="help-block">Please enter amount greater than 0!</span>
      );
    } else document.getElementById("id_Amount").classList.remove("has_error");
  };
  const accountNumberRequired = value => {
    if (!value) {
      document.getElementById("id_AccountNumber").classList.add("has_error");
      return <span className="help-block">Token ID is required!</span>;
    } else
      document.getElementById("id_AccountNumber").classList.remove("has_error");
  };
  const AmountRequired = value => {
    if (!value) {
      document.getElementById("id_Amount").classList.add("has_error");
      return <span className="help-block">Amount is required!</span>;
    } else document.getElementById("id_Amount").classList.remove("has_error");
  };
  const descriptionRequired = value => {
    if (!value) {
      document.getElementById("id_desc").classList.add("has_error");
      return <span className="help-block">Amount is required!</span>;
    } else document.getElementById("id_desc").classList.remove("has_error");
  };
  const [boloader, setBoLoader] = useState(false);
  const [Amount, setAmount] = useState("");
  const [TokenId, setTokenId] = useState();
  const [UserId, setUserId] = useState();

  const [Description, setDescription] = useState();

  const [modalShow, setModalShow] = useState(false);
  //Dialogue Popup
  const [dialougeModalShow, setDialougeModalShow] = useState(false);
  const [modelhdng, setMdlHdng] = useState("");
  const [modelbdy, setMdlBdy] = useState("");
  const [MdlIcon, setMdlIcon] = useState("");
  //Modal Popups
  const [confirmModalShow, setconfirmModalShow] = useState(false);
  const [isSuperClient, setIsSuperClient] = useState(false);
  const [showClientErr, setShowClientErr] = useState("d-none");
  const [ClientId, setClientId] = useState(currentUser?.user?.ClientId ?? 0);

  const form = useRef();
  const checkBtn = useRef();
  useEffect(() => {
    if(currentUser?.user?.SuperClientId>0)
    setIsSuperClient(true);
  }, []);
  useEffect(() => {
    if (props.calledfrom === "cardholderdetails") {
      setTokenId(props.TokenID);
    }
  }, []);
  const maxLengthCheck = object => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
    let t = object.target.value;
    if(t.includes("."))
    object.target.value = (t?.indexOf(".") >= 0) ? (t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)) : t;
  };
  const onChangeAmount = e => {
    const Amount = e.target.value;
    if (Amount != "") {
      let value = parseFloat(Amount);
      setAmount(value);
    }
  };
  const onChangeDescription = e => {
    const desc = e.target.value;
    if (desc != "") {
      setDescription(desc);
    }
  };
  const onChangeAccountNumber = e => {
    const AccountNumber = e.target.value;
    setTokenId(String(AccountNumber));
  };
 
  const ClientNameChangeHandlers = async e => {
    setBoLoader(true);
    const clientName = e.target.selectedOptions[0].value;
    if(!IsEmptyObj.IsEmpty(clientName)){
    let val =parseInt(clientName);
    setClientId(val);
    //getSuperClientsDropDown(val);
    requiredClient(val);
    setBoLoader(false);
    }
  };
  const requiredClient = value => {
    if (!value) {
      document.getElementById("id_client").classList.add("has_error");
      setShowClientErr("");
      return false;
    } else {
      document.getElementById("id_client").classList.remove("has_error");
      setShowClientErr("d-none");
      return true;
    }
  };
  const renderDebitCard = () => {
    return (
      <Fragment>
        <div className="row">
          <BoLoader loading={boloader} />
          <div className="col-md-12">
            <h2>Debit Card</h2>
            <div className="mt-16">
              <div className="row">
                <div className="col-md-12">
                  <div className="tab-content" id="myTabContent">
                    <div>
                      <div>
                        <Form onSubmit={debitCardSave} ref={form}>
                          <div className="row mt-16">
                            {props?.calledfrom === "cardholderdetails" && (
                              <ul className="ul-card-details">
                                <li className="d-flex  mb-20">
                                  <label>Balance:</label>
                                  &nbsp;
                                  <span><CurrencyFormat value={props.Balance} displayType={'text'} thousandSeparator={true} /></span> &nbsp;
                                  {props.CurrencyCode}
                                </li>
                              </ul>
                            )}
                           
                            {isSuperClient ?(<div className="col-md-6" id="id_client">
                            <div className="form-group">
                              <label>Select Program</label>
                              <div className="form-controls">
                                <GetAllSubClients
                                  clientnamechangehandler={
                                    ClientNameChangeHandlers
                                  }
                                  searchgriddata="Y"
                                />
                                <span
                                  className={
                                    "help-block " + showClientErr
                                  }
                                >
                                  Client is required!
                                </span>
                              </div>
                            </div>
                          </div>):( <div className="col-md-6">
                          <div className="form-group">
                              <label>Client Ledger</label>
                              <div className="form-controls">
                                <input 
                                type="text"
                                value={currentUser.user?.ClientName}
                                disabled={true}
                                />
                              </div>
                            </div>
                            </div>)}
                           
                            <div className="col-md-6">
                              <div className="form-group" id="id_AccountNumber">
                                <label>Token ID*</label>
                                <div className="form-controls">
                                  <Input
                                    type="number"
                                    onWheel={(e) => e.target.blur()}
                                    className="form-control no-icon"
                                    name="cardholderName"
                                    maxLength="10"
                                    onInput={maxLengthCheck}
                                    value={TokenId}
                                    onChange={onChangeAccountNumber}
                                    validations={[accountNumberRequired]}
                                    //disable={disabled}
                                    disabled={true}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group" id="id_Amount">
                                <label>Amount*</label>
                                <div className="form-controls">
                                  <Input
                                    type="number"
                                    className="form-control no-icon"
                                    name="LastName"
                                    value={Amount}
                                    maxLength="9"
                                    onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                    onInput={maxLengthCheck}
                                    onWheel={(e) => e.target.blur()}
                                    onChange={onChangeAmount}
                                    validations={[
                                      AmountRequired,
                                      CheckNegativeAmount,
                                      CheckZeroAmount,
                                    ]}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group" id="id_desc">
                                <label>Description*</label>
                                <div className="form-controls">
                                  <Input
                                    type="text"
                                    className="form-control no-icon"
                                    name="LastName"
                                    maxLength="50"
                                    value={Description}
                                    onChange={onChangeDescription}
                                    validations={[descriptionRequired]}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group submit-field d-flex justify-content-end mt-20 mb-0">
                              <a
                                className="btn btn-outline mr-19"
                                data-bs-dismiss="modal"
                                onClick={() => {
                                  onShowModelClearFields();
                                }}
                              >
                                Cancel
                              </a>
                              <a
                                className="btn btn-large"
                                onClick={debitCardSave}
                              >
                                Done
                              </a>
                            </div>
                          </div>
                          <CheckButton
                            style={{ display: "none" }}
                            ref={checkBtn}
                          />
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };
  const debitCardSave = e => {
    if (e) e.preventDefault();
    let currencycode = props.CurrencyCode;
    form.current.validateAll();
    if (checkBtn.current.context._errors.length === 0 ) {
      setBoLoader(true);
      transactionService
        .debitCard(
          Amount,
          TokenId,
          currencycode,
          Number(ClientId),
          Description,
          props?.userId
        )
        .then(
          response => {
            setBoLoader(false);
            if (response && response.data && response.data.success) {
              setconfirmModalShow(false);
              setModalShow(true);
            }
          },
          error => {
            setBoLoader(false);
            setconfirmModalShow(false);
            setModalShow(false);
            let msgBody = error?.response?.data?.message
              ? error?.response?.data?.message
              : "Error Occured";
            let errIcon = "icon-error";
            errorAndSuccessModl("Error", msgBody, errIcon);
            (async () => {
              let resp = await RefreshToken(
                error && error.response && error.response.status
              );
              if (resp) {
                window.location.reload();
                setBoLoader(false);
              } else {
                setBoLoader(false);
              }
            })();
          }
        );
    }
  };
  const closeP2pModal = e => {
    e.preventDefault();
    setModalShow(false);
    setconfirmModalShow(false);
    setAmount("");
    props.onHide();
    if (props?.calledfrom === "cardholderdetails") window.location.reload();
  };
  const renderDetails = () => {
    return (
      <div className="row">
        <BoLoader loading={boloader} />
        <div className="col-md-12">
          <div class="icon-alert icon-alert-popup">
            <span class="icon-successful">
              <span className="path1"></span>
              <span className="path2"></span>
            </span>
          </div>
          <h2>Transfered</h2>
          <div className="mt-16">
            <div>
              <Form onSubmit={closeP2pModal}>
                <div className="row">
                  <div className="col-md-12">
                    <p>Amount has been debited successfully.</p>
                  </div>
                  <div className="col-md-12">
                    <ul className="ul-card-details-popup mt-20">
                      <li className="d-flex justify-content-between mb-16">
                        <label>Token ID</label>
                        <span>{TokenId}</span>
                      </li>
                      <li className="d-flex justify-content-between mb-16">
                        <label>Amount</label>
                        <span>{Amount}</span>
                      </li>
                    </ul>
                    <div class="form-group submit-field text-center mt-40">
                      <button class="btn btn-large" onclick={closeP2pModal}>
                        Done
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const errorAndSuccessModl = (heading, body, errIcon) => {
    setDialougeModalShow(true);
    setMdlHdng(heading);
    setMdlBdy(body);
    setMdlIcon(errIcon);
  };
  const onShowModelClearFields = () => {
    setAmount("");
    setDescription("");
    props.onHide();
  };
  return (
    <Fragment>
      <ModelPopup
        key={21}
        show={props.show}
        onHide={() => onShowModelClearFields()}
        modelheading={""}
        showheader={"false"}
        className="smallPopup issueCardSmall"
        dialogClassName="modal-dialog modal-dialog-centered mwidth-76"
        backdrop="static"
        keyboard={false}
        modelbody={renderDebitCard()}
      />
      <ModelPopup
        key={22}
        show={modalShow}
        onHide={() => setModalShow(false)}
        modelheading={""}
        modelbody={renderDetails()}
        showheader={"false"}
        dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
        className="smallPopup issueCardSmall"
        backdrop="static"
        keyboard={false}
      />
      <DialoguePopup
        show={dialougeModalShow}
        onHide={() => {
          setDialougeModalShow(false);
        }}
        modelheading={modelhdng}
        modelbody={modelbdy}
        modelicon={MdlIcon}
        backdrop="static"
        keyboard={false}
      />
    </Fragment>
  );
};

export default DebitCard;
