import React from "react";
import logo from "../assets/images/logo.svg";
import particlesmblicon from "../assets/images/particles-mobile-theme1.svg";
import imagetheme1 from "../assets/images/particles-web-theme1.svg";
import imagetheme2 from "../assets/images/particles-web-theme2.svg";
import imagetheme3 from "../assets/images/particles-web-theme3.svg";
import imagetheme4 from "../assets/images/particles-web-theme4.svg";
import imagetheme5 from "../assets/images/particles-web-theme5.svg";
import imagetheme6 from "../assets/images/particles-web-theme6.svg";
import imagetheme7 from "../assets/images/particles-web-theme7.svg";
import imagetheme8 from "../assets/images/particles-web-theme8.svg";
import imagetheme9 from "../assets/images/particles-web-theme9.svg";
import imagetheme from "../assets/images/particles-web-top-Theme-1.svg";
import { Link } from "react-router-dom";
var imagepath=imagetheme;
function BoPaymentWrapper(props) {
  const Themename=props.themename;
  if(Themename==="theme1")
  imagepath=imagetheme1;
  if(Themename==="theme2")
  imagepath=imagetheme2;
  if(Themename==="theme3")
  imagepath=imagetheme3;
  if(Themename==="theme4")
  imagepath=imagetheme4;
  if(Themename==="theme5")
  imagepath=imagetheme5;
  if(Themename==="theme6")
  imagepath=imagetheme6;
  if(Themename==="theme7")
  imagepath=imagetheme7;
  if(Themename==="theme8")
  imagepath=imagetheme8;
  if(Themename==="theme9")
  imagepath=imagetheme9;
 
  return (
    <div className="col-md-5 col-lg-4 onbo-sidebar">
      <img className="particles-mbl" style={{width: "100%"}} src={particlesmblicon} alt="prctice" />
      <div className="onbo-inn"  style={{backgroundImage:`url(${imagepath})`}}>
        <div className="logo d-flex align-items-center bo-thumb">
          {
            props.clientlogo ? (
              <img src={ props.clientlogo}  style={{width: "150%"}}alt="BO Payments" />
            )
            :(
              <Link to="#">
                <h3>
                  Control<span>Center</span>
                </h3>
              </Link>)
          }
         
        </div>
        <div className="bo-info mt-70">
        
            <h1>{props.pagename}</h1>
        
          <h4>{props.pagemsg}</h4>
        </div>
      </div>
    </div>
  );
}

export default BoPaymentWrapper;
