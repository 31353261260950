import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Fragment } from "react";
import cardHolderService from "../../services/cardholder.service";
// import receivingImg from "../../assets/images/icons/receiving.svg";
// import withdrawImg from "../../assets/images/icons/withdraw.svg";
// import trnsfrImg from "../../assets/images/icons/transfer.svg";
import DateFormat from "../../helpers/DateFormat";
import ModelPopup from "../../helpers/ModelPopup";
import BoLoader from "../../helpers/BoLoader";
import RefreshToken from "../../helpers/RefreshToken";
import SearchGridCardTransactions from "../cardholderCardScreens/SearchGridCardTransactions";
import LoadCard from "./LoadCard";
import { haspermissions } from "../../components/permissions/PermissionTypes";
import DebitCard from "./DebitCard";
import ExportToExcel from "../../helpers/ExportToExcel";
import GetClientsLoadDebit from "../../helpers/GetClientsLoadDebit";
import Form from "react-validation/build/form";
import CheckButton from "react-validation/build/button";
import Input from "react-validation/build/input";
import { Link } from "react-router-dom";
import DialoguePopup from "../../helpers/DialoguePopup";
import ReactFlagsSelect from "react-flags-select";
import { TrimCharacters } from "../../helpers/TrimCharacters";
import CurrencyFormat from 'react-currency-format';
import { isEmail } from "validator";
import moment from "moment";


let selectBoxVal = "";
const CardHolderDetails = props => {
  const descriptionRequired = value => {
    if (!value) {
      document.getElementById("id_desc").classList.add("has_error");
      return <span className="help-block">Description is required!</span>;
    } else document.getElementById("id_desc").classList.remove("has_error");
  };
  const Emailrequired = value => {
    if (!value) {
      document.getElementById("edit_email").classList.add("has_error");
      return <span className="help-block">Email is required!</span>;
    } else document.getElementById("edit_email").classList.remove("has_error");
  };


  const validEmail = value => {
    if (!isEmail(value)) {
      document.getElementById("edit_email").classList.add("has_error");
      return <span className="help-block">Invalid Email!</span>;
    } else document.getElementById("edit_email").classList.remove("has_error");
  };
  const { user: currentUser } = useSelector(state => state.auth);

  const [cardHolderDetail, setCardHolderDetail] = useState();
  const [cardHolderDetailSuccessful, setcardHolderDetailSuccessful] =
    useState(false);
  const [active, setactiveStatus] = useState(true);
  const [statusChangeConfirmBox, setStatusChangeConfirmBox] = useState(false);
  const [boloader, setBoLoader] = useState(false);
  const [userId, setUserId] = useState(props?.location?.state?.userId);
  const [cardDetails, setCardDetails] = useState();
  const [modalShowLoadCard, setmodalShowLoadCard] = useState(false);
  const [TokenId, setTokenId] = useState();
  const [CardFullNumber, setCardFullNumber] = useState();
  const [CardCvv, setCardCvv] = useState();
  const [CardExpiry, setCardExpiry] = useState();
  const [RefereshData, setRefereshData] = useState();
  const [modalShowDebitCard, setmodalShowDebitCard] = useState(false);
  const [modalShowKyc, setmodalShowKyc] = useState(false);
  const [dataset, setDataset] = useState();
  //Dialogue Popup
  const [modalShow, setModalShow] = useState(false);
  const [modelhdng, setMdlHdng] = useState("");
  const [modelbdy, setMdlBdy] = useState("");
  const [MdlIcon, setMdlIcon] = useState("");
  const [dialougemodalShow, setDialougemodalShow] = useState(false);
  //Suspend Unsuspend Card
  const [modaldeactivateshow, setModaldeactivateshow] = useState(false);
  const [modalsuccessfullShow, setModalsuccessfullShow] = useState(false);
  const [Status, setStatus] = useState("Unsuspended");
  const [showClientErr, setShowClientErr] = useState("d-none");
  const [Description, setDescription] = useState("Suspended Debit");
  const [ClientId, setClientId] = useState(currentUser.user.ClientId);
  const form = useRef();
  const checkBtn = useRef();
  //Edit Cardholder Details
  const [modalShowEditCard, setModalShowEditCard] = useState(false);
  const [FirstName, setFirstName] = useState();
  const [LastName, setLastName] = useState();
  const [Address, setAddress] = useState();
  const [City, setCity] = useState();
  const [Country, setCountry] = useState();
  const [ZipCode, setZipCode] = useState();
  const [FirstNameErr, setFirstNameErr] = useState("d-none");
  const [LastNameErr, setLastNameErr] = useState("d-none");
  const [AddressErr, setAddressErr] = useState("d-none");

  const [EmailEdit, setEmailEdit] = useState("");
  const [modalrefillamountshow, setModalrefillamountshow] = useState(false);
  const [isSuperClient, setIsSuperClient] = useState(false);
  const [IssuerCategory, setIssuerCategory] = useState();
  const requiredFirstName = value => {
    if (!value) {
      document.getElementById("edit_firstname").classList.add("has_error");
      setFirstNameErr("");
      return false;
    } else {
      document
        .getElementById("edit_firstname")
        .classList.remove("has_error");
      setFirstNameErr("d-none");
      return true;
    }
  }; const requiredLastName = value => {
    if (!value) {
      document.getElementById("edit_lastname").classList.add("has_error");
      setLastNameErr("");
      return false;
    } else {
      document
        .getElementById("edit_lastname")
        .classList.remove("has_error");
      setLastNameErr("d-none");
      return true;
    }
  }; const requiredAddress = value => {
    if (!value) {
      document.getElementById("edit_address").classList.add("has_error");
      setAddressErr("");
      return false;
    } else {
      document
        .getElementById("edit_address")
        .classList.remove("has_error");
      setAddressErr("d-none");
      return true;
    }
  };
  useEffect(() => {
    setBoLoader(true);
    setcardHolderDetailSuccessful(false);
    callHolderServiceCall();
    setcardHolderDetailSuccessful(false);
    getCardDetail();
    getCardCvv();
  }, [props.location.state]);
  if (currentUser) {
    if (currentUser?.role == "Client admin") {
      haspermissions.LoadCard = (currentUser?.user?.Permissions?.includes("Load Card"));
      haspermissions.SuspendCard = (currentUser?.user?.Permissions?.includes("Suspend Card"));
      haspermissions.ViewTransactionHistory = (currentUser?.user?.Permissions?.includes("View CardHolder Transactions"));
      haspermissions.ViewCardholderKYC = (currentUser?.user?.Permissions?.includes("View Cardholder KYC"));
      haspermissions.DebitCard = (currentUser?.user?.Permissions?.includes("Debit Card"));
      //UpdateCardHolder Permission
      haspermissions.UpdateCardholderInfo =
        currentUser?.user?.Permissions?.includes("Update Cardholder Info");
      //Corporate Client
      haspermissions.CorporateClient =
        currentUser?.user?.ClientPermissions?.includes("Corporate Client");
      haspermissions.ViewC2CHistory = (currentUser?.user?.Permissions?.includes("View CardHolder Transactions"));

    }
    else if (currentUser?.role == "Super client") {
      haspermissions.LoadCard = (currentUser?.user?.Permissions?.includes("Load Card"));
      haspermissions.SuspendCard = (currentUser?.user?.Permissions?.includes("Suspend Card"));
      haspermissions.ViewTransactionHistory = (currentUser?.user?.Permissions?.includes("View CardHolder Transactions"));
      haspermissions.ViewCardholderKYC = (currentUser?.user?.Permissions?.includes("View Cardholder KYC"));
      haspermissions.DebitCard = (currentUser?.user?.Permissions?.includes("Debit Card"));
      //UpdateCardHolder Permission
      haspermissions.UpdateCardholderInfo =
        currentUser?.user?.Permissions?.includes("Update Cardholder Info");
      //Corporate Client
      haspermissions.CorporateClient =
        currentUser?.user?.ClientPermissions?.includes("Corporate Client");
      haspermissions.ViewC2CHistory = (currentUser?.user?.Permissions?.includes("View CardHolder Transactions"));
    }
    else{

    }

  }
 

  const getCardCvv = async () => {
    await cardHolderService.getCardCvv(userId).then(
      (response) => {
        if (response && response.data && response.data.payload)
          setCardCvv(response?.data?.payload?.result);
      },
      (error) => {
      }
    );
  }
  const callHolderServiceCall = async () => {
    await cardHolderService
      .getcardholderDetail(props.location.state.id)
      .then(res => {
        if (res && res.data && res.data.success) {
          if (res && res.data && res.data.payload) {
            setCardHolderDetail(res.data.payload.result);
            setIssuerCategory(res?.data?.payload?.result?.issuerCategory)
            //setcardHolderDetailSuccessful(true);
            setUserId(res.data.payload.result.userId);
            // res.data.payload.cardHolderStatus === true
            //   ? setactiveStatus(true)
            //   : setactiveStatus(false);
          } else {
            setcardHolderDetailSuccessful(false);
          }
        }
        setBoLoader(false);
      })
      .catch(err => {
        setBoLoader(false);
        (async () => {
          let resp = await RefreshToken(
            err && err.response && err.response.status
          );
          if (resp) {
            window.location.reload();
            setBoLoader(false);
          } else {
            setBoLoader(false);
          }
        })();
        setcardHolderDetailSuccessful(false);
      });
  };
  const getCardDetail = async () => {
    await cardHolderService
      .getCardDetail(props.location.state.userId)
      .then(res => {
        if (res && res.data && res.data.success) {
          if (res && res.data && res.data.payload) {
            setCardDetails(res.data.payload.result);
            res.data.payload.result.isActive === true
              ? setactiveStatus(true)
              : setactiveStatus(false);
            res.data.payload.result.issuspended === true
              ? setStatus("Unsuspended")
              : setStatus("Suspended");
            setcardHolderDetailSuccessful(true);
            setTokenId(res.data.payload.result.tokenId);
            setCardFullNumber(res?.data?.payload?.result?.unMaskedCardNumber);
            setCardExpiry(moment(res?.data?.payload?.result?.expiryDate).format("MM/YY"));
          } else {
            setcardHolderDetailSuccessful(false);
          }
        }
        setBoLoader(false);
      })
      .catch(err => {
        setBoLoader(false);
        (async () => {
          let resp = await RefreshToken(
            err && err.response && err.response.status
          );
          if (resp) {
            window.location.reload();
            setBoLoader(false);
          } else {
            setBoLoader(false);
          }
        })();
        setcardHolderDetailSuccessful(false);
      });
  };
  const handleCardHolderStatusChange = e => {
    setBoLoader(true);
    cardHolderService
      .updateCardHolderStatus(userId, active)
      .then(res => {
        setBoLoader(false);
        if (res && res.data && res.data.success) {
          setactiveStatus(selectBoxVal);
          setStatusChangeConfirmBox(false);
        }
      })
      .catch(() => {
        setBoLoader(true);
      });
  };
  const showCardStatusConfirmPopUp = e => {
    setactiveStatus(e.target.value);
    selectBoxVal = e.target.value;
    setStatusChangeConfirmBox(true);
  };
  const LoadCardPopupHandler = () => {
    setmodalShowLoadCard(true);
  };
  const kycCardPopupHandler = () => {
    setmodalShowKyc(true);
  };
  const debitCardPopupHandler = () => {
    setmodalShowDebitCard(true);
  };

  const onSuspendCard = (e) => {
    if (e) e.preventDefault();
    let OperatorUserId = userId;
    let UserIdCard = userId
    let IsBlock = true;
    let CardCurrency = cardDetails?.cardFeeCurrencyCode;
    let clientid = 2;
    if (cardDetails.cardTypeName === "GPR_VIR")
      clientid = 36;
    //let client = requiredClient(ClientId);
    form.current.validateAll();
    if (checkBtn.current.context._errors.length === 0) {
      setBoLoader(true);
      cardHolderService
        .suspendCard(TokenId, OperatorUserId, UserIdCard, IsBlock, clientid, Description, CardCurrency)
        .then((res) => {
          setBoLoader(false);
          if (res && res.data && res.data.payload) {
            onShowModelClearFields();
            setModalsuccessfullShow(true);
          }
        })
        .catch((error) => {
          setBoLoader(false);
          setModaldeactivateshow(false);
          let msgBody = error?.response?.data?.message
            ? error?.response?.data?.message

            : "Error Occured";
          let errIcon = "icon-error";
          errorAndSuccessModl("Error", msgBody, errIcon);
        });
    }
  };
  const errorAndSuccessModl = (heading, body, errIcon) => {
    setDialougemodalShow(true);
    setMdlHdng(heading);
    setMdlBdy(body);
    setMdlIcon(errIcon);
  };
  const onUnsuspendCard = (e) => {
    if (e) e.preventDefault();
    let OperatorUserId = userId;
    let UserIdCard = userId
    let IsBlock = false;
    let CardCurrency = cardDetails?.cardFeeCurrencyCode;
    ///let client = requiredClient(ClientId);
    form.current.validateAll();
    if (checkBtn.current.context._errors.length === 0) {
      setBoLoader(true);
      cardHolderService
        .unSuspendCard(TokenId, OperatorUserId, UserIdCard, IsBlock, ClientId, Description, CardCurrency)
        .then((res) => {
          setBoLoader(false);
          if (res && res.data && res.data.payload) {
            onShowModelClearFields();

            setModalsuccessfullShow(true);
          }
        })
        .catch((error) => {
          setBoLoader(false);
          setModaldeactivateshow(false);
          let msgBody = error?.response?.data?.message
            ? error?.response?.data?.message

            : "Error Occured";
          let errIcon = "icon-error";
          errorAndSuccessModl("Error", msgBody, errIcon);
        });
    }
  };
  const openModal = () => {
    setModaldeactivateshow(true);
  };
  const ClientNameChangeHandler = e => {
    const clientName = e.target.selectedOptions[0].value;
    if (clientName) {
      let clientid = parseInt(clientName);
      setClientId(clientid);
      //requiredClient(clientid);
    }
  };
  const onShowModelClearFields = () => {
    setModaldeactivateshow(false);
    setDescription("");

    setShowClientErr("d-none");
  };
  const onChangeDescription = e => {
    const desc = e.target.value;
    if (desc != "") {
      setDescription(desc);
    }
  };
  const onCancelActiveInactive = () => {
    setBoLoader(true);
    cardHolderService.getCardDetail(props.location.state.userId).then((res) => {
      setBoLoader(false);
      if (res) {
        res.data.payload.result.isActive === true
          ? setactiveStatus(true)
          : setactiveStatus(false);
      }
    }).catch((err) => {
      setBoLoader(false);
      (async () => {
        let resp = await RefreshToken(
          err && err.response && err.response.status
        );
        if (resp) {
          window.location.reload();
          setBoLoader(false);
        } else {
          setBoLoader(false);
        }
      })();
    })
  }
  const EditCardPopupHandler = () => {
    setFirstName(cardHolderDetail?.firstName);
    setLastName(cardHolderDetail?.lastName);
    setAddress(cardHolderDetail?.address);
    setCity(cardHolderDetail?.city);
    setCountry(cardHolderDetail?.country);
    setZipCode(cardHolderDetail?.zipCode);
    setEmailEdit(cardHolderDetail?.email)
    setModalShowEditCard(true);
  };
  const onEditCardDetails = () => {
    let chckfirstname = requiredFirstName(FirstName);
    let chcklastname = requiredLastName(LastName);
    let chckaddress = requiredAddress(Address);
    if (chckfirstname && chcklastname && chckaddress) {
      setBoLoader(true);
      cardHolderService.onEditCard(FirstName, LastName, Address, City, Country, ZipCode, userId, EmailEdit).then((response) => {
        setBoLoader(false);
        if (response)
          setModalShowEditCard(false);
        let msgBody = response.data.message
        let errIcon = "icon-success";
        errorAndSuccessModl("Success", msgBody, errIcon);
      }).catch((error) => {
        setBoLoader(false);
        let msgBody = error?.response?.data?.message
          ? error?.response?.data?.message

          : "Error Occured";
        let errIcon = "icon-error";
        errorAndSuccessModl("Error", msgBody, errIcon);
      });
    }
  }
  const onCancelEditCards = () => {
    setFirstName(cardHolderDetail?.firstName);
    setLastName(cardHolderDetail?.lastName);
    setAddress(cardHolderDetail?.address);
    setCity(cardHolderDetail?.city);
    setCountry(cardHolderDetail?.country);
    setZipCode(cardHolderDetail?.zipCode);
    setEmailEdit(cardHolderDetail?.email)
    setLastNameErr("d-none");
    setFirstNameErr("d-none");
    setAddressErr("d-none");
  }
  const onChangeFirstName = e => {
    let value = e.target.value;
    value = value.replace(/[^a-z0-9 ]/gi, '')
    setFirstName(value);
    requiredFirstName(value)
  };
  const onChangeLastName = e => {
    let value = e.target.value;
    value = value.replace(/[^a-z0-9 ]/gi, '')
    setLastName(value);
    requiredLastName(value);
  };
  const onChangeAddress = e => {
    const name = e.target.value;
    setAddress(name);
    requiredAddress(name);
  };
  const onChangeCity = e => {
    let value = e.target.value;
    value = value.replace(/[^A-Za-z ]/ig, '')
    setCity(value);
  };
  const onChangeCountry = e => {
    const name = e.target.value;
    setCountry(name);
  };
  const onChangeZipCode = e => {
    let value = e.target.value;
    value = value.replace(/[^a-z0-9 ]/gi, '')
    setZipCode(value);
  };
  const refereshPage = () => {
    setBoLoader(true);
    callHolderServiceCall();
    getCardDetail();
  }

  const onChangeEmail = e => {
    const email = e.target.value;
    setEmailEdit(email);
  };
  const refillMainWallet = () => {
    setBoLoader(true);
    cardHolderService.refillbalance(props?.location?.state?.userId).then((resp) => {
      if (resp) {
        setBoLoader(false);
        let msgBody = resp?.response?.data?.message
          ? resp?.response?.data?.message
          : "Account Refilled SuccessFully";
        let errIcon = "icon-success";
        errorAndSuccessModl("Success", msgBody, errIcon);
      }


    }).catch((error) => {
      setBoLoader(false);
      let msgBody = error?.response?.data?.message
        ? error?.response?.data?.message
        : "Error Occured";
      let errIcon = "icon-error";
      errorAndSuccessModl("Error", msgBody, errIcon);
    });
  }
  const onHideRefillPopUpHandler = () => {
    setModalrefillamountshow(false)
  }
  return (
    <div className="col-xl-9 col-lg-12 bo-main-content">
      <BoLoader loading={boloader} />
      {cardHolderDetailSuccessful && (
        <Fragment>
          <div className="row align-items-center">
            <div className="col-lg-2 mb-24">
              <h3>Card Holder Detail</h3>
            </div>

            <div className="col-lg-10 mb-24">
              <div
                className="form-group submit-field d-md-flex justify-content-end btns-four"

              >
                {
                  currentUser?.role == "Client admin" && (<>
                    {haspermissions.ViewCardholderKYC &&(<button
                      className="btn btn-large btn-extra-large mr-19 mb-3 mb-md-0"
                      onClick={kycCardPopupHandler}
                    >
                      KYC Documents
                    </button>)}</>

                  )
                }
                {
                  currentUser?.role == "Super client" && (<>
                    {haspermissions.ViewCardholderKYC &&(<button
                      className="btn btn-large btn-extra-large mr-19 mb-3 mb-md-0"
                      onClick={kycCardPopupHandler}
                    >
                      KYC Documents
                    </button>)}</>

                  )
                }

                {
                  (currentUser?.role == "Client admin" || currentUser?.role == "Super client") && (
                    <>
                      {

                        haspermissions.DebitCard && (
                          <button
                            className="btn btn-large btn-extra-large mr-19 mb-3 mb-md-0"
                            onClick={debitCardPopupHandler}
                          >
                            Debit Card
                          </button>
                        )

                      }
                    </>
                  )
                }


                {
                  Status !== "Unsuspended" && (
                    <>
                      {

                        haspermissions.LoadCard && (<button className="btn btn-large btn-extra-large mr-19 mb-3 mb-md-0" onClick={LoadCardPopupHandler}>
                          Load Card
                        </button>
                        )}
                    </>)}

                {
                    haspermissions.SuspendCard && (
                      <Fragment>
                        {Status === "Suspended" && (
                          <button
                            className="btn btn-large btn-extra-large mr-19 mb-3 mb-md-0"
                            onClick={openModal}
                          >
                            Suspend Card
                          </button>)}
                        
                      </Fragment>
                    )
                }
              </div>
            </div>

          </div>

          <div className="row-card-holder-details mb-40">
            <div className="row flex-column-reverse flex-sm-row">
              <div className="col-md-9 col-sm-8">
                <ul className="ul-card-details">
                  <li>
                    <div className="mb-20">
                      <p>First Name</p>
                      <h5>{cardHolderDetail?.firstName}</h5>
                    </div>
                    <div className="mb-20">
                      <p>Date of Issue</p>
                      <h5>
                        <DateFormat date={cardDetails?.issueDate} />
                      </h5>
                    </div>
                    <div className="mb-20">
                      <p>Email</p>
                      <h5 className="text-break"> {cardHolderDetail?.email}</h5>
                    </div>
                    <div className="mb-20">
                      <p>Currency</p>
                      <h5>
                        {cardDetails.cardFeeCurrencyCode !== ""
                          ? cardDetails.cardFeeCurrencyCode
                          : "$"}
                      </h5>
                    </div>
                    {
                      haspermissions.CorporateClient ? (
                        <div className="mb-20">
                          <p>Un Masked Card Number</p>
                          <h5>{CardFullNumber}</h5>
                        </div>
                      ) : (<div className="mb-20">
                        <p>Card Number</p>
                        <h5>{cardDetails.maskedCardNumber !== null ? cardDetails.maskedCardNumber : "#00000"}</h5>
                      </div>)
                    }

                    {haspermissions.CorporateClient ? (
                      <div className="mb-20">
                        <p>CVV</p>
                        <h5>{CardCvv}</h5>
                      </div>
                    ) : ("")}

                  </li>
                  <li>
                    <div className="mb-20">
                      <p>Last Name</p>
                      <h5>{cardHolderDetail?.lastName}</h5>
                    </div>
                    {
                      currentUser?.user?.IssuerName !== "IssuerG" ? (<div className="mb-20">
                        <p>Date of Expiry</p>
                        <h5>
                          <DateFormat date={cardDetails.expiryDate} />
                        </h5>
                      </div>) : (
                        <div className="mb-20">
                          <p>City</p>
                          <h5> {cardHolderDetail?.city}</h5>
                        </div>
                      )
                    }
                    <div className="mb-20">
                      <p>Phone no</p>
                      <h5> {cardHolderDetail?.phoneNumber}</h5>
                    </div>
                    <div className="mb-20">
                      <p>Balance</p>
                      <h5> <CurrencyFormat value={cardHolderDetail?.availableBalance} displayType={'text'} thousandSeparator={true} /></h5>
                    </div>
                    <div className="mb-20">
                      <p>Token ID</p>
                      <h5>{TokenId !== null ? TokenId : "#00000"}</h5>
                    </div>


                  </li>
                  <li>
                    <div className="mb-20">
                      <p>Type of Card</p>
                      <h5>
                        {" "}
                        {cardDetails.cardTypeName === "GPR_VIR"
                          ? "VIRTUAL"
                          : "PHYSICAL"}
                      </h5>
                    </div>
                    <div className="mb-20">
                      <p>Address</p>
                      <h5> {cardHolderDetail?.address}</h5>
                    </div>
                    <div className="mb-20">
                      <p>City</p>
                      <h5> {cardHolderDetail?.city}</h5>
                    </div>
                    <div className="mb-20">
                      <p>Country</p>
                      <h5> {cardHolderDetail?.country}</h5>
                    </div>
                    <div className="mb-20">
                      <p>Zip Code</p>
                      <h5> {cardHolderDetail?.zipCode}</h5>
                    </div>
                    <div className="mb-20">
                      <p>Nationality</p>
                      <h5> {cardDetails.nationality}</h5>
                    </div>
                    {
                      currentUser?.user?.IssuerName === "IssuerB4B" && (
                        <div className="mb-20">
                          <p>Pending Balance</p>
                          <h5> <CurrencyFormat value={cardHolderDetail?.pendingBalance} displayType={'text'} thousandSeparator={true} /></h5>
                        </div>
                      )
                    }
                    {
                      IssuerCategory==="IssuerB4B" &&( <div className="mb-20">
                      <p>Pending Balance</p>
                      <h5> <CurrencyFormat value={cardHolderDetail?.pendingBalance} displayType={'text'} thousandSeparator={true} /></h5>
                    </div>)
                    }


                    {
                      currentUser?.user?.IssuerName === "IssuerG" && (
                        <div className="mb-20">
                          <p>Card Color</p>
                          <h5>{cardHolderDetail?.cardColor ? cardHolderDetail?.cardColor == "Silver" ? "Black" : cardHolderDetail?.cardColor : "-"}</h5>
                        </div>
                      )
                    }
                  </li>
                </ul>
              </div>
              <div className="col-md-3 col-sm-4">
                <div className="row">
                  <div className="col-6 col-sm-12">
                    <form onSubmit={e => e.preventDefault()}>
                      <div className="form-group">
                        <div className="form-controls form-card-state">
                          {Status !== "Unsuspended" && (
                            <>
                              <select
                                className="form-control"
                                value={active}
                                onChange={showCardStatusConfirmPopUp}
                              >
                                <option value="true">Active</option>
                                <option value="false">Inactive</option>
                              </select>
                            </>)}

                        </div>
                      </div>
                      <ModelPopup
                        key={2}
                        show={statusChangeConfirmBox}
                        onHide={() => { setStatusChangeConfirmBox(false); onCancelActiveInactive(); }}
                        modelheading={""}
                        modelbody={[
                          <div className="row">
                            <div className="col-md-12">
                              <h2>Confirmation</h2>
                              <div className="mt-16">
                                <div>
                                  <form onSubmit={e => e.preventDefault()}>
                                    <div className="row">
                                      <div className="col-md-12">
                                        <p>
                                          Are you sure you want to change the status
                                          from
                                          {selectBoxVal === "true"
                                            ? " In Active to Active"
                                            : " Active to In Active"}
                                          {/* to
                                      {selectBoxVal
                                        ? " Active "
                                        : " In Active "} */}
                                        </p>
                                      </div>
                                      <div className="col-md-12">
                                        <div className="form-group submit-field d-flex justify-content-between mt-20 mb-0">
                                          <button
                                            className="btn btn-outline"
                                            onClick={() => {
                                              setStatusChangeConfirmBox(false);
                                              //callHolderServiceCall();
                                              onCancelActiveInactive();
                                            }}
                                          >
                                            Cancel
                                          </button>
                                          <button
                                            className="btn btn-large"
                                            onClick={handleCardHolderStatusChange}
                                          >
                                            Confirm
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>,
                        ]}
                        showheader={"false"}
                        className="smallPopup issueCardSmall"
                        dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
                      />
                    </form>
                  </div>
                  {Status !== "Unsuspended" && (
                    <>
                      {
                        haspermissions.UpdateCardholderInfo && (
                          <div className="col-6 col-sm-12">
                            <button
                              className="btn btn-large btn-extra-large float-end btn-edit"
                              onClick={EditCardPopupHandler}
                            >
                              Edit Card
                            </button>
                          </div>
                        )
                      }
                      {
                        currentUser?.user?.IssuerName === "IssuerB4B" && (
                          <div className="col-6 col-sm-12 mt-16">
                            <div>
                              <button
                                className="btn btn-large btn-extra-large float-end btn-edit"
                                onClick={() => setModalrefillamountshow(true)}
                                disabled={cardHolderDetail?.pendingBalance == 0 || cardHolderDetail?.availableBalance == 5000}
                              >
                                Top Up Balance
                              </button>
                            </div>


                          </div>
                        )
                      }
                    </>)}

                </div>
              </div>
            </div>
          </div>

          {
            haspermissions.ViewC2CHistory && (
              <Fragment>
                <div className="row align-items-center">
                  <div className="col-sm-6">
                    <h3>Transactions</h3>
                  </div>
                  <div className="col-sm-6 toRight">
                    <ExportToExcel
                      Dataset={dataset}
                      Name="CardsTransaction"
                      calledfrom="cardtransaction"
                    />
                  </div>
                </div>
               {
                IssuerCategory &&(<SearchGridCardTransactions
                  placeholdervalue="Search Card Holder Transactions"
                  headersdata={[
                    "MERCHANT NAME",
                    "STATUS",
                    // "CURRENCY",
                    "AMOUNT",
                    "DATE",
                    // "REF #",
                    "VIEW",
                  ]}
                  screenname="cardholdertransactions"
                  UserId={props.location.state.userId}
                  setDataset={setDataset}
                  IssuerCategory={IssuerCategory}
                />)
               } 
              </Fragment>
            )
          }

          {TokenId && (
            <LoadCard
              show={modalShowLoadCard}
              onHide={() => setmodalShowLoadCard(false)}
              calledfrom="cardholderdetails"
              TokenID={TokenId}
              CurrencyCode={cardDetails?.cardFeeCurrencyCode}
              Balance={cardHolderDetail?.availableBalance}

            />
          )}
        </Fragment>
      )}
      {TokenId && (
        <DebitCard
          show={modalShowDebitCard}
          onHide={() => setmodalShowDebitCard(false)}
          calledfrom="cardholderdetails"
          TokenID={TokenId}
          CurrencyCode={cardDetails?.cardFeeCurrencyCode}
          Balance={cardHolderDetail?.availableBalance}
          userId={userId}
        />
      )}
      <ModelPopup
        show={modalShowKyc}
        key={12}
        onHide={() => setmodalShowKyc(false)}
        modelheading={""}
        showheader={"false"}
        className="smallPopup issueCardSmall"
        dialogClassName="modal-dialog modal-dialog-centered mwidth-76"
        modelbody={[
          <Fragment>
            <form onSubmit={e => e.preventDefault()}>
              <div className="row">
                <div className="col-md-12">
                  <h2>KYC Card Detail</h2>
                  <ul className="ul-card-details-popup mt-16 ul-issue-card">
                    <li className="mb-16">
                      <div className="row">
                        <div className="col-4">
                          <label>Name</label>
                        </div>
                        <div className="col-8">
                          <span>{cardHolderDetail?.cardHolderName}</span>
                        </div>
                      </div>
                    </li>
                    <li className="mb-16">
                      <div className="row">
                        <div className="col-4">
                          <label>Token ID</label>
                        </div>
                        <div className="col-8">
                          <span>{cardDetails?.tokenId}</span>
                        </div>
                      </div>
                    </li>
                    <li className="mb-16">
                      <div className="row">
                        <div className="col-4">
                          <label>Company Name</label>
                        </div>
                        <div className="col-8">
                          <span>{cardHolderDetail?.companyName}</span>
                        </div>
                      </div>
                    </li>
                    <li className="mb-16">
                      <div className="row">
                        <div className="col-4">
                          <label>Card Expiry Date</label>
                        </div>
                        <div className="col-8">
                          <span>
                            <DateFormat date={cardDetails?.expiryDate} />
                          </span>
                        </div>
                      </div>
                    </li>
                    <li className="mb-32">
                      <div className="row">
                        <div className="col-4">
                          <label>Status</label>
                        </div>
                        <div className="col-8">
                          {/* <span>{cardHolderDetail.cardHolderStatus}</span> */}
                          <span>Approved</span>
                        </div>
                      </div>
                    </li>
                    {cardHolderDetail?.idType && (
                      <Fragment><li className="mb-24">
                        <div className="row">
                          <div className="col-4">
                            <label>ID Type</label>
                          </div>
                          <div className="col-8">
                            <span>{cardHolderDetail?.idType}</span>
                          </div>
                        </div>
                      </li>
                        <li className="mb-40">
                          <div className="text-center id-doc">
                            <a
                              href={cardHolderDetail?.filePathIdType}
                              id="downloadidtype"
                            >
                              <img
                                src={cardHolderDetail?.filePathIdType}
                                alt="id document"
                              />
                            </a>
                            <div>
                              <a
                                href={cardHolderDetail?.filePathIdType}
                                style={{ textDecoration: `underline`, color: `blue` }}>
                                Download image
                              </a>
                            </div>
                          </div>
                        </li>
                      </Fragment>
                    )}
                    {
                      cardHolderDetail?.addressProof && (
                        <Fragment>
                          <li className="mb-24">
                            <div className="row">
                              <div className="col-4">
                                <label>Proof of Address</label>
                              </div>
                              <div className="col-8">
                                <span>{cardHolderDetail?.addressProof}</span>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="text-center add-paper">
                              <a
                                href={cardHolderDetail?.filePathAddressProof}
                                id="downloadaddrproof"
                              >
                                <img
                                  src={cardHolderDetail?.filePathAddressProof}
                                  alt="house dodument"
                                />
                              </a>
                              <div>
                                <a href={cardHolderDetail?.filePathAddressProof}
                                  style={{ textDecoration: `underline`, color: `blue` }}>
                                  Download image
                                </a>
                              </div>
                            </div>
                          </li>
                        </Fragment>
                      )
                    }

                  </ul>
                  <div className="form-group submit-field d-flex justify-content-end mt-30 mb-0">
                    <button
                      className="btn btn-large"
                      onClick={() => {
                        setmodalShowKyc(false);
                        //setShowAccptCnfrmPopup(true);
                      }}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </form>

            {/* {noDataFound && <p>No details found for selected card holder</p>} */}
          </Fragment>,
        ]}
      />
      <ModelPopup
        key={45}
        show={modaldeactivateshow}
        onHide={() => setModaldeactivateshow(false)}
        modelheading={""}
        showheader={"false"}
        className="smallPopup issueCardSmall"
        dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
        modelbody={[
          <Fragment>
            {Status === "Suspended" ? (
              <Fragment>
                <div className="row">
                  <BoLoader loading={boloader} />
                  <div className="col-md-12">
                    <h2> Suspend Card</h2>
                    <div className="mt-16">
                      <div className="row">
                        <div className="col-md-12">
                          <p>Do you want to Suspend Card?</p>
                        </div>
                        <div className="col-md-12">
                          <div className="tab-content" id="myTabContent">
                            <div>
                              <div>
                                <Form onSubmit={onSuspendCard} ref={form}>
                                  <div className="row">

                                    {/* <ul className="ul-card-details">
                                            <li className="d-flex  mb-20">
                                              <label>Balance:</label>
                                              &nbsp;
                                              <span>{cardHolderDetail?.availableBalance}</span> &nbsp;
                                              {cardDetails?.cardFeeCurrencyCode}
                                            </li>
                                            <li className="d-flex  mb-20">
                                              <label>Client Name:</label>
                                              &nbsp;
                                              <span>{cardHolderDetail?.companyName}</span> &nbsp;
                                             
                                            </li>
                                          </ul> */}

                                    {/* <div className="col-md-6">
                                          <div className="form-group" id="id_client">
                                            <label>Select Client Ledger</label>
                                            <div className="form-controls">
                                              <GetClientsLoadDebit
                                                clientnamechangehandler={
                                                  ClientNameChangeHandler
                                                }
                                              />
                                              <span
                                                className={
                                                  "help-block " + showClientErr
                                                }
                                              >
                                                Client is required!
                                              </span>
                                            </div>
                                          </div>
                                        </div> */}
                                    {/* <div className="col-md-12">
                                          <div className="form-group" id="id_desc">
                                            <label>Description</label>
                                            <div className="form-controls">
                                              <Input
                                                type="text"
                                                className="form-control no-icon"
                                                name="LastName"
                                                value={Description}
                                                onChange={onChangeDescription}
                                                validations={[descriptionRequired]}
                                              />
                                            </div>
                                          </div>
                                        </div> */}
                                  </div>
                                  <div className="col-md-12">
                                    <div className="form-group submit-field d-flex justify-content-end mt-20 mb-0">
                                      <a
                                        className="btn btn-outline mr-19"
                                        data-bs-dismiss="modal"
                                        onClick={() => {
                                          onShowModelClearFields();
                                        }}
                                      >
                                        No
                                      </a>
                                      <a
                                        className="btn btn-large"
                                        onClick={onSuspendCard}
                                      >
                                        Yes
                                      </a>
                                    </div>
                                  </div>
                                  <CheckButton
                                    style={{ display: "none" }}
                                    ref={checkBtn}
                                  />
                                </Form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Fragment>
            ) : (
              <Fragment>
                <div className="row">
                  <BoLoader loading={boloader} />
                  <div className="col-md-12">
                    <h2>Unsuspend Card</h2>
                    <div className="mt-16">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="tab-content" id="myTabContent">
                            <div>
                              <div>
                                <Form onSubmit={onUnsuspendCard} ref={form}>
                                  <div className="row mt-16">

                                    <ul className="ul-card-details">
                                      <li className="d-flex  mb-20">
                                        <label>Balance:</label>
                                        &nbsp;
                                        <span>{cardHolderDetail?.availableBalance}</span> &nbsp;
                                        {cardDetails?.cardFeeCurrencyCode}
                                      </li>
                                      <li className="d-flex  mb-20">
                                        <label>Client Name:</label>
                                        &nbsp;
                                        <span>{cardHolderDetail?.companyName}</span> &nbsp;

                                      </li>
                                    </ul>

                                    {/* <div className="col-md-6">
                                            <div className="form-group" id="id_client">
                                              <label>Select Client Ledger</label>
                                              <div className="form-controls">
                                                <GetClientsLoadDebit
                                                  clientnamechangehandler={
                                                    ClientNameChangeHandler
                                                  }
                                                />
                                                <span
                                                  className={
                                                    "help-block " + showClientErr
                                                  }
                                                >
                                                  Client is required!
                                                </span>
                                              </div>
                                            </div>
                                          </div> */}
                                    <div className="col-md-12">
                                      <div className="form-group" id="id_desc">
                                        <label>Description</label>
                                        <div className="form-controls">
                                          <Input
                                            type="text"
                                            className="form-control no-icon"
                                            name="LastName"
                                            value={Description}
                                            onChange={onChangeDescription}
                                            validations={[descriptionRequired]}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="form-group submit-field d-flex justify-content-end mt-20 mb-0">
                                      <a
                                        className="btn btn-outline mr-19"
                                        data-bs-dismiss="modal"
                                        onClick={() => {
                                          onShowModelClearFields();
                                        }}
                                      >
                                        Cancel
                                      </a>
                                      <a
                                        className="btn btn-large"
                                        onClick={onUnsuspendCard}
                                      >
                                        Done
                                      </a>
                                    </div>
                                  </div>
                                  <CheckButton
                                    style={{ display: "none" }}
                                    ref={checkBtn}
                                  />
                                </Form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Fragment>

            )}

          </Fragment>,
        ]}
      />
      <ModelPopup
        key={1}
        show={modalsuccessfullShow}
        onHide={() => { setModalsuccessfullShow(false); document.getElementById("clientNavLink").click(); }}
        modelheading={""}
        showheader={"false"}
        className="smallPopup issueCardSmall"
        dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
        backdrop="static"
        keyboard={false}
        modelbody={[
          <Fragment>
            <div className="row">
              <div className="col-md-12">
                <div className="icon-alert icon-alert-popup">
                  <span className="icon-successful">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </span>
                </div>
                <h2>Successful</h2>
                <div className="mt-16">
                  <div>
                    <form>
                      <div className="row">
                        <div className="col-md-12">
                          {Status === "Suspended" ? (
                            <p>Card Suspended Successfully</p>
                          ) : (
                            <p>Card Unsuspended Successfully</p>
                          )}
                        </div>
                        <div className="col-md-12">
                          <div className="form-group submit-field mt-40 mb-0">
                            <Link
                              to="#"
                              className="btn btn-large w-100"
                              onClick={() => {
                                setModalsuccessfullShow(false);
                                document.getElementById("dashboardNavLink").click();
                              }}
                            >
                              Go To Cards
                            </Link>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>,
        ]}
      />
      <ModelPopup
        show={modalShowEditCard}
        key={15}
        onHide={() => { setModalShowEditCard(false); onCancelEditCards(); }}
        modelheading={""}
        showheader={"false"}
        className="smallPopup issueCardSmall"
        dialogClassName="modal-dialog modal-dialog-centered mwidth-76"
        backdrop="static"
        keyboard={false}
        modelbody={[
          <Fragment>
            <form onSubmit={e => e.preventDefault()}>
              <h2>Edit CardHolder Details</h2>
              <div className="row mt-16">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group" id="edit_firstname">
                        <label>First Name*</label>
                        <div className="form-controls">
                          <input
                            type="text"
                            className="form-control  no-icon"
                            name="cardholderName"
                            value={FirstName}
                            onChange={onChangeFirstName}
                            onKeyDown={(evt) => {
                              let chck = TrimCharacters.checkSpecialCharacters(evt.key)
                              if (chck)
                                evt.preventDefault();
                            }
                            }
                            // validations={[firstNameRequired]}
                            maxLength="20"
                          />

                        </div>
                        <span
                          className={
                            "help-block " + FirstNameErr
                          }
                        >
                          First Name is required!
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group" id="edit_lastname">
                        <label>Last Name*</label>
                        <div className="form-controls">
                          <input
                            type="text"
                            className="form-control  no-icon"
                            name="cardholderName"
                            value={LastName}
                            onChange={onChangeLastName}
                            onKeyDown={(evt) => {
                              let chck = TrimCharacters.checkSpecialCharacters(evt.key)
                              if (chck)
                                evt.preventDefault();
                            }
                            }
                            maxLength="20"
                          />

                        </div>
                        <span
                          className={
                            "help-block " + LastNameErr
                          }
                        >
                          Last Name is required!
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group" id="edit_address">
                        <label>Address*</label>
                        <div className="form-controls">
                          <input
                            type="text"
                            className="form-control  no-icon"
                            name="cardholderName"
                            value={Address}
                            onChange={onChangeAddress}
                            maxLength="40"
                          />

                        </div>
                        <span
                          className={
                            "help-block " + AddressErr
                          }
                        >
                          Address is required!
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group" id="edit_city">
                        <label>City</label>
                        <div className="form-controls">
                          <input
                            type="text"
                            className="form-control  no-icon"
                            name="cardholderName"
                            value={City}
                            onChange={onChangeCity}
                            onKeyDown={(evt) => {
                              let chck = TrimCharacters.checkAlpha(evt.key)
                              if (chck)
                                evt.preventDefault();
                            }
                            }
                            maxLength="20"
                          />

                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group" id="id_deliverycountry">
                        <label>Country*</label>
                        <div className="form-controls">
                          <ReactFlagsSelect
                            className="form-control no-icon"
                            selected={Country}
                            onSelect={code => {
                              setCountry(code);
                            }}
                            searchable={true}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group" id="edit_zipcode">
                        {
                          currentUser?.user?.IssuerName=="IssuerManigo" ?( <label>Postal Code</label>):( <label>Zip Code</label>)
                        }
                        <div className="form-controls">
                          <input
                            type="text"
                            className="form-control  no-icon"
                            name="cardholderName"
                            value={ZipCode}
                            onChange={onChangeZipCode}
                            onKeyDown={(evt) => {
                              let chck = TrimCharacters.checkSpecialCharacters(evt.key)
                              if (chck)
                                evt.preventDefault();
                            }
                            }
                            maxLength="10"
                          />

                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group" id="edit_email">
                        <label>Email</label>

                        <div className="form-controls">
                          <input
                            type="text"
                            className="form-control  no-icon"
                            name="email"
                            value={EmailEdit}
                            onChange={onChangeEmail}
                            validations={[Emailrequired, validEmail]}
                            // onKeyDown={(evt) => {
                            //   let chck = TrimCharacters.checkSpecialCharacters(evt.key)
                            //   if (chck)
                            //     evt.preventDefault();
                            // }
                            // }
                            maxLength="60"
                          />

                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group submit-field d-flex justify-content-end mt-20 mb-0">
                      <a
                        className="btn btn-outline mr-19"
                        data-bs-dismiss="modal"
                        onClick={() => {
                          setModalShowEditCard(false);
                          onCancelEditCards();
                        }}
                      >
                        Cancel
                      </a>
                      <a
                        className="btn btn-large"
                        onClick={onEditCardDetails}
                      >
                        Save
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </form>

            {/* {noDataFound && <p>No details found for selected card holder</p>} */}
          </Fragment>,
        ]}
      />
      <ModelPopup
        key={4}
        show={modalrefillamountshow}
        onHide={() => setModalrefillamountshow(false)}
        modelheading={""}
        showheader={"false"}
        className="smallPopup issueCardSmall"
        dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
        backdrop="static"
        keyboard={false}
        modelbody={[
          <Fragment>
            <div class="row">
              <div class="col-md-12">
                <h2>Confirmation</h2>
                <div class="mt-16">
                  <div>
                    <form>
                      <div class="row">
                        <div class="col-md-12">
                          <p>Are you sure you want to top up from your pending balance?</p>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group submit-field d-flex justify-content-between mt-20 mb-0">
                            <a
                              class="btn btn-outline"
                              onClick={onHideRefillPopUpHandler}
                            >
                              No
                            </a>
                            <a
                              class="btn btn-large"
                              onClick={() => {
                                refillMainWallet();
                                setModalrefillamountshow(false);
                              }}
                            >
                              Yes
                            </a>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>,
        ]}
      />
      <DialoguePopup
        show={dialougemodalShow}
        onHide={() => setDialougemodalShow(false)}
        hideallpopups={() => { setDialougemodalShow(false); refereshPage(); }}
        modelheading={modelhdng}
        modelbody={modelbdy}
        modelicon={MdlIcon}
      />
    </div>
  );
};

export default CardHolderDetails;
