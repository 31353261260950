const HideTransaction = [
   "972D897F-1167-410F-97EA-F04A12321209",
   "D5E14A63-2929-4549-8BB0-F7B906CB679D",
   "7C47689B-1167-4BB3-B737-8120D1260126",
   "7C47689B-1167-4BB3-B737-8120D1260127",
   "94778BD6-BD30-40D4-A14D-1D799A1E3A63",
   "DDFE2DA5-4FD8-401C-A70D-482AEEA58EB4",
   "72FB45A5-5EE9-4A14-B858-C1055AF6BAA6",//3146456
   "44B48820-4219-4139-8241-623974CF0A70",//3146456
   "3C0DFF44-6E2F-4BC3-B8C1-265F7ECE4215",//3172286
   "F706ABAC-93B6-4F75-929A-32FFB1F8877A",//3172286
   "D15C4F8A-6A3E-4463-A89C-AB307DC6B682",//3172286
   "F6D775C3-0FF3-4981-BFEC-441AE9E4289E",//3172286
   "CD0976C9-1E62-4958-9A9D-4160D6FA9AC7",//3295518
   "C5C11DF0-69E6-4D22-9873-0560B8492945",//3295518
   "AB18A7A8-097E-4004-A917-D07D2041FA40",//3295518
   "13D4979D-C301-48C4-98B6-009374DD2660",//3295518
   "8BB78756-3F12-4254-B9FF-70A5CB1CAE24",//3295518
   "3595B0AD-8A24-4712-BA10-2A0BBEE0DBA0",//3295518
   "F511D610-A084-4DC2-98D4-5193E5CBC425",//2155714
   "6E51C769-3787-4A6B-A69C-7C8973DFBD98",//2155714
   "BF8E7E6D-3B91-403B-94FB-BF7E3C379A7C",//2155714
   "3953996B-ABA3-44D8-8057-AD15F430E5B4",//2155714
   "7C47689B-1167-4BB3-B737-8120D1260134",//4444316
   "7C47689B-1167-4BB3-B737-8120D1260135",//4444316
   "7C47689B-1167-4BB3-B737-8120D1260137",//4444316
   "7C47689B-1167-4BB3-B737-8120D1260128",//4444316
   "7C47689B-1167-4BB3-B737-8120D1260151",//4444316
   "7C47689B-1167-4BB3-B737-8120D1260148",//4444316
   "7C47689B-1167-4BB3-B737-8120D1260149",//4444316
   "7C47689B-1167-4BB3-B737-8120D1260146",//4444316
   "7C47689B-1167-4BB3-B737-8120D1260150",//4444316
   "7C47689B-1167-4BB3-B737-8120D1260147",//4444316
   "7C47689B-1167-4BB3-B737-8120D1260142",//4444316
   "7C47689B-1171-4BB3-B737-8120D1259127",//4426828
   "7C47689B-1171-4BB3-B737-8120D1259126",//4426828
   "7C47689B-1171-4BB3-B737-8120D1259138",//4426828
   "7C47689B-1171-4BB3-B737-8120D1259137",//4426828
   "7C47689B-1171-4BB3-B737-8120D1259136",//4426828
   "7C47689B-1171-4BB3-B737-8120D1259132",//4426828
   "7C47689B-1171-4BB3-B737-8120D1259131",//4426828
   "7C47689B-1171-4BB3-B737-8120D1259128",//4426828
   "7C47689B-1171-4BB3-B737-8120D1259126",//4426828
   "7C47689B-1249-4BB3-B737-8120D1274137",//5496136
   "7C47689B-1249-4BB3-B737-8120D1274133",//5496136
   "7C47689B-1249-4BB3-B737-8120D1274129",//5496136
   "7C47689B-1249-4BB3-B737-8120D1274127",//5496136
   "7C47689B-1171-4BB3-B737-8120D1259130",//4426828
   "12935800330",//6145560
   "12936992833",//6145560
   "12928833190",//3944035
   "12928713251",//3944035
   "12936992104",//3973939
   "12928830415",//3973939
   "7C47689B-2464-4BB3-B737-8120D1504130",//5477323
   "7C47689B-2464-4BB3-B737-8120D1504131",//5477323
   "7C47689B-1171-4BB3-B737-8120D1259132",//4426828
   "7C47689B-1171-4BB3-B737-8120D1259131",//4426828
   "7C47689B-1171-4BB3-B737-8120D1259130",//4426828
   "7C47689B-1171-4BB3-B737-8120D1259127",//4426828
   "7C47689B-1171-4BB3-B737-8120D1259128",//4426828
   "7C47689B-1171-4BB3-B737-8120D1259126",//4426828
   "7C47689B-2464-4BB3-B737-8120D1504131",//5477323
   "7C47689B-2464-4BB3-B737-8120D1504132",//5477323
   "7C47689B-1249-4BB3-B737-8120D1274132",//5496136
   "7C47689B-1249-4BB3-B737-8120D1274126",//5496136
   "7C47689B-1171-4BB3-B737-8120D1259127",//4426828
   "7C47689B-1171-4BB3-B737-8120D1259126",//4426828
   "7C47689B-1167-4BB3-B737-8120D1260135",//4444316
   "7C47689B-1167-4BB3-B737-8120D1260134",//4444316
   "7C47689B-1918-4BB3-B737-8120D1468127",//5462737
   "7C47689B-1918-4BB3-B737-8120D1468128",//5462737
   "7C47689B-2402-4BB3-B737-8120D1472127",//5478338
   "7C47689B-2402-4BB3-B737-8120D1472128",//5478338
   "7C47689B-1921-4BB3-B737-8120D1394129",//5427507
   "7C47689B-1921-4BB3-B737-8120D1394130",//5427507
   "7C47689B-1326-4BB3-B737-8120D1340141",//5443332
   "7C47689B-1326-4BB3-B737-8120D1340142",//5443332
   "2d00575f-b6de-4cd1-b317-a35d9f68e40a",//8130754
   "e3ae3048-0103-4a0e-bca1-20e3321a6e48",//8118520
   "71584bf1-b861-4a72-9519-7ba0682f34c0",//8116700
   "dc522ad5-506f-4c9d-8860-047a90f9f37c",//8116700
   "1c4ea9f7-fffd-4d99-b24b-734615457302",//8119957
   "9eef1abe-36e3-4315-9bcd-37cb4b5a725a",//8119957
   "a58ebf75-88f6-4d50-918d-17584169bfc4",//8119957
   "2e5ec72c-d042-4b86-95fd-ff872c5e5b49",//8197888
   "1d3416dd-c3ba-4772-836d-ab75faa641d1",//8197888
   "eed2ad97-deb0-49f2-a4fd-b94f42c52e4e",//8147870
   "4c8bee2e-8a61-4b1d-848b-90dd0c0e8be8",//8147870
   "13247106273",//6224356
   "13238609059",//6224356
   "187d4feb-d04d-479e-89cd-fc2280b616ce",//8112855
   "67c4d511-ebd6-4faa-bdf3-561361d09f9e",//8123435
   "e2e74bcc-ded4-4a41-acb8-5d08a5e5e45f",//8193579
   "e2e74bcc-ded4-4a41-acb8-5d08a5e5e45f",//8193579
   "7860cde3-cd1d-4c65-b6b2-0d1ba885fc8a",//8193579
   "36913afd-c36c-4edb-8a98-4b4bff604c18",//8193579
   "2cf20679-ebde-4671-8aab-9d0826ea05e3",//8114415
   "14d6bd52-9363-47f7-a940-ff9109f027d4",//8114415
   "1bede2f6-1f64-4807-9473-bca6d9b18d73",//8184695
   "862ca200-bc8c-422e-8600-12779debf97c",//8184695 
   "f9381733-fcb5-4997-8b2e-55e428d3e2ba",//8161622
   "b8f1168f-ba15-42f3-95ba-16e2145648c5",//8161622
   "082e66b6-23b2-4c62-893d-63062c444d88",//8153501
   "da1f4c62-9926-4619-9082-478ab98daf8e",//8153501
   "378c7759-1951-4b51-b7a2-9ab0f01fb5f0",//8180671
   "39a235f4-408b-4fa2-9222-d1836ccc42ec",//8180671
   "941ffb68-4855-4afb-a916-45843ea1d657",//8195496
   "0b7f340f-5e20-4d45-a07a-7d2c536edd72",//8195496
   "fb216bec-f059-40aa-9343-9253f29a503c",//8192799
   "d6b8a6c9-00d1-4618-9ee1-ea5edff2812e",//8192799
   "2f5848b1-6942-4f04-9585-d7c76a5bfef7",//8141098
   "25977eb7-cee7-4e13-8153-a9fb3d4f65ee",//8141098
   "e1fe1a04-0dfb-4d83-b817-3c0746759211",//8193579
   "4071dab9-25ed-47d0-a420-df0c4a184361",//8193579
   "41c13cf3-113a-4621-99fd-35d9892aa3fc",//8196553
   "785a4451-e24a-4731-b1e9-bae8cca650ea",//8196553
   "14c2f2f3-52c9-4bef-9e35-73d4dd8fd6e4",//8148251
   "6d22df3b-190f-4784-8ea8-68ace36c1708",//8148251  
   "e1fe1a04-0dfb-4d83-b817-3c0746759211",//8193579
   "4071dab9-25ed-47d0-a420-df0c4a184361",//8193579
   "5b0c0c98-36d1-4faf-adb5-e29228fba599",//8193579
   "da8432a7-ec41-4ef8-82bf-8f966bbea18f",//8193579
   "6b0aaa7f-9c8b-458a-bff2-3133de61a7cc",//8198609
   "498a5480-1d55-4206-9c99-5949b1bbc261",//8198609
   "13450914850", //6224042
   "13451598435", //6224042
   "13450709037", //6233791
   "13451597353", //6233791
   "0be359a8-1f9a-41c3-9bdb-f32f7d4d2f4b", //8148251
   "89a60021-5a45-4fae-b7c7-98017b8fc19e", //8148251
   "7C47689B-2702-4BB3-B737-8120D2093131", //5438451
    "b0e3ffc8-d842-4b67-9ef2-e323583be49e", //8193579
   "bc567acc-bbfa-449d-89a4-6570464565bb", //8193579
   "92ab3f9d-5108-4589-9714-51c56d5bd70b", //8193579
   "92ab3f9d-5108-4589-9714-51c56d5bd70b", //8193579
   "92ab3f9d-5108-4589-9714-51c56d5bd70b", //8193579
   "da8432a7-ec41-4ef8-82bf-8f966bbea18f", //8193579
   "5b0c0c98-36d1-4faf-adb5-e29228fba599", //8193579
   "4071dab9-25ed-47d0-a420-df0c4a184361", //8193579
   "e1fe1a04-0dfb-4d83-b817-3c0746759211", //8193579
   "6715654e-6c9a-46a5-9fff-8669de850b13", //8167279
   "c9506446-98b3-4a94-ad01-ece61362d9dd", //8143164
   "a9e2bba6-d94f-423f-80ae-1bc876a107b7", //8193893
   "a33c6e01-67fd-46b9-911c-b031034e3ef6", //8193893
   "3be4b30d-cea6-4d3c-864e-69ff3d3127d7", //8148251
   "fbb916cc-9b5a-4f4e-8063-369c447c016c", //8148251
   "70cea52d-5cc6-4d3f-b342-8f45ee9ae212", //8167842
   "6476ad40-b035-4bd1-818b-77fdb654a5a6",//8167842
   "1eab86ce-1e99-4482-9580-e7e2325d4fb4",//8195496
   "3b13978d-55f1-4444-acb0-8f704294ad7b",//8195496
   "b774e9df-763a-4b18-8632-9dadae011431", //8196553
   "a729b4da-3c1e-4cd9-97f3-fe451aafbd3c", //8196553
   "421ea7c7-ba96-4d45-8ce7-b8edc97af1c1", //8196553
   "9f6f2841-ba55-4aeb-9368-f26e2e5049c0", //8196553
   "1bf25e09-815e-42b0-b762-6526df7da589", //8196553
   "f695faf1-f36f-45c3-b163-c2e7a6ef641d", //8196553
   "66e6f2d0-4e60-4464-97ad-b5629ac1faa8", //8196553
   "2f6e698c-5aad-4b2d-8c48-eee0a43d3e43", //8196553
   "1a4cce2d-b163-4628-8e3c-946377faf7ad", //8197986
   "8d760c78-2411-4943-8541-2f10c310f78a", //8197986
   //Old transactions 26-oct-2023
   "A20469F0-92D7-4AA0-9809-3CB02A29697F",
   "76C13EA7-D7CC-4214-9832-6107AE5D19C2",
   "C8D28585-4E6F-44C7-A294-9683861E20FE",
   "3F1D8254-016D-4E19-A871-E74E6FEB9846",
   // New Transactions starts 30-10-2023
   
   "13442885430", //6240907
   "13552165239", //6240907
   "13555996796", //6240907
   "13552168529", //6233791
   "13556001169", //6233791
   "13546450828", //6279235
   "13556618533", //6279235
   "13556622935", //6224042
   "13552174111", //6224042


]


export default HideTransaction;